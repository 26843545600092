import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visa from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/master-card.png";
import eftops from "../../../assets/images/eftops.png";
import InputSelector from "../../inputselector/InputSelector";
const RediscoverJourney = () => {
  return (
    <>
      <section className="keyzi_clickAway--wrapper keyzi_discJourney--wrapper">
        <div className="click_away--banner "></div>
        <Container>
          <div className="keyzi_clickAway--inner">
            <Row>
              <Col lg={6}>
              
              </Col>
              <Col lg={6}>
                <div className="newPage--InputSelector newPageInput--rediscover">
                  <h3>
                  Ready to start the journey?
                  </h3>
                  <p>Contact us today to find your driving instructor and take the first step towards converting your international licence with confidence and ease.</p>
                  <InputSelector label="Instructor Search" />
                  <div className="fp_weaccept">
                    <h4>We Accept</h4>
                    <img src={visa} className="img-fluid " alt="Visa" />
                    <img
                      src={mastercard}
                      className="img-fluid master_card"
                      alt="Master Card"
                    />
                    <img src={eftops} className="img-fluid " alt="Eftops" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default RediscoverJourney;
