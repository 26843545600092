import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import "./PackageCsOne.css"
import money from "../../assets/images/Money.svg"
import user from "../../assets/images/User.svg"

import visa from "../../assets/images/visa-bg.png"
import master from "../../assets/images/master-card.png"
import eftops from "../../assets/images/eftops.png"


export const PackageCsOne = () => {
  const currentUrl = window.location.href;
  const containsCorporateSiteone = currentUrl.includes("corporatesiteone");
  const containsCorporateSitetwo = currentUrl.includes("corporatesitetwo");
  return (
    <section className={`${containsCorporateSitetwo ? "cstwo_packagecstwo--wrapper" : "csone_packagecsone--wrapper"}`}>
      <Container>
        <div className='csone_packagecsone--inner'>
            <Row>
              {containsCorporateSitetwo &&
                <Col md={12}>
                <div className="packagecsone_leftSide">
                    <div className="packagecsone--card">
                      <div className="card-img">
                          <img src={money} className='img-fluid'  alt="" />
                      </div>
                      <div className="card-content">
                        <h2>Competitive Rates and Comprehensive Driving Lesson Packages</h2>
                        <p>Buy more lessons & get more discount</p>
                      </div>
                    </div>
                    <div className="packagecsone--card">
                      <div className="card-img">
                          <img src={user} className='img-fluid'  alt="" />
                      </div>
                      <div className="card-content">
                        <h2>Guaranteed Driving Lessons!</h2>
                        <p>We will fulfill your driving lesson booking or you won’t be charged</p>
                      </div>
                    </div>
                </div>
              </Col>
              }
              {!containsCorporateSitetwo && 
              (
                <>
                <Col md={8}>
                <div className="packagecsone_leftSide">
                    <div className="packagecsone--card">
                      <div className="card-img">
                          <img src={money} className='img-fluid'  alt="" />
                      </div>
                      <div className="card-content">
                        <h2>Driving Lesson pricing and packages</h2>
                        <p>Buy more lessons & get more discount</p>
                      </div>
                    </div>
                    <div className="packagecsone--card">
                      <div className="card-img">
                          <img src={user} className='img-fluid'  alt="" />
                      </div>
                      <div className="card-content">
                        <h2>Driving lesson guarantee!</h2>
                        <p>We will fulfill your driving lesson booking or you won’t be charged</p>
                      </div>
                    </div>
                </div>
              </Col>
              <Col md={4}>
                <div className="packagecsone_rightSide">
                    <h2>We Accept</h2>
                    <div className="payment-images">
                      <img src={visa} className='img-fluid' alt="Visa" />
                      <img src={master} className='img-fluid'  alt="Card" />
                      <img src={eftops} className='img-fluid'  alt="Eftops" />
                    </div>
                </div>
              </Col>
                </>
              )}
            </Row>
        </div>
      </Container>
    </section>
  )
}
