import React from "react";
import { useEffect } from "react";
import { BannerCsOne } from "../components/bannercsone/BannerCsOne";
import { PackageCsOne } from "../components/packagecsone/PackageCsOne";
import { WorkCsOne } from "../components/workscsone/WorkCsOne";
import { DrivingLessonsCsOne } from "../components/drivinglessonscsone/DrivingLessonsCsOne";
import { LearnerSayCsOne } from "../components/learnersaycsone/LearnerSayCsOne";
import { FaqCsOne } from "../components/faqcsone/FaqCsOne";
import { FeaturedCsOne } from "../components/featuredcsone/FeaturedCsOne";
import { WorkCsTwo } from "../components/workcstwo/WorkCsTwo";
import { DrivingLessonsCsTwo } from "../components/drivinglessonscstwo/DrivingLessonsCsTwo";
import { LearnerSayCsTwo } from "../components/learnersaycstwo/LearnerSayCsTwo";
import ExploreMore from "../components/exploremore/ExploreMore";

export const CorporateSiteTwo = () => {
  useEffect(() => {
    window.localStorage.removeItem("prevsUrl");
  }, []);
  return (
    <>
      <BannerCsOne />
      <PackageCsOne />
      <WorkCsTwo />
      <DrivingLessonsCsTwo />
      <LearnerSayCsTwo/>
      <FaqCsOne centerclass="text-start"/>
      <ExploreMore/>
    </>
  );
};
