import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import star from "../../assets/images/Instructor Rating.svg"
import arrow from "../../assets/images/Accredited.svg"
import message from "../../assets/images/Vehicle Safety.svg"
import car from "../../assets/images/Always your choice.svg"
import "./DrivingLessonsCsOne.css"
export const DrivingLessonsCsOne = () => {
  return (
    <section className="drivinglessonscsone--wrapper">
      <Container>
          <div className='drivinglessonscsone--inner'>
              <div className="drivinglessons--whiteWrapper">
              <h2 className='sub_head'>Book driving lessons with confidence</h2>
              <p className='para_text'>Choose a driving instructor you can trust</p>
              <Row>
                <Col md={3}>
                  <div className="drivinglessonscsone--card">
                      <div className="card-img">
                        <img src={star} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Instructor Ratings</h5>
                        <p>Access peer reviews & find an instructor who has consistently provided a great learning experience.</p>
                      </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="drivinglessonscsone--card">
                      <div className="card-img">
                        <img src={arrow} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Accredited</h5>
                        <p>We obtain up to date copies of relevant instructor accreditations & verify their working with children credentials.</p>
                      </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="drivinglessonscsone--card">
                      <div className="card-img">
                        <img src={message} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Vehicle Safety</h5>
                        <p>Gain access to instructor vehicle make, model, year & safety rating.</p>
                      </div>
                  </div>
                </Col>
                <Col md={3}>
                  <div className="drivinglessonscsone--card">
                      <div className="card-img">
                        <img src={car} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Always your choice</h5>
                        <p>Don't like your current instructor? Select a new instructor via our online portal, no questions asked.</p>
                      </div>
                  </div>
                </Col>
                <Col md={12} className='text-center'>
                  <Link to="/" className='btn'>Book driving lessons now</Link>
                </Col>
              </Row>
              </div>
          </div>
      </Container>
    </section>
  )
}

