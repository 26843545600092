import React from "react";
import DataTable from "react-data-table-component";
import { Eyeshow } from "../../../assets/icons/Eyeshow";

export const WalletTable = ({ reversedFilteredData }) => {
  const formatDate = (dateStr) => {
    const date = new Date(dateStr);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row?.id, // Use a selector function instead of a string
      sortable: true,
    },
    {
      name: "Client",
      selector: (row) => row?.client, // Use a selector function instead of a string
      sortable: true,
      cell: (row) => `${row?.instructor?.name}`,
    },
    {
      name: "Amount",
      selector: (row) => row?.amount, // Use a selector function instead of a string
      sortable: true,
      cell: (row) => `$${row?.amount}`,
    },
    {
      name: "Date",
      selector: (row) => row.created_at, // Use a selector function instead of a string
      sortable: true,
      cell: (row) => formatDate(row.created_at),
    },
    {
      name: "Status",
      selector: (row) => row.type, // Use a selector function instead of a string
      sortable: true,
      cell: (row) => (
        <span className={`debit_state f-16 fw_600 ${row.type}`}>
          {row.type}
        </span>
      ),
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      <div className="tabel_body_wallet">
        <DataTable
          striped
          bordered
          highlightOnHover
          className="table-wallet"
          columns={columns}
          data={reversedFilteredData}
          pagination
          paginationPerPage={5} // Number of rows per page
          paginationRowsPerPageOptions={[5, 10, 20]} // Rows per page options
          paginationComponentOptions={paginationOptions}
          noDataComponent="NO RECORD FOUND"
        />
      </div>
    </>
  );
};
