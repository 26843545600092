import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Row } from "react-bootstrap";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Datepickcalender } from "../../../assets/icons/Datepickcalender";
import { WalletTable } from "./WalletTable";
import "./Wallet.css";
import { WalletData } from "./WalletData";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { LearnerWalletDataById } from "../../../redux/slice/authSlice";
// this is wallet section which shows all the detail of transaction and have three button all,credit and debit and data will filter according to the button click
export const Wallet = () => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState("");
  const [key, setKey] = useState("all");
  const { leanerWalletData } = useSelector((state) => state?.auth);
  console.log(leanerWalletData);
  const { learnerDashboardProfileInfo } = useSelector((state) => state?.auth);
  const { user, error } = useSelector((state) => state?.auth);

  const handleFilterChange = (k) => {
    setKey(k);
  };
  const filteredData =
    key === "all"
      ? leanerWalletData?.data
      : leanerWalletData?.data?.filter((item) => item?.type === key);
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(LearnerWalletDataById(user?.data?.data.id));
      } catch (error) {
        console.error(
          "An error occurred while fetching wallet info:",
          error
        );
      }
    };
    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);

  const filteredDataByDate =
    startDate
      ? filteredData?.filter((item) => {
          const itemDate = new Date(item.created_at);
          return itemDate.toDateString() === startDate.toDateString();
        })
      : filteredData;

      const reversedFilteredData =
      filteredDataByDate?.length > 0 ? filteredDataByDate.slice().reverse() : [];
  
  return (
    <>
      <section className="walletHistory_section das_comp_shdo br-10">
        <Row className="bg-white br-10 p-3 p-md-5">
          <div className="d-flex justify-content-between">
            <div>
              <h5 className="book_calender">Wallet</h5>
              <h5 className="book_calender text-indigo">
                ${learnerDashboardProfileInfo?.data.wallet_amount}
              </h5>
            </div>
            <div className="d-flex position-relative">
              <Datepickcalender className="mt-2" />
              <div className="position-relative">
                <DatePicker
                  selected={startDate}
                  className="drop_date_picker"
                  onChange={(date) => setStartDate(date)}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="DD/MM/YYYY"

                />
              </div>
            </div>
          </div>
          {leanerWalletData ? (
                   <Tabs
                   id="filter-tabs"
                   activeKey={key}
                   onSelect={handleFilterChange}
                   className="mb-4 border-0 bt-grp btn-group justify-content-center justify-content-xl-start"
                 >
                   <Tab eventKey="all" title="All">
                     <WalletTable reversedFilteredData={reversedFilteredData} />
                   </Tab>
                   <Tab eventKey="Debit" title="Debit">
                     <WalletTable reversedFilteredData={reversedFilteredData} />
                   </Tab>
                   <Tab eventKey="Credit" title="Credit">
                     <WalletTable reversedFilteredData={reversedFilteredData} />
                   </Tab>
                 </Tabs>
        ) : (
          <h6 className="text-center">Loading...</h6>
        )}

        </Row>
      </section>
    </>
  );
};
