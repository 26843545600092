import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import cardrive from '../../assets/images/cardrive.png'
import "./BannerMain.css";
export const BannerMain = () => {
  return (
    <section>
      <Container className="banner_main_new_wrapper">
           <Row>
           <Col md={12}>
           <div className="banner_main_content">
              <div>
                <h1 className="text-white sub_light_head mb-1 small_text">
                  Where do you need a
                </h1>
                <h1 className="text-uppercase text-white sub_head">
                  driving instructor?
                </h1>
              </div>
              <InputSelector />
            </div>
           </Col>
           </Row>


      </Container>
    </section>
  );
};
