import React from 'react'
import banner from "../../../assets/images/newpages/embarkmain-banner.png"
const EmbarkBanner = () => {
  return (
    <section className="keyzi_conquer--wrapper  keyzi_embark--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
              <h3>Embark On The Journey To Independence</h3>
              <p>Your Road To Getting P Plates</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side">
                <img src={banner} alt="Car" className='img-fluid'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default EmbarkBanner