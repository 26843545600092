import 'bootstrap-icons/font/bootstrap-icons.css';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import { Button} from 'react-bootstrap';
import { useRef, useState } from 'react';
import { useEffect } from 'react';
import React from 'react';
import './Calender.css';
import { ModalBox } from './Modal';
import { EventDetail } from './EventDetail';
import * as bootstrap from 'bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Routers } from '../../../routes/Constant';
import { LearnerBookingDetailById } from '../../../redux/slice/authSlice';

export const CalenderPage = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const calendarRef = useRef();
  const { user, error } = useSelector((state) => state?.auth);
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(LearnerBookingDetailById(user?.data?.data.id));
      } catch (error) {
        console.error("An error occurred while fetching dashboard info:", error);
      }
    };

    if (user?.data?.data) {
      fetchDashboardInfo();
      console.log(bookingDetail?.calendar_data)
    }
  }, [user]);
  const { bookingDetail } = useSelector(state => state?.auth);
  const Events = bookingDetail?.calendar_data?.map((item) => {
    if(item?.is_canceled === 0){
      return {
        id: item.id,
        title: "BOOKED",
        start: item.start,
        end: item.end,
        backgroundColor: '#3599E7',
      };
    }
    else{
      return {
        id: item.id,
        title: "CANCEL",
        start: item.start,
        end: item.end,
        backgroundColor: '#EB3349',
      };
    }
  });

  const [show, setShow] = useState(false);
  const [showEvent, setShowEvent] = useState(false);

  const [list, setList] = useState(Events);

  // getting value from modal.jsx for making event"
  const handleContent = (newvalue) => {
  

    // setList([
    //   ...list,
    //   {
    //     id: i + 1,
    //     title: `${newvalue.heading}`,
    //     start: `${newvalue.startDate}`,
    //     end: `${newvalue.endDate}`,
    //     backgroundColor: 'var(--primary-color)',
    //   },
    // ]);
    // i++;
    setShow(false);
    
  };
  const HandleEventShow = (info) => {
    navigate(`/auth/dashboard/booking/bookingDetails/${info?.event?._def?.publicId}`)
    console.log(info)
  };
  function mobileCheck() {
    if (window.innerWidth < 568) {
      return true;
    } else {
      return false;
    }
  }

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const currentDate = new Date();
      calendarRef.current.getApi().gotoDate(currentDate);
    }
  };

  // this is the calender section for booking
  return (
    <>
      <section className=" calender_sec bg-white br-10 learner_calander">
        <div className="d-flex justify-content-between p-4 calender_head">
          <div className="book_calender  bg-white">
            <p className="mb-0">Booking Calendar</p>
          </div>
          <div>
            <Button
              variant="primary"
              className="view_btn view_booking_btn bg-indigo"
              onClick={()=>{navigate(Routers.Booking)}}
            >
              View Booking
            </Button>
          </div>
        </div>
        <div className="p-4">
          <FullCalendar
        ref={calendarRef}

            id="calender"
            initialView={mobileCheck() ? 'timeGridDay' : 'timeGridWeek'}
            plugins={[
              dayGridPlugin,
              bootstrap5Plugin,
              timeGridPlugin,
              listPlugin,
              interactionPlugin,
            ]}
            customButtons={{
              myCustomButton: {
                text: 'Today',
                click: handleTodayButtonClick,
              },
            }}
            // function is used for making event drop
            eventDrop={(info) => {
              const { start, end } = info.oldEvent._instance.range;
              console.log(start, end);
              const { start: newStart, end: newEnd } =
                info.event._instance.range;
              console.log(newStart, newEnd);
              if (new Date(start).getDate() === new Date(newStart).getDate()) {
                info.revert();
              }
            }}
            dateClick={() => setShow(true)}
            eventClick={(info)=>HandleEventShow(info)}

            events={Events}
            slotMinTime="09:00"
            editable={false}
            selectable={true}
            allDaySlot={false}
            expandRows={true}
            eventBackgroundColor="green"
            eventColor="green"
            eventTextColor="white"
            contentHeight={'500px'}
            // this this the column header section
            dayHeaderContent={({ date }) => {
              const dayName = date.toLocaleDateString('locale', {
                weekday: 'short',
              });
              const newdate = date.getDate();
              const currentDate = new Date().getDate();

              return (
                <>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                      className={`pt-2 ${
                        currentDate === newdate ? '#3599E7' : 'black'
                      }`}
                    >
                      {dayName}
                    </div>
                    <span
                      className={`pt-1 ${
                        currentDate === newdate ? 'current_Date' : ''
                      }`}
                      style={{
                        width: '25px',
                        height: '25px',
                        borderRadius: '50%',
                      }}
                    >
                      {newdate}
                    </span>
                  </div>
                </>
              );
            }}
            headerToolbar={{
              left: 'myCustomButton prev next',
              center: 'title',
              right: 'dayGridMonth,timeGridWeek,timeGridDay,listWeek',
            }}
            themeSystem="bootstrap5"
          />
          {/* {showEvent && (
            <EventDetail
              lists={list}
              showEvent={showEvent}
              setShowEvent={setShowEvent}
            />
          )} */}
          {/* here we show popup and getting value from that popup for event making */}
          {/* {show && (
            <ModalBox
              show={show}
              setShow={setShow}
              handleContent={handleContent}
            />
          )} */}
        </div>
      </section>
    </>
  );
};
