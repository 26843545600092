import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { Bannermain } from '../components/bannermain/Bannermain';
import { DashboardLeftSide } from '../components/dashboard/dashboardmenu/DashboardLeftSide';
import { useLocation, Redirect, useNavigate } from 'react-router-dom';
import { Routers } from './Constant';
import { Navigate } from 'react-router-dom';
import { useEffect,useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import {
  learnerDashboardInfoById,
  learnerDashboardProfileInfoById,
  LearnerWalletDataById,
  LearnerBookingDetailById,
  LearnerTransactionDataById
} from "../redux/slice/authSlice";
export const DashboardRoutes = ({ element: Component, ...props }) => {
  const {user,error} = useSelector((state) => state?.auth);
  const dispatch = useDispatch();
  // const navigate = useNavigate();
  const pathname = useLocation().pathname;
  const token = localStorage.getItem('token');
  const [count, setCount] = useState(1);
  const isValidToken = !!token;
  const countHandler = () => {
    setCount(count + 1);
  }


  
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(learnerDashboardProfileInfoById(user?.data?.data.id));
        // await dispatch(learnerDashboardInfoById(user?.data?.data.id));
        // await dispatch(LearnerWalletDataById(user?.data?.data.id));
        // await dispatch(LearnerBookingDetailById(user?.data?.data.id))
        // await dispatch(LearnerTransactionDataById(user?.data?.data.id))
    
        //  localStorage.setItem('token', user?.data?.data.token);
        //  const booking = localStorage.getItem('booking')
        // if (booking) {
        //   navigate(Routers.Progress);
        // } else {
        //   navigate(Routers.Dashboard);
        // }
      } catch (error) {
        console.error('An error occurred while fetching dashboard info:', error);
      }
    };

    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);

  return (
    <>
      {/* Rendering content based on the presence of a valid token */}
      {isValidToken ? (
        <div>
          {/* If the pathname is equal to Routers.Progress, render the Component */}
          {pathname === Routers.Progress ? (
            <Component {...props} />
          ) : (
            // Otherwise, render the Dashboard layout with DashboardLeftSide and the Component
            <Bannermain bgconfirm="container-w-bg-clr pt-3 pt-xl-5">
              <Container>
                <Row>
                  <Col lg={3} className="dash_left">
                    <DashboardLeftSide />
                  </Col>
                  <Col sm={12} xl={9}>
                    <Component countHander={countHandler} count={count} {...props} />
                  </Col>
                </Row>
              </Container>
            </Bannermain>
          )}
        </div>
      ) : (
        // If there's no valid token, redirect to the login page.
        <Navigate to={Routers.Login} />
      )}
    </>
  );
};

