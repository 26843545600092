import React from "react";
import banner from "../../../assets/images/newpages/license_banner.png";
const LicenseBanner = () => {
  return (
    <section className="keyzi_conquer--wrapper keyzi_licensebanner--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h3>
                  Seamlessly Transition Your International License to an
                  Australian One
                </h3>
                <p>
                  Start a new chapter in Australia? Whether you're here for a
                  new adventure, career opportunities, or to call this beautiful
                  country home, ensuring you can confidently navigate the roads
                  is crucial. Transitioning your international driver's licence
                  to an Australian one is a significant step in establishing
                  your independence here. Let's guide you through this journey
                  with ease and assurance.
                </p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side bg-none">
                <img src={banner} alt="Car" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LicenseBanner;
