import { createSlice } from "@reduxjs/toolkit";
import { resetAllState } from './rootSlice';

const initialState = {
  user: null,
  loading: true,
  error: null,
  routeAfterLogin: null,
  forgotPassword: null,
  LearnerSign: null,
  instructorPackage: null,
  learnerDashboardProfileInfo: null,
  learnerDashboardInfo:null,
  bookingDescription:null,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    [resetAllState]: () => initialState,
    loginStart: (state) => {
      state.loading = true;
      state.error = null;
      state.user = null;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload;
      state.error = null;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.user = null;
      state.error = action.payload;
    },
    logoutLearner: (state) => {
      state.loading = true;
      state.error = null;
      state.user = null;
    },
    logoutLearnerSuccess: (state, action) => {
      state.loading = false;
      state.userlogout = action.payload;
      state.error = null;
    },
    logoutLearnerFailure: (state, action) => {
      state.loading = false;
      state.userlogout = null;
      state.error = action.payload;
    },

    forgotPasswordStart: (state) => {
      state.loading = true;
      state.error = null;
      state.forgotPassword = null;
    },
    forgotPasswordSuccess: (state, action) => {
      state.loading = false;
      state.forgotPassword = action.payload;
      state.error = null;
    },
    forgotPasswordFailure: (state, action) => {
      state.loading = false;
      state.forgotPassword = null;
      state.error = action.payload;
    },
    SignUpLearnerData: (state) => {
      state.loading = true;
      state.error = null;
      state.LearnerSign = null;
    },
    SignUpLearnerDataSuccess: (state, action) => {
      state.loading = false;
      state.LearnerSign = action.payload;
      state.error = null;
    },
    SignUpLearnerDataFailure: (state, action) => {
      state.loading = false;
      state.LearnerSign = null;
      state.error = action.payload;
    },
    SignUpInstructorData: (state) => {
      state.loading = true;
      state.error = null;
      state.LearnerSign = null;
    },
    SignUpInstructorDataSuccess: (state, action) => {
      state.loading = false;
      state.LearnerSign = action.payload;
      state.error = null;
    },
    SignUpInstructorDataFailure: (state, action) => {
      state.loading = false;
      state.LearnerSign = null;
      state.error = action.payload;
    },
    InstructorPackagesByIdAData: (state) => {
      state.loading = true;
      state.error = null;
      state.instructorPackage = null;
    },
    InstructorPackagesByIdADataSuccess: (state, action) => {
      state.loading = false;
      state.instructorPackage = action.payload;
      state.error = null;
    },
    InstructorPackagesByIdADataFailure: (state, action) => {
      state.loading = false;
      state.instructorPackage = null;
      state.error = action.payload;
    },

    learnerDashboardInfoById: (state) => {
      state.loading = true;
      state.error = null;
      state.learnerDashboardInfo = null;
    },
    learnerDashboardInfoByIdSuccess: (state, action) => {
      state.loading = false;
      state.learnerDashboardInfo = action.payload;
      state.error = null;
    },
    learnerDashboardInfoByIdFailure: (state, action) => {
      state.loading = false;
      state.learnerDashboardInfo = null;
      state.error = action.payload;
    },
    learnerDashboardProfileInfoById: (state) => {
      state.loading = true;
      state.error = null;
      state.learnerDashboardProfileInfo = null;
    },
    learnerDashboardProfileInfoByIdSuccess: (state, action) => {
      state.loading = false;
      state.learnerDashboardProfileInfo = action.payload;
      state.error = null;
    },
    learnerDashboardProfileInfoByIdFailure: (state, action) => {
      state.loading = false;
      state.learnerDashboardProfileInfo = null;
      state.error = action.payload;
    },
    learnerDashboardProfileUpdateById: (state) => {
      state.loading = true;
      state.error = null;
      state.learnerDashboardProfileUpdate = null;
    },
    learnerDashboardProfileUpdateByIdSuccess: (state, action) => {
      state.loading = false;
      state.learnerDashboardProfileUpdate = action.payload;
      state.error = null;
    },
    learnerDashboardProfileUpdateByIdFailure: (state, action) => {
      state.loading = false;
      state.learnerDashboardProfileUpdate = null;
      state.error = action.payload;
    },

    learnerDashboardUpcomingBookingById: (state) => {
      state.loading = true;
      state.error = null;
      state.upcomingBooking = null;
    },
    learnerDashboardUpcomingBookingByIdSuccess: (state, action) => {
      state.loading = false;
      state.upcomingBooking = action.payload;
      state.error = null;
    },
    learnerDashboardUpcomingBookingByIdFailure: (state, action) => {
      state.loading = false;
      state.upcomingBooking = null;
      state.error = action.payload;
    },

    learnerDashboardPwdChangeById: (state) => {
      state.loading = true;
      state.error = null;
      state.learnerDashboardPwdChange = null;
    },
    learnerDashboardPwdChangeByIdSuccess: (state, action) => {
      state.loading = false;
      state.learnerDashboardPwdChange = action.payload;
      state.error = null;
    },
    learnerDashboardPwdChangeByIdFailure: (state, action) => {
      state.loading = false;
      state.learnerDashboardPwdChange = null;
      state.error = action.payload;
    },
    InstructorBookingById: (state) => {
      state.loading = true;
      state.error = null;
      state.instructorBooking = null;
    },
    InstructorBookingByIdSuccess: (state, action) => {
      state.loading = false;
      state.instructorBooking = action.payload;
      state.error = null;
    },
    InstructorBookingByIdFailure: (state, action) => {
      state.loading = false;
      state.instructorBooking = null;
      state.error = action.payload;
    },
    BookingDescriptionById: (state) => {
      state.loading = true;
      state.error = null;
      state.bookingDescription = null;
    },
    BookingDescriptionByIdSuccess: (state, action) => {
      state.loading = false;
      state.bookingDescription = action.payload;
      state.error = null;
    },
    BookingDescriptionByIdFailure: (state, action) => {
      state.loading = false;
      state.bookingDescription = null;
      state.error = action.payload;
    },
    LearnerWalletDataById: (state) => {
      state.loading = true;
      state.error = null;
      state.leanerWalletData = null;
    },
    LearnerWalletDataByIdSuccess: (state, action) => {
      state.loading = false;
      state.leanerWalletData = action.payload;
      state.error = null;
    },
    LearnerWalletDataByIdFailure: (state, action) => {
      state.loading = false;
      state.leanerWalletData = null;
      state.error = action.payload;
    },
    LearnerBookingDetailById: (state) => {
      state.loading = true;
      state.error = null;
      state.bookingDetail = null;
    },
    LearnerBookingDetailByIdSuccess: (state, action) => {
      state.loading = false;
      state.bookingDetail = action.payload;
      state.error = null;
    },
    LearnerBookingDetailByIdFailure: (state, action) => {
      state.loading = false;
      state.bookingDetail = null;
      state.error = action.payload;
    },
    LandingPageReferalId: (state) => {
      state.loading = true;
      state.error = null;
      state.landingPage = null;
    },
    LandingPageReferalIdSuccess: (state, action) => {
      state.loading = false;
      state.landingPage = action.payload;
      state.error = null;
    },
    LandingPageReferalIdFailure: (state, action) => {
      state.loading = false;
      state.landingPage = null;
      state.error = action.payload;
    },
    LearnerTransactionDataById: (state) => {
      state.loading = true;
      state.error = null;
      state.leanerTransactionData = null;
    },
    LearnerTransactionDataByIdSuccess: (state, action) => {
      state.loading = false;
      state.leanerTransactionData = action.payload;
      state.error = null;
    },
    LearnerTransactionDataByIdFailure: (state, action) => {
      state.loading = false;
      state.leanerTransactionData = null;
      state.error = action.payload;
    },
    feedbacklearnersend: (state) => {
      state.loading = true;
      state.error = null;
      state.feedbackdata = null;
    },
    feedbacklearnersendSuccess: (state, action) => {
      state.loading = false;
      state.feedbackdata = action.payload;
      state.error = null;
    },
    feedbacklearnersendFailure: (state, action) => {
      state.loading = false;
      state.feedbackdata = null;
      state.error = action.payload;
    },
    cancelBooking: (state) => {
      state.loading = true;
      state.error = null;
      state.bookingcancel = null;
    },
    cancelBookingSuccess: (state, action) => {
      state.loading = false;
      state.bookingcancel = action.payload;
      state.error = null;
    },
    cancelBookingFailure: (state, action) => {
      state.loading = false;
      state.bookingcancel = null;
      state.error = action.payload;
    }
    ,
    contactusUser: (state) => {
      state.loading = true;
      state.error = null;
      state.contactus = null;
    },
    contactusUserSuccess: (state, action) => {
      state.loading = false;
      state.contactus = action.payload;
      state.error = null;
    },
    contactusUserFailure: (state, action) => {
      state.loading = false;
      state.contactus = null;
      state.error = action.payload;
    },PackageSubSerivceList: (state, action) => {
      state.loading = true;
      state.subServiceListData = null;
      state.error = null;
    },
    PackageSubSerivceListSuccess: (state, action) => {
      state.loading = false;
      state.subServiceListData = [action.payload];
      state.error = null;

    },
    PackageSubSerivceListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },updateBookingDateLearner: (state, action) => {
      state.loading = true;
      state.updateDate = null;
      state.error = null;
    },
    updateBookingDateLearnerSuccess: (state, action) => {
      state.loading = false;
      state.updateDate = [action.payload];
      state.error = null;

    },
    updateBookingDateLearnerFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  },
});

export const {
  LandingPageReferalId,
  LandingPageReferalIdSuccess,
  LandingPageReferalIdFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  logoutLearner,
  logoutLearnerSuccess,
  logoutLearnerFailure,
  setRouteAfterLogin,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  SignUpLearnerData,
  SignUpLearnerDataFailure,
  SignUpInstructorDataSuccess,
  SignUpInstructorData,
  SignUpInstructorDataFailure,
  SignUpLearnerDataSuccess,
  InstructorPackagesByIdAData,
  InstructorPackagesByIdADataSuccess,
  InstructorPackagesByIdADataFailure,
  learnerDashboardInfoById,
  learnerDashboardInfoByIdSuccess,
  learnerDashboardInfoByIdFailure,
  learnerDashboardProfileInfoById,
  learnerDashboardProfileInfoByIdSuccess,
  learnerDashboardProfileInfoByIdFailure,
  learnerDashboardProfileUpdateById,
  learnerDashboardProfileUpdateByIdSuccess,
  learnerDashboardProfileUpdateByIdFailure,
  learnerDashboardPwdChangeById,
  learnerDashboardPwdChangeByIdSuccess,
  learnerDashboardPwdChangeByIdFailure,
  learnerDashboardUpcomingBookingById,
  learnerDashboardUpcomingBookingByIdSuccess,
  learnerDashboardUpcomingBookingByIdFailure,
  InstructorBookingById,
  InstructorBookingByIdSuccess,
  InstructorBookingByIdFailure,
  BookingDescriptionById,
  BookingDescriptionByIdSuccess,
  BookingDescriptionByIdFailure,
  LearnerWalletDataById,
  LearnerWalletDataByIdSuccess,
  LearnerWalletDataByIdFailure,
  LearnerBookingDetailById,
  LearnerBookingDetailByIdSuccess,
  LearnerBookingDetailByIdFailure,
  LearnerTransactionDataById,
  LearnerTransactionDataByIdSuccess,
  LearnerTransactionDataByIdFailure,
  feedbacklearnersend,
  feedbacklearnersendSuccess,
  feedbacklearnersendFailure,
  cancelBooking,
  cancelBookingSuccess,
  cancelBookingFailure,
  contactusUser,
  contactusUserSuccess,
  contactusUserFailure,
  PackageSubSerivceList,
  PackageSubSerivceListSuccess,
  PackageSubSerivceListFailure,
  updateBookingDateLearner,
  updateBookingDateLearnerSuccess,
  updateBookingDateLearnerFailure
} = authSlice.actions;
export default authSlice.reducer;
