import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import "bootstrap-icons/font/bootstrap-icons.css";
import bootstrap5Plugin from "@fullcalendar/bootstrap5";
import { Container, Row, Col, Button } from "react-bootstrap";
import { message } from "antd";
import {
  CalenderAvailabilityData,
  getData,
} from "../../../redux/slice/CalenderSlice";

export const CheckAvailability = ({
  SelectPacknext,
  updateFieldData,
  timeDurationPackage,
  bookingData,
}) => {
  const { instructorsdetails } = useSelector((state) => state.instructors);
  const { bookingDetail } = useSelector((state) => state?.auth);

  const instructorData = instructorsdetails[0].data;
  const dispatch = useDispatch();

  // Fetch calendar data when the component mounts
  useEffect(() => {
    dispatch(CalenderAvailabilityData(instructorData.id));
  }, [dispatch, instructorData.id]);

  const Availability = useSelector((state) => state.calender.calender);
  const Events = Availability.calendar_data;
  const calendarRef = useRef();

  function mobileCheck() {
    if (window.innerWidth < 568) {
      return true;
    } else {
      return false;
    }
  }

  const [selectedDates, setSelectedDates] = useState([]);
  const [selectedStartDates, setSelectedStartDates] = useState([]);
  const [selectedEndDates, setSelectedEndDates] = useState([]);

  const handleDateSelect = (info) => {
    const setCalendarTime = new Date(info.startStr);
    const formattedStartDateTime = formatDate(setCalendarTime);
  
    // Check for existing bookings
    const isBookingConflict = bookingDetail?.calendar_data?.some((e) => {
      return e?.start === formattedStartDateTime;
    });
  
    if (isBookingConflict) {
      message.error({
        content: "This slot is already booked with another Instructor",
        duration: 3,
      });
      return;
    }
  
    // Calculate end time
    const durationInMinutes = timeDurationPackage;
    const endDateTime = new Date(setCalendarTime);
    endDateTime.setMinutes(endDateTime.getMinutes() + durationInMinutes);
    const formattedEndDateTime = formatDate(endDateTime);
  
    // Validate the selected date
    const currentDate = new Date();
  
    if (setCalendarTime < currentDate) {
      message.error({
        content: "Please Select Upcoming Booking Date",
        duration: 3,
      });
      return;
    }
  
    const newtime = setCalendarTime
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
      })
      .toLocaleLowerCase();
  
    const newday = setCalendarTime.toLocaleDateString("locale", {
      weekday: "long",
    });
  
    const currDate = setCalendarTime.toLocaleDateString("en-US", {
      month: "long",
      day: "2-digit",
    });
  
    const endTime = setCalendarTime
      .toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      })
      .toLocaleLowerCase();
  
    const cs = [{ newtime, newday, currDate }];
    dispatch(getData(cs));
  
    // Update all date arrays together
    setSelectedDates([...selectedDates, formattedStartDateTime]);
    setSelectedStartDates([...selectedStartDates, formattedStartDateTime]);
    setSelectedEndDates([...selectedEndDates, formattedEndDateTime]);
  
    info.view.calendar.addEvent({
      id: "selected-date",
      title: "Selected",
      start: info.startStr,
    });
  };
  
  

  function formatDate(date) {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    const formattedTime = `${hours
      .toString()
      .padStart(2, "0")}:${minutes}:${seconds}`;

    return `${year}-${month}-${day} ${formattedTime}`;
  }

  const plugins = [
    dayGridPlugin,
    bootstrap5Plugin,
    timeGridPlugin,
    listPlugin,
    interactionPlugin,
  ];

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const currentDate = new Date();
      calendarRef.current.getApi().gotoDate(currentDate);
    }
  };

  useEffect(() => {
    
    if (selectedDates.length === bookingData?.lesson_count) {
        updateFieldData("start_date", selectedStartDates);
        updateFieldData("end_date", selectedEndDates);
        updateFieldData("lesson_count", bookingData?.lesson_count);
        SelectPacknext();
    }
  }, [selectedDates]);

  return (
    <>
      <section className="calender_sec m-bot">
        <Container>
          <Row>
            <Col>
              <div className="check_availability">
                <div className="d-flex justify-content-center p-4 calender_head book_calender">
                  <p className="mb-0 text-center">
                    CHECK INSTRUCTOR AVAILABILITY
                  </p>
                </div>
                <div className="text-success text-center mt-3">
                  Please Select {bookingData?.lesson_count} Booking Date
                </div>
                <div className="text-indigo text-center">
                  <p>{`Selected Dates: ${selectedDates.length} / ${
                    bookingData?.lesson_count
                  }`}</p>
                </div>
                <div className="p-4">
                  <FullCalendar
                    ref={calendarRef}
                    id="calender"
                    eventClassNames={(arg) => {
                      if (arg.event.id === "selected-date") {
                        return "selected-date";
                      }
                      return "";
                    }}
                    initialView={mobileCheck() ? "timeGridDay" : "timeGridWeek"}
                    plugins={plugins}
                    customButtons={{
                      myCustomButton: {
                        text: "Today",
                        click: handleTodayButtonClick,
                      },
                    }}
                    editable={false}
                    events={Events}
                    slotMinTime="09:00"
                    select={handleDateSelect} // Handle date selection
                    selectable={true}
                    allDaySlot={false}
                    expandRows={true}
                    eventRender={(info) => {
                      // Check if the date is selected and change the background color
                      if (selectedDates.includes(info.event.startStr)) {
                        info.el.style.backgroundColor = "blue"; // Change to your desired color
                      }
                    }}
                    contentHeight={"500px"}
                    dayHeaderContent={({ date }) => {
                      const dayName = date.toLocaleDateString("locale", {
                        weekday: "short",
                      });
                      const newdate = date.getDate();
                      const currentDate = new Date().getDate();

                      return (
                        <>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                              className={`pt-2 ${
                                currentDate === newdate ? "#3599E7" : "black"
                              }`}
                            >
                              {dayName}
                            </div>
                            <span
                              className={`pt-1 ${
                                currentDate === newdate ? "current_Date" : ""
                              }`}
                              style={{
                                width: "25px",
                                height: "25px",
                                borderRadius: "50%",
                              }}
                            >
                              {newdate}
                            </span>
                          </div>
                        </>
                      );
                    }}
                    headerToolbar={{
                      left: "myCustomButton prev next",
                      center: "title",
                      right: "dayGridMonth,timeGridWeek,timeGridDay,listWeek",
                    }}
                    themeSystem="bootstrap5"
                  />
                </div>
              </div>
            </Col>
          </Row>
          <div className="button_wrapper text-center">
            <Button
              variant="primary"
              className="orange_btn text_manual br-10 select_lesson_btn ms-5 me-5 mb-2"
              onClick={() => {
                if (selectedDates.length > 0) {
                  updateFieldData("start_date", selectedStartDates);
                  updateFieldData("end_date", selectedEndDates);
                  updateFieldData("lesson_count", bookingData?.lesson_count);
                  SelectPacknext();
                } else {
                  alert("Please Select At Least One Date");
                }
              }}
            >
              Next
            </Button>
          </div>
        </Container>
      </section>
    </>
  );
};
