import React from "react";
import banner from "../../../assets/images/newpages/navigating-banner.png";
const NavigationBanner = () => {
  return (
    <section className="keyzi_conquer--wrapper keyzi_navigatingBanner--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h3>
                Driving Across the Divide: 
Navigating State-Specific Licensing Requirements in Australia
                </h3>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side bg-none">
                <img src={banner} alt="Car" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default NavigationBanner;
