import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import learnerone from "../../assets/images/learnercsone-one.jpg";
import learnertwo from "../../assets/images/learnercsone-two.jpg";
import learnerthree from "../../assets/images/learnercsone-three.jpg";
import playbtn from "../../assets/images/play-btn.svg"
import "./LearnerSayCsOne.css";
export const LearnerSayCsOne = () => {
  return (
    <section className="learnersaycsone--wrapper">
      <Container>
        <div className="learnersaycsone--inner">
          
          <div className="learnersaycsone--head">
          <h2 className="sub_head">What our learners say</h2>
          <p className="para_text">Choose a driving instructor you can trust</p>
          </div>
          <Row>
            <Col md={4}>
              <div className="learnersaycsone--card">
                <div className="card-img">
                  <img src={learnerone} className="img-fluid" alt="" />
                </div>
                <img src={playbtn} className="img-fluid play_btn"  alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className="learnersaycsone--card">
                <div className="card-img">
                  <img src={learnertwo} className="img-fluid" alt="" />
                </div>
                <img src={playbtn} className="img-fluid play_btn"  alt="" />
              </div>
            </Col>
            <Col md={4}>
              <div className="learnersaycsone--card">
                <div className="card-img">
                  <img src={learnerthree} className="img-fluid" alt="" />
                </div>
                <img src={playbtn} className="img-fluid play_btn"   alt="" />
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </section>
  );
};
