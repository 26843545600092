import React from "react";
import MainConquer from "../components/newpages/conquer/MainConquer";
import Mind from "../components/newpages/conquer/Mind";
import Journey from "../components/newpages/conquer/Journey";
import Path from "../components/newpages/conquer/Path";
import Quite from "../components/newpages/conquer/Quite";

const Conquer = () => {
  return (
   <>
    <MainConquer/> 
    <Mind/>
    <Journey/>
    <Path/>
    <Quite/>
   </>
  );
};

export default Conquer;
