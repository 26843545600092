import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./AboutInstructor.css";
import profile from "../../assets/icons/profile.svg";
import check from "../../assets/icons/check.svg";
import reviews from "../../assets/icons/reviews.svg";
import { Routers } from "./../../routes/Constant";
import { useNavigate, useParams } from "react-router-dom";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
// import { Autoplay } from "./swiper";
import "swiper/swiper-bundle.css";
// import { Navigation } from "swiper";
import { useDispatch } from "react-redux";
import { fetchInstructorsSingle } from "../../redux/slice/InstructorSlice.js";
import { resetAllState } from "../../redux/slice/rootSlice";
import { Navigation } from "swiper";
export const AboutInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const { instructorsdetails } = useSelector((state) => state.instructors);
  const { instructorsFilter } = useSelector((state) => state.instructors);

  console.log(instructorsdetails);
  console.log(instructorsFilter);

  let instructorData = instructorsdetails?.length > 0 ? instructorsdetails[0] : null;



  let instructorFilterData =
    instructorsFilter?.length > 0 ? instructorsFilter[0] : null;

  
  const index = params?.id?.lastIndexOf("_");
  const result = params?.id?.substr(index + 1);
  const [indexswiper, setIndexSwiper] = useState(result);
  useEffect(() => {
    if (params.id) {
      dispatch(fetchInstructorsSingle(params.id));
    }
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(
        indexswiper ? indexswiper : index,
        300,
        false
      );
    }
  }, [dispatch]);

  const changeInstructorHandler = (id) => {
    // const filteredObject = instructorFilterData?.data?.find(
    //   (obj) => obj?.id === id
    // );
    dispatch(fetchInstructorsSingle(id))
    console.log(id);
    navigate(`/moreInfo/${id}`);
  };

  const swiperRef = useRef(null);

  const BookLessonHandler = () => {
    navigate(Routers.Login);
    localStorage.setItem("booking", "pending");
  };

  const handleClick = (index) => {
    if (index) {
      setIndexSwiper(null);
    }
    if (swiperRef.current) {
      swiperRef.current.swiper.slideTo(index, 300, false);
    }
  };

  const scrollToSection = (sectionId) => {
    const element = document.getElementById(sectionId);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };


  useEffect(() => {
    scrollToSection("section-1");
  }, []);

  return (
    <>
      <section className="aboutInstructor_section m-bot">
        <div className="bg-grey p-5" id="section-1">
          <h2 className="sub_light_head text-center">
            ABOUT
            <br />
            <span className="sub_head text-indigo"> INSTRUCTOR</span>
          </h2>
        </div>
        <Container>
          <div className="p-1 p-lg-5 d-flex instructor_list">
            {/* ! OTHER INSTRUCTOR ! This section define.How many other instructor in your database  */}

            <h6 className="other_instruct_head pe-3 pt-3">
              Other
              <br className="d-none d-lg-block" /> Instructor
            </h6>
            {instructorFilterData ? (
              <Swiper
                slidesPerView={3}
                spaceBetween={5}
                centeredSlides={true}
                navigation={true}
                modules={[Navigation]}
                className="mySwiper"
                ref={swiperRef}
                breakpoints={{
                  575: {
                    slidesPerView: 1,
                    spaceBetween: 10,
                  },
                  768: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  992: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                  1200: {
                    slidesPerView: 3,
                    spaceBetween: 10,
                  },
                }}
              >
                {instructorFilterData?.data?.map((e, i) => {
                  return (
                    <SwiperSlide onClick={() => handleClick(i)} key={i}>
                      <div
                        key={e.id}
                        className="d-flex other_instructor_list"
                        onClick={() => {
                          changeInstructorHandler(e.id);
                        }}
                      >
                        <div className="text-center">
                          <div className="instructorimg_list_wrapper">
                            <img
                              src={
                                "http://167.71.238.150/keyzi-admin/public/" +
                                e.profile_image
                              }
                              alt="i1"
                            />
                          </div>
                          <p>{e.name}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            ) : (
              <p>Loading instructor data...</p>
            )}
          </div>

          {/* ! DETAIL OF INSTRUCTOR ! This section show you overall detail of current selected instructor */}

          <div className="bg-grey br-10">
            <Row className="div_border-b m-0 p-3 about_sec">
              <Col className="div_border-r">
                <div className="d-flex align-items-center">
                  <div className="instructorabout_img_wrapper">
                    <img
                      src={
                      
 "http://167.71.238.150/keyzi-admin/public/" +
                            instructorData?.data?.profile_image
                      }
                      alt="wadepic"
                      className="instructor_img m-3"
                    />
                  </div>
                  <div className="instructor_name_time">
                    <h5>
                        {instructorData?.data?.name}
                    </h5>
                    <h6>
                      Instructor Since{" "}
                      {new Date(
                            instructorData?.data?.created_at
                          ).getFullYear()}
                    </h6>
                  </div>
                </div>
              </Col>
              <Col className="div_border-r d-flex align-items-center">
                <div className=" d-flex m-3 kz_info_instruc">
                  <div className="d-flex bg-white rounded-pill p-3 shadow-sm me-3 wrap_icon_about">
                    <img className="m-auto" src={profile} alt="profile_icon" />
                  </div>
                  <div className="instructor_inner_det">
                    <h6>Keyzi Learners</h6>
                    <h3 className="m-0">
                      {instructorData?.data?.learner_count}
                    </h3>
                  </div>
                </div>
              </Col>
              <Col className="d-flex align-items-center">
                <div className="d-flex  justify-content-between m-3 review_section w-100">
                  <div className="d-flex">
                    <span className="d-flex align-items-center">
                      <div className="d-flex bg-white rounded-pill shadow-sm me-3 wrap_icon_about">
                        <img
                          className="m-auto"
                          src={reviews}
                          alt="reviews_icon"
                        />
                      </div>
                    </span>
                    <div className="instructor_inner_det">
                      <h6>Reviews</h6>
                      <h3 className="m-0">
                        {Math.round(instructorData?.data?.instructor_rating)}
                      </h3>
                    </div>
                  </div>
                  {/* ! BOOK LESSON ! Click this to navigate on login page */}
                  <Button
                    className="br-10 border-0 orange_btn book_lesson_btn ms-auto ms-lg-3"
                    onClick={BookLessonHandler}
                  >
                    Book a Lesson
                  </Button>
                </div>
              </Col>
            </Row>
            <Row className="p-3 m-0 instructor_info">
              <Col className="div_border-r">
                <div className="instructor_sub_section">
                  <h6 className="text-indigo">About Wade</h6>
                  <p>
                    {instructorData?.data?.about}
                  </p>
                </div>
              </Col>
              <Col className="div_border-r">
                <div className="instructor_sub_section">
                  <h6 className="text-indigo">Vehicle</h6>
                  <ul className="list-unstyled">
                    <li>
                      <span>
                        <img src={check} alt="check_icon" />
                      </span>
                      {instructorData?.data?.vehicle_model}{" "}
                      {instructorData?.data?.vehicle_name}
                    </li>
                    <li>
                      <span>
                        <img src={check} alt="check_icon" />
                      </span>
                      {instructorData?.data?.transmission_type}
                    </li>
                  </ul>
                </div>
              </Col>
              <Col>
                <div className="pt-3 instructor_sub_section">
                  <h6 className="text-indigo">Interests</h6>
                  <ul className="list-unstyled">
                    <li>
                      {instructorData?.data?.instructor_interest}
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};
