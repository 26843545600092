import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import "./SignUpInstructor.css";
import { useState } from "react";
import { LeftArrow } from "../assets/icons/LeftArrow";
import { Bannermain } from "../components/bannermain/Bannermain";
import { SignUpInstructorData } from "../redux/slice/authSlice";
import { useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../routes/Constant";
import { EyeClosed } from "../assets/icons/EyeClosed";
import { EyeShoow } from "../assets/icons/EyeShoow";

function PasswordInput({ label, name, register, errors }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div className="input_block">
       <div className="position-relative">
       <input
          type={showPassword ? "text" : "password"}
          className="input_tag br-10 p-4"
          {...register(name, { required: true })}
          placeholder={`${label}*`}
        />
        <span className="password-toggle" onClick={togglePasswordVisibility}>
          {showPassword ? <EyeShoow /> : <EyeClosed />}
        </span>
      </div>
      {errors[name] && (
        <div className="text-danger m-1">{errors[name].message}</div>
      )}
       </div>
    </>
  );
}

export const SignupInstructor = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const schema = yup.object().shape({
    first_name: yup.string().required("First Name  is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid Email format"),
    phone: yup.string().required("Phone Number is required"),
    notification_checkbox: "",
    condition_checkbox: yup
      .bool()
      .oneOf([true], "Please accept Terms and Conditions to continue"),
    car_registration_year: yup
      .string()
      .required("Car Registration year is required"),
    start_year: yup.string().required("Start Year is required"),
    car_model: yup.string().required("Car Model is required"),
    car_make: yup.string().required("Car Make is required"),
    password: yup.string().min(6, "Password must contain at least 6 character"),
    gender: yup.string().required("Gender is required"),
    password_confirmation: yup
      .string()
      .min(6, "Password must contain at least 6 character ")
      .oneOf([yup.ref("password")], "Password do not match"),
  });
  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const onSubmit = (values) => {
    console.log(values);
    dispatch(SignUpInstructorData(values));
    reset();
    navigate(Routers.Login);
  };



  return (
    <>
      <Bannermain bgconfirm="container-o-bg-clr ">
        <section className="signup_card m-top">
          <h5 className="main_head text-indigo text-center">SIGN UP</h5>
          <h6><Link className="back_Link" to={Routers.Login}><LeftArrow />Back</Link></h6>
          <Container className="login_container mt-5 login_sec br-10">
            <h5 className=" text-indigo text-center sub_head">
              SIGN UP TO START GROWING YOUR BUSINESS TODAY
            </h5>
            <Row>
              <Col>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        className="input_tag br-10 p-4"
                        {...register("first_name", { required: true })}
                        placeholder="First Name*"
                      />
                      {errors.first_name && (
                        <div className="text-danger m-1">
                          {errors.first_name.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="text"
                        className="input_tag br-10 p-4 "
                        {...register("last_name", { required: true })}
                        placeholder="Last Name*"
                      />
                      {errors.last_name && (
                        <div className="text-danger m-1">
                          {errors.last_name.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <select
                    className="input_tag form-control select_signup_learner br-10 p-3"
                    name="gender"
                    {...register("gender")}
                  >
                    <option value="" selected disabled>
                      Gender*
                    </option>
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    <option value="other">Other</option>
                  </select>
                  {errors.gender && (
                    <div className="text-danger m-1">
                      {errors.gender.message}
                    </div>
                  )}

                  <input
                    type="text"
                    className="input_tag br-10 p-4"
                    {...register("start_year", { required: true })}
                    placeholder="Start Year*"
                  />
                  {errors.start_year && (
                    <div className="text-danger m-1">
                      {errors.start_year.message}
                    </div>
                  )}
                  <input
                    type="text"
                    className="input_tag br-10 p-4"
                    {...register("car_make", { required: true })}
                    placeholder="Car Make*"
                  />
                  {errors.car_make && (
                    <div className="text-danger m-1">
                      {errors.car_make.message}
                    </div>
                  )}
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        className="input_tag br-10 p-4"
                        {...register("car_model", { required: true })}
                        placeholder="Car Model*"
                      />
                      {errors.car_model && (
                        <div className="text-danger m-1">
                          {errors.car_model.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="text"
                        className="input_tag br-10 p-4 "
                        {...register("car_registration_year", {
                          required: true,
                        })}
                        placeholder="Car Registration Year*"
                      />
                      {errors.car_registration_year && (
                        <div className="text-danger m-1">
                          {errors.car_registration_year.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        placeholder="Phone*"
                        maxLength="10"
                        className="input_tag br-10 p-4 me-2"
                        {...register("phone", { required: true })}
                      />
                      {errors.phone && (
                        <div className="text-danger m-1">
                          {errors.phone.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="Email*"
                        className="input_tag br-10 p-4 "
                        {...register("email", { required: true })}
                        placeholder="Email*"
                      />
                      {errors.email && (
                        <div className="text-danger m-1">
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-block d-sm-flex gap-2">
                    <PasswordInput
                      label="Password"
                      name="password"
                      register={register}
                      errors={errors}
                    />

                    <PasswordInput
                      label="Confirm Password"
                      name="password_confirmation"
                      register={register}
                      errors={errors}
                    />
                  </div>

                  <div className="form-check checkbox-xl mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="condition_checkbox"
                      {...register("condition_checkbox", { required: true })}
                      id="checkbox-1"
                    />
                    <label
                      className="form-check-label"
                      type="button"
                      htmlFor="checkbox-1"
                    >
                      I agree to the{" "}
                      <span className="text-indigo fw-bold">
                        Terms & Conditions.*
                      </span>
                    </label>

                  </div>
                  {errors.condition_checkbox && (
                      <div className="text-danger">
                        {errors.condition_checkbox.message}
                      </div>
                    )}
                  <div className="form-check checkbox-xl mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="notification_checkbox"
                      {...register("notification_checkbox")}
                      id="checkbox-2"
                    />
                    <label
                      type="button"
                      className="form-check-label"
                      htmlFor="checkbox-2"
                    >
                      Receive sms notifications.
                    </label>
                  </div>

                  <div className="d-flex  flex-sm-row  flex-column  justify-content-between inst_learn_btn pt-4">
                    <div>
                      <Button
                        variant="primary"
                        className="orange_btn text_manual create_account br-10 "
                        type="submit"
                      >
                        Create Account
                      </Button>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </section>
      </Bannermain>
    </>
  );
};
