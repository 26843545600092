import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import whyChoose from "../../../assets/images/newpages/why-choose.png"

const WhyChoose = () => {
  return (
    <div className="keyzi_whyChoose--wrapper">
      <Container>
        <div className='keyzi_whyChoose--inner'>
          <Row>
            <Col xl={6}>
              <div className='whyChoose--leftSide'>
                <div>
                <h4>Why Choose Keyzi?</h4>
                <p>Addressing Your Deepest Concerns With Compassion And Expertise</p>
                <p>We know that the thought of your child navigating busy streets can send a wave of worry through your heart. Our comprehensive driving packages are designed not just to teach driving but to instil confidence, safety, and responsibility in young drivers. Here's how we ease your concerns and pave a path of learning that's reassuring for both you and your child:</p>
                </div>
                <img src={whyChoose} className='img-fluid' alt="" />
              </div>
            </Col>
            <Col xl={6}>
            <div className='whyChoose--rightSide'>
              <ul>
                <li>
                  <h5>Time And Stress Saved</h5>
                  <p>Balancing work, life, and teaching your teen to drive can be overwhelming. Let’s take the instruction wheel. You’ll save time and avoid the potential stress and strain on your relationship that teaching driving can sometimes cause.</p>
                </li>
                <li>
                  <h5>Safety Comes First</h5>
                  <p>Our dual control systems allow our instructors to take control in difficult situations, ensuring the safety of your teen at every turn. This feature addresses the paramount concern of every parent - keeping their child safe.</p>
                </li>
                <li>
                  <h5>Updated and comprehensive Curriculum</h5>
                  <p>Our lessons are always current with the latest road rules and test requirements. We provide a structured learning experience that covers everything from the basics to advanced driving techniques, preparing your teen for not just the test, but for a lifetime of safe driving.</p>
                </li>
                <li>
                  <h5>Cost-Effective Learning Paths</h5>
                  <p>Investing in professional driving lessons might seem like an added expense, but it’s a cost-effective choice in the long run. Our bulk lesson packages offer savings, ensuring your teen receives comprehensive instruction without cutting any corners. Plus, our trained professionals can significantly reduce the wear and tear on your family car - and potentially your nerves!</p>
                </li>
                <li>
                  <h5>Support Every Step Of The Way</h5>
                  <p>We understand the rollercoaster of emotions that comes with this journey. Our team is here to support not just your teen but you as well. From the first lesson to the day they pass their test, and beyond, we're with you every mile of the journey.</p>
                </li>
              </ul>
            </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  )
}

export default WhyChoose