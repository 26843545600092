import React from "react";
import wadepic from "../../../assets/images/wadepic.png";
import "./BookingDetails.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { BookingDescriptionById } from "../../../redux/slice/authSlice";
import { Routers } from "../../../routes/Constant";
import { useNavigate } from "react-router-dom";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { cancelBooking } from "../../../redux/slice/authSlice";
import { message } from "antd";
export const BookingDetails = () => {
  const bookingId = useParams();
  const [showCancel, setShowCancel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { bookingDescription } = useSelector((state) => state?.auth);
  const { bookingcancel } = useSelector((state) => state?.auth);
  const [showCancelMessage, setShowCancelMessage] = useState(false);
  const [smShow, setSmShow] = useState(false);




  useEffect(() => {
    dispatch(BookingDescriptionById(bookingId.id));
  }, [dispatch, bookingId.id]);

  useEffect(() => {
    dispatch(BookingDescriptionById(bookingId.id));

  }, [bookingcancel]);

  useEffect(() => {
    if (bookingDescription?.data?.is_canceled === 0) {
      setShowCancelMessage(false);
    } else {
      setShowCancelMessage(true);
    }
    console.log(bookingDescription)
  }, [bookingDescription]);

  useEffect(() => {
    if (bookingDescription?.data?.start) {
      try {
        const ExpiryDate = new Date(bookingDescription.data.start);
        const currentDate = new Date();

        if (ExpiryDate > currentDate) {
          setShowCancel(true);
        } else {
          setShowCancel(false);
        }
      } catch (error) {
        console.error("Invalid date:", error);
      }
    }
  }, [bookingDescription]);

  function convertDateFormat(inputDateStr) {
    if(inputDateStr === null){
      return "null"
    }
    const originalDate = new Date(inputDateStr);

    const day = originalDate.toLocaleString("en-us", { weekday: "long" });
    const time = originalDate.toLocaleString("en-us", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const month = originalDate.toLocaleString("en-us", { month: "long" });
    const date = originalDate.getDate();

    return `${time} ${day}, ${month} ${date}`;
  }
  const bookingCancelConfirm = async () => {
    try {
      await dispatch(cancelBooking({ booking_id: bookingId.id }));
      setSmShow(false); 
      message.success({
        content: "Your Booking is Cancelled Successfully",
        duration: 3,
      });
    } catch (err) {
      console.error("Error canceling booking:", err);
    }
  };
  

  

  return (
    <>
      <section>
        <div className="container booking_detail_Container bg-white  das_comp_shdo br-10">
          <div className="page-title-box p-1 pt-md-4 ps-md-4 ">
            <div className="row align-items-center">
              <div className="d-block text-center d-md-flex mt-2 mb-2 justify-content-between">
                <h6 className="page-title heading_booking_details">
                  Booking Details
                </h6>
                {bookingDescription?.data?.start ? <div>
                  {showCancel ? (
                    showCancelMessage ? (
                      <span className="booking_can_message">
                        Booking is Cancelled
                      </span>
                    ) : (
                      <button
                        className="btn btn-danger"
                        onClick={() => setSmShow(true)}
                      >
                        Cancel Booking
                      </button>
                    )
                  ) : (
                    bookingDescription?.data?.is_feedback === 0 ? (<button
                      className="btn btn-primary me-2 bg-indigo border-0 rate_instructor_btn"
                      onClick={() => {
                        navigate(
                          `/auth/dashboard/booking/bookingDetails/rating/${bookingId.id}`
                        );
                      }}
                    >
                      Rate Your Instructor
                    </button>) : (
                      <span className="feedback_done_message">
                      Feedback is Done
                    </span>
                    )
                    
                  )}
                </div> : ""}
              </div>
            </div>
          </div>
          <div className="card-body account_detail row p-none p-md-4 pt-md-2">
            <div className="bg-gray">
              <div className="d-flex gap-20 align-items-center instructor_profilecard_wrappper">
                <div className="acc_img rounded-pill me-3">
                  <img
                    src={`http://167.71.238.150/keyzi-admin/public/${bookingDescription?.data?.instructor?.profile_image}`}
                    className="booking_detail_image"
                    alt=""
                  />
                </div>
                <div className="acc_detail">
                  <h5 className="text-capitalize">
                    {bookingDescription?.data?.instructor?.name}
                  </h5>
                  <p>Instructor</p>
                </div>
              </div>
              <hr className="kz_hr_line" />
              <div className="row m-0 kz_accountant_detail">
                <div className="col-lg-6 col-md-6 after_vr_line position-relative kz_col_left">
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Email
                    </label>
                    <p className="kz_input_data">
                      {bookingDescription?.data?.instructor?.email}
                    </p>
                  </div>
                  <hr className="kz_hr_line" />
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Status
                    </label>
                    {showCancelMessage ? (
                      <p className="kz_input_data cancel_status">Cancel</p>
                    ) : (
                      <p className="kz_input_data active_status">Active</p>
                    )}
                  </div>
                  <hr className="kz_hr_line" />
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Start Date & Time
                    </label>
                    <p className="kz_input_data">
                      {convertDateFormat(bookingDescription?.data?.start)}
                    </p>
                  </div>
                </div>
                <div className="col-lg-6 col-md-6 kz_col_right">
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Phone
                    </label>
                    <p className="kz_input_data">
                      {bookingDescription?.data?.instructor?.phone}
                    </p>
                  </div>
                  <hr className="kz_hr_line" />
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Gender
                    </label>
                    <p className="kz_input_data">
                      {bookingDescription?.data?.instructor?.gender}
                    </p>
                  </div>
                  <hr className="kz_hr_line" />
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      End Date & Time
                    </label>
                    <p className="kz_input_data">
                      {convertDateFormat(bookingDescription?.data?.end)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-transparent">
              <div className="acc_title">
                <h5 className="heading_booking_details mt-3">
                  Package Details
                </h5>
                <hr className="kz_hr_line" />

                <div className="d-flex align-items-center payment_content ms-3">
                  <p>
                    {
                      bookingDescription?.data?.package?.sub_service?.service
                        ?.name
                    }
                  </p>
                </div>
                <div className="d-flex align-items-center payment_content ms-3">
                  <p>
                    Time-Duration : {bookingDescription?.data?.package?.time}{" "}
                    min
                  </p>
                </div>
                <div className="d-flex align-items-center payment_content ms-3 mb-0">
                  <p>
                    Package Amount : {bookingDescription?.data?.package?.amount}
                    $
                  </p>
                </div>
              </div>
            </div>
            <div className="bg-transparent">
              <div className="acc_title">
                <h5 className="heading_booking_details mt-3">
                  Pickup Location
                </h5>
              </div>
              <hr className="kz_hr_line" />
              <div className="row m-0 kz_accountant_detail">
                <div className="col-lg-12 col-md-12 kz_col_left">
                  <div className="form-group">
                    <label htmlFor="" className="kz_label">
                      Address
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      disabled
                      placeholder={`${bookingDescription?.data?.city} ${bookingDescription?.data?.pickup_location} ${bookingDescription?.data?.postal_code}`}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-gray">
              <div className="payment_title">
                <h5 className="heading_booking_details mt-3">Payment</h5>
              </div>
              <hr className="kz_hr_line" />
              <div className="d-flex align-items-center payment_content ms-2 text-indigo mt-3">
                <h4>
                  Payment Type :{" "}
                  <span className="text-capitalize">
                    {bookingDescription?.data?.payment_type}
                  </span>
                </h4>
              </div>
              <div className="d-flex align-items-center payment_content ms-2 mt-3 text-indigo">
                <h4>Total : ${bookingDescription?.data?.package?.amount}</h4>
              </div>
            </div>
          </div>
          <Modal
            size="sm"
            show={smShow}
            onHide={() => setSmShow(false)}
            className="booking_cancel_modal"
            aria-labelledby="example-modal-sizes-title-sm "
            centered
          >
            <Modal.Header closeButton className="border-0">
              <Modal.Title id="example-modal-sizes-title-sm"></Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 className="text-center">Confirm booking cancellation?</h6>
            </Modal.Body>
            <Modal.Footer className="border-0">
              <Button onClick={bookingCancelConfirm} className=" bg-indigo">
                Confirm
              </Button>
              <Button onClick={() => setSmShow(false)} className="btn-danger">
                Cancel
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </section>
    </>
  );
};
