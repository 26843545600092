import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import createSagaMiddleware from 'redux-saga';
import FeedBackReducer from "./slice/LearnerSlice"
import calenderReducer from './slice/CalenderSlice';
import instructorReducer from './slice/InstructorSlice';
import authReducer from './slice/authSlice';
import rootSaga from './saga/rootSaga';
import instructorPostalCodeReducer from './slice/postalSlice'
import objectReducer from './slice/objectSlice'
import {
  persistStore,
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from "redux-persist";

const persistConfig = {
  key: "persist_st",
  storage,
};

const sagaMiddleware = createSagaMiddleware();
const rootReducer = combineReducers({

  calender: calenderReducer,
  instructors: instructorReducer,
  auth: authReducer,
  instructorPostalCodeReducer: instructorPostalCodeReducer,
  FeedBackReducer: FeedBackReducer,
  objectReducer:objectReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const ignoredActions = [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER];

const store = configureStore({
  reducer: persistedReducer,
  middleware: [
    ...getDefaultMiddleware({
      serializableCheck:false
    }),
    sagaMiddleware,
  ],
  devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
});

sagaMiddleware.run(rootSaga);

export const persistor = persistStore(store);
export default store;



// import { combineReducers } from "@reduxjs/toolkit";
// import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import createSagaMiddleware from 'redux-saga';
// import FeedBackReducer from "./slice/LearnerSlice"
// import calenderReducer from './slice/CalenderSlice';
// import instructorReducer from './slice/InstructorSlice';
// import authReducer from './slice/authSlice';
// import rootSaga from './saga/rootSaga';
// import instructorPostalCodeReducer from './slice/postalSlice'
// import rootSliceReducer from './slice/rootSlice';
// const sagaMiddleware = createSagaMiddleware();
// const rootReducer = combineReducers({
//   calender: calenderReducer,
//   instructors: instructorReducer,
//   auth: authReducer,
//   instructorPostalCodeReducer: instructorPostalCodeReducer,
//   FeedBackReducer: FeedBackReducer,
//   root:rootSliceReducer
// });

// const store = configureStore({
//   reducer: rootReducer,
//   middleware: [
//     ...getDefaultMiddleware({
//       serializableCheck: false
//     }),
//     sagaMiddleware,
//   ],
//   devTools: process.env.NODE_ENV !== 'production', // Enable Redux DevTools only in development
// });

// sagaMiddleware.run(rootSaga);

// export default store;
