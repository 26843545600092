import React from "react";
import mind from "../../../assets/images/newpages/mind.png";
import road from "../../../assets/images/newpages/road.png";

const GuideContent = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner">
          <p>
          The journey to becoming a skilled, confident driver is filled with excitement, challenges, and invaluable learning experiences. As you stand at this crossroads, one critical decision looms large: Should your path be guided by a professional driving instructor or navigate the familiar but potentially bumpy road of parent-taught lessons? While both routes hold merit, the choice you make can significantly impact your journey to independence. Let's explore the pros and cons, leaning into the wisdom of choosing a service like ours to steer you or your teenager towards success.
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default GuideContent;
