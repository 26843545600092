import React from 'react'
import EmbarkBanner from '../components/newpages/embark/EmbarkBanner'
import Content from '../components/newpages/unlock/Content'
import EmbarkContent from '../components/newpages/embark/EmbarkContent'
import EmbarkSteps from '../components/newpages/embark/EmbarkSteps'
import EmbarkAhead from '../components/newpages/embark/EmbarkAhead'

const Embark = () => {
  return (
    <>
      <EmbarkBanner/>
      <EmbarkContent/>
      <EmbarkSteps/>
      <EmbarkAhead/>
    </>
  )
}

export default Embark