import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Col';
import Card from 'react-bootstrap/Card';
import { CardData } from './CardData.jsx';
import './Why_Keyzi_Card.css';

export const CardSection = () => {
  return (
    //  ! WHY KEYZI ! this is why keyzi this section does'nt have any functionality

    <section className="whykeyzi_section m-top m-bot">
      <Container>
        <Row>
          <Col className='text-center'>
            <h2 className=" sub_light_head">
              WHY <span className="text-indigo sub_head">KEYZI</span>
            </h2>
            <p className=" mb-5 para_text">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit
            </p>
          </Col>
        </Row>

        <Row>
          <Col>
            <div className="key_card_sec d-flex flex-md-row  flex-column justify-content-between ">
              {CardData?.map((i, ind) => {
                return (
                  <Card
                    className="bg-grey key_card p-3 col-12 col-lg-4 br-10"
                    key={ind}
                  >
                    <Card.Body className="pt-4">
                      <Card.Img
                        variant="top"
                        src={i.img_url}
                        style={{ width: 'unset' }}
                        className="img-fluid img_card "
                      />
                    </Card.Body>
                    <Card.Body>
                      <Card.Title className="key_inner_head">
                        {i.head}
                      </Card.Title>
                      <Card.Text className="card-text-det">
                        {i.headDetail}
                      </Card.Text>
                    </Card.Body>
                  </Card>
                );
              })}
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
