import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./PaymentDetails.css";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripePaymentForm from "./StripePaymentForm";
export const PaymentDetails = ({ SelectPacknext,tokenGetter,bookingData,updateFieldData }) => {
    const stripePromise = loadStripe(
        "pk_test_51JyxVVEOzkB81rfAEISd4WanV1Zdj8e6D2ks5QNJUbcmjZyh8HYncpiV1RPJRqeHcSh5tBcdSZhlPdX50EXozF2z00ml73iUBn"
    );
    return (
        <section className="paymentdetails_section m-bot">
            <Container>
                <Row>
                    <Col className="mt-5">
                        <Elements stripe={stripePromise}>
                            <StripePaymentForm SelectPacknext={SelectPacknext} tokenGetter={tokenGetter} bookingData={bookingData} updateFieldData={updateFieldData}/>
                        </Elements>
                    </Col>
                </Row>
            </Container>
        </section>
    );
};