import React from 'react'

const Profile = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="60" height="60" viewBox="0 0 60 60" fill="none">
  <path d="M54.122 49.6875C50.5524 43.5164 45.0516 39.0914 38.6321 36.9938C41.8075 35.1034 44.2746 32.223 45.6545 28.7949C47.0344 25.3667 47.2508 21.5803 46.2705 18.0173C45.2902 14.4542 43.1675 11.3114 40.2281 9.07157C37.2888 6.83172 33.6955 5.61865 30.0001 5.61865C26.3046 5.61865 22.7113 6.83172 19.772 9.07157C16.8327 11.3114 14.7099 14.4542 13.7296 18.0173C12.7493 21.5803 12.9658 25.3667 14.3457 28.7949C15.7256 32.223 18.1927 35.1034 21.3681 36.9938C14.9485 39.0891 9.44774 43.5141 5.87821 49.6875C5.74731 49.901 5.66048 50.1385 5.62286 50.386C5.58523 50.6335 5.59756 50.8861 5.65913 51.1288C5.72069 51.3715 5.83024 51.5994 5.98132 51.7991C6.13239 51.9988 6.32192 52.1661 6.53874 52.2914C6.75555 52.4166 6.99525 52.4972 7.2437 52.5283C7.49214 52.5594 7.7443 52.5404 7.98529 52.4724C8.22628 52.4045 8.45123 52.289 8.64685 52.1327C8.84248 51.9764 9.00482 51.7826 9.1243 51.5625C13.5399 43.9313 21.3446 39.375 30.0001 39.375C38.6556 39.375 46.4602 43.9313 50.8759 51.5625C50.9953 51.7826 51.1577 51.9764 51.3533 52.1327C51.5489 52.289 51.7739 52.4045 52.0149 52.4724C52.2559 52.5404 52.508 52.5594 52.7565 52.5283C53.0049 52.4972 53.2446 52.4166 53.4614 52.2914C53.6782 52.1661 53.8678 51.9988 54.0188 51.7991C54.1699 51.5994 54.2795 51.3715 54.341 51.1288C54.4026 50.8861 54.4149 50.6335 54.3773 50.386C54.3397 50.1385 54.2529 49.901 54.122 49.6875ZM16.8751 22.5C16.8751 19.9041 17.6449 17.3666 19.087 15.2082C20.5292 13.0498 22.5791 11.3675 24.9774 10.3741C27.3756 9.38071 30.0146 9.12079 32.5606 9.62722C35.1066 10.1337 37.4453 11.3837 39.2809 13.2193C41.1164 15.0548 42.3665 17.3935 42.8729 19.9395C43.3793 22.4855 43.1194 25.1245 42.126 27.5227C41.1326 29.921 39.4503 31.9709 37.2919 33.4131C35.1335 34.8553 32.596 35.625 30.0001 35.625C26.5203 35.6213 23.184 34.2373 20.7234 31.7767C18.2628 29.3161 16.8788 25.9799 16.8751 22.5Z" fill="#FF8354"/>
</svg>
  )
}

export default Profile