import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import map from "../../../assets/images/newpages/aus_map.png";
import { Link } from "react-router-dom";
const NavigatingTeritory = () => {
  return (
    <>
      <section className="keyzi_teritory--wrapper">
      <div className="text-center">
      <img src={map} className="img-fluid " alt="Visa" />
      </div>
        <Container>
          <div className="keyzi_teritory--inner">
            <Row>
              <Col lg={6}>
              <ul className="teritory--list">
                    <li>
                      <h5>AUSTRALIAN CAPITAL TERRITORY (ACT)</h5>
                      <p>"In the heart of Australia's political landscape, the ACT's Graduated Licensing Scheme is tailored to ensure drivers are meticulously prepared, embodying a commitment to road safety and excellence."</p>
                      <h6><span>More Information: </span>For detailed steps, requirements, and resources, visit the <Link href="">ACT Government Licensing Information.</Link></h6>
                    </li>
                    <li>
                      <h5>NEW SOUTH WALES (NSW)</h5>
                      <p>"NSW leads with digital innovation and support for drivers, offering resources like digital logbooks and the Safer Drivers Course, enriching the learning journey with practical experience."</p>
                      <h6><span>More Information:</span> Explore the comprehensive guide to getting your licence in NSW at <Link href="">NSW Transport and Roads.</Link></h6>
                    </li>
                    <li>
                      <h5>NORTHERN TERRITORY (NT)</h5>
                      <p>"With its vast terrains, the NT focuses on the quality of driving and hazard perception over minimum supervised hours, ensuring drivers are adept at navigating its unique landscapes."</p>
                      <h6><span>More Information: </span>Find out more about licensing in NT at the <Link href="">NT Government Driver Licence Information.</Link></h6>
                    </li>
                    <li>
                      <h5>QUEENSLAND (QLD)</h5>
                      <p>"Reflecting QLD's forward-thinking approach, learners are encouraged to log driving hours using TMR approved apps, integrating technology into driver education."</p>
                      <h6><span>More Information:</span> For a detailed understanding of QLD's licensing process, visit <Link href="">Queensland Government Transport and Main Roads.</Link></h6>
                    </li>
                  </ul>
              </Col>
              <Col lg={6}>
              <ul className="teritory--list">
                    <li>
                      <h5>SOUTH AUSTRALIA (SA)</h5>
                      <p>"SA's Competency-Based Training approach emphasises skills and readiness, offering a unique path to licensure that's as diverse as the state itself."</p>
                      <h6><span>More Information:</span> Learn about SA's driving licence requirements at <Link href="">SA.GOV.AU: Licensing.</Link></h6>
                    </li>
                    <li>
                      <h5>TASMANIA (TAS)</h5>
                      <p>"Tasmania ensures drivers are ready for its unique driving conditions with a comprehensive L2 assessment and a two-stage provisional licence system."</p>
                      <h6><span>More Information:</span> Discover how to get licensed in Tasmania by visiting <Link href="">Transport Services Tasmania.</Link></h6>
                    </li>
                    <li>
                      <h5>VICTORIA (VIC)</h5>
                      <p>"Victoria equips new drivers with critical skills for safety through a mandatory Hazard Perception Test, reflecting the state's meticulous approach to driver preparation."</p>
                      <h6><span>More Information:</span> For Victoria's licensing details, head to <Link href="">VicRoads Driver Licences.</Link></h6>
                    </li>
                    <li>
                      <h5>WESTERN AUSTRALIA (WA)</h5>
                      <p>"WA's dedication to cultivating skilled drivers is evident in its comprehensive Practical Driving Assessment, readying drivers for its expansive roads."</p>
                      <h6><span>More Information:</span> Get all the information you need on WA's licensing process at the <Link href="">Department of Transport WA.</Link></h6>
                    </li>
                  </ul>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default NavigatingTeritory;

