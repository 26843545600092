
import { all } from 'redux-saga/effects';
import authSaga from './authSaga';
import instructorSaga from './instructorSaga';
import instructorsPostalCodeSaga from './postalSaga'
import  FeedBack from './feedbackSaga'
import calenderSaga from './calenderSaga'

function* rootSaga() {
  yield all([
    instructorSaga(),
    authSaga(),
    instructorsPostalCodeSaga(),
    calenderSaga(),
    FeedBack()
  ]);
}

export default rootSaga;
