// import React, { useState } from "react";
// import { Button } from "react-bootstrap";
// import searchi from "../../assets/images/searchi.svg";
// import searchiblack from "../../assets/images/searchiblack.svg";
// import "./InputSelector.css";
// import Select from "react-select";
// import { useNavigate } from "react-router-dom";
// import { Routers } from "../../routes/Constant";
// import { Link } from "react-router-dom";
// import FilterPopup from "../filterpopup/FilterPopup";
// import { useSelector, useDispatch } from "react-redux";
// import { fetchInstructorsPostalCode } from "../../redux/slice/postalSlice";
// import { fetchInstructorsFilter } from "../../redux/slice/InstructorSlice";
// import { useEffect } from "react";
// import automatic from "../../assets/images/automatic.png";
// import manual from "../../assets/images/manual.png";
// import Form from "react-bootstrap/Form";

// function InputSelector(props) {
//   const currentUrlsite = window.location.href;
//   const containsCorporateSiteone = currentUrlsite.includes("corporatesiteone");
//   const containsCorporateSitetwo = currentUrlsite.includes("corporatesitetwo");
//   console.log(containsCorporateSitetwo);
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const [vtchecked, setVtchecked] = useState(false);
//   const { user, error } = useSelector((state) => state?.auth);
//   const { instructors } = useSelector(
//     (state) => state.instructorPostalCodeReducer
//   );
//   const response = instructors?.data?.map((e) => ({
//     value: e.pin_code,
//     label: `${e.pin_code} ${e.city_name}`,
//   }));

//   const currentUrl = window.location.href;
//   const containsSite2 = currentUrl.includes("site2");
//   console.log(containsSite2);

//   useEffect(() => {
//     dispatch(fetchInstructorsPostalCode());
//   }, [dispatch]);

//   const [modalShow, setModalShow] = useState(false);
//   const [searchFilters, setSearchFilters] = useState({});
//   const [filterCount, setFilterCount] = useState(0);
//   const [selectedOption, setSelectedOption] = useState({
//     rating: "",
//     pin_code: "",
//     experience: "",
//     transmission_type: "automatic",
//     is_login: 0,
//     learner_id: null,
//   });

//   const token = localStorage.getItem("token");

//   useEffect(() => {
//     if (token) {
//       setSelectedOption({
//         ...selectedOption,
//         is_login: 1,
//         learner_id: user?.data?.data?.id,
//       });
//     }
//   }, [token]);

//   const handleSearch = (filters) => {
//     setSearchFilters(filters);
//     setModalShow(false);
//     const maxLimit = 2;

//     if (Object.keys(filters).length === 0) {
//       setFilterCount(0);
//     } else {
//       const newValueCapped = Math.min(filterCount, maxLimit);

//       if (filters.experience && filters.rating) {
//         setFilterCount(Math.min(newValueCapped + 2, maxLimit));
//       } else {
//         setFilterCount(Math.min(newValueCapped + 1, maxLimit));
//       }
//     }
//   };

//   const instructorSearchHandler = (event) => {
//     event.preventDefault();
//     const updatedOption = {
//       ...selectedOption,
//       rating: searchFilters.rating,
//       experience: searchFilters.experience,
//     };
//     setSelectedOption(updatedOption);
//     console.log(updatedOption);
//     dispatch(fetchInstructorsFilter(updatedOption));
//     navigate(Routers.Manual);
//   };

//   const handleChange = (option) => {
//     setSelectedOption({
//       ...selectedOption,
//       pin_code: option.value,
//     });
//   };

//   const vechtypehandler = (value) => {
//     if (value === "automatic") {
//       setVtchecked(false);
//     } else {
//       setVtchecked(true);
//     }
//   };

//   const handleRadioChange = (event) => {
//     setVtchecked(!vtchecked);
//     setSelectedOption({
//       ...selectedOption,
//       transmission_type: event.target.value,
//     });
//   };

//   // ! INPUT SELECTOR ! This component is form input selector in which the user can find instructors nearly automatically or manually
//   return (
//     <>
//       {containsCorporateSiteone || containsCorporateSitetwo ? (
//         <form
//           className="me-auto input_selector_filter"
//           onSubmit={instructorSearchHandler}
//         >
//           <div className={`input-group input-search ${props.maxWidth}`}>
//             <div className="border_inp_btn">
//               <div className="d-flex justify-content-center search_instructor--wrapper align-items-center">
//                 <label
//                   className={`btn border-0 ${!vtchecked && "auto_select"}`}
//                   htmlFor="custom-switch"
//                 >
//                   <img src={automatic} alt="" className="img-fluid auto-icon" />{" "}
//                   <span className="ms-2 vehicle_type">Automatic</span>
//                 </label>
//                 {containsCorporateSitetwo ? <Form.Check
//                   type="switch"
//                   id="custom-switch"
//                   className="cs-sitetwo"
//                   checked={vtchecked}
//                   onChange={handleRadioChange}
//                   value={vtchecked ? "automatic" : "manual"}
//                 /> : <Form.Check
//                 type="switch"
//                 id="custom-switch"
//                 checked={vtchecked}
//                 onChange={handleRadioChange}
//                 value={vtchecked ? "automatic" : "manual"}
//               />}
//                 <label
//                   className={`btn rounded-3 border-0 ${
//                     vtchecked && "manual_select"
//                   }`}
//                   htmlFor="custom-switch"
//                 >
//                   <span className="vehicle_type">Manual</span>
//                   <img
//                     src={manual}
//                     alt=""
//                     className="img-fluid manual-icon ms-2"
//                   />{" "}
//                 </label>
//               </div>
//             </div>
//             <div className="d-flex align-items-center p-lg-0 p-1 input_select_wrapper">
//               <span className="search_icon--wrapper">
//                 {containsCorporateSitetwo ? <img src={searchiblack} alt="search_icon" /> : <img src={searchi} alt="search_icon" />}
//               </span>
//               <div className="filter_search_input_select_style">
//                 {response ? (
//                   <Select
//                     value={
//                       response?.length > 0
//                         ? response.find(
//                             (opt) => opt.value === selectedOption?.pin_code
//                           )
//                         : null
//                     }
//                     options={response}
//                     onChange={handleChange}
//                     placeholder="Pop in postcode or suburb"
//                     className="selector_li p-0"
//                   />
//                 ) : null}
//               </div>
//             </div>
//             <div className="input_filter_button_wrapper">
//               <Button className="btn_search br-10 " type="submit">
//                 Instructor Search
//               </Button>
//             </div>
//             <FilterPopup
//               show={modalShow}
//               onHide={() => setModalShow(false)}
//               onSearch={handleSearch}
//             />
//           </div>
//         </form>
//       ) : (
//         <form
//           className="me-auto input_selector_filter"
//           onSubmit={instructorSearchHandler}
//         >
//           <div
//             className={`input-group mt-3 justify-content-evenly input-search ${props.maxWidth}`}
//           >
//             <div className="border_inp_btn">
//               <div className="d-flex justify-content-center search_instructor--wrapper align-items-center">
//                 <label
//                   className={`btn border-0 ${!vtchecked && "auto_select"}`}
//                   htmlFor="custom-switch"
//                 >
//                   <img src={automatic} alt="" className="img-fluid auto-icon" />{" "}
//                   <span className="ms-2 vehicle_type">Automatic</span>
//                 </label>
//                 <Form.Check
//                   type="switch"
//                   id="custom-switch"
//                   checked={vtchecked}
//                   onChange={handleRadioChange}
//                   value={vtchecked ? "automatic" : "manual"}
//                 />
//                 <label
//                   className={`btn rounded-3 border-0 ${
//                     vtchecked && "manual_select"
//                   }`}
//                   htmlFor="custom-switch"
//                 >
//                   <img src={manual} alt="" className="img-fluid manual-icon" />{" "}
//                   <span className="ms-2 vehicle_type">Manual</span>
//                 </label>
//               </div>
//             </div>
//             <div className="d-flex align-items-center p-lg-0 p-1 input_select_wrapper">
//               <span className="search_icon--wrapper">
//                 <img src={searchi} alt="search_icon" />
//               </span>
//               <div className="filter_search_input_select_style">
//                 {response ? (
//                   <Select
//                     value={
//                       response?.length > 0
//                         ? response.find(
//                             (opt) => opt.value === selectedOption?.pin_code
//                           )
//                         : null
//                     }
//                     options={response}
//                     onChange={handleChange}
//                     placeholder={
//                       containsSite2
//                         ? "Pop in postcode or suburb"
//                         : "Pop in postcode or suburb"
//                         // : "Enter your post code or suburb"
//                     }
//                     className="selector_li p-0"
//                   />
//                 ) : null}
//               </div>
//             </div>
//             <div className="input_filter_button_wrapper">
//               <Button className="btn_search br-10 " type="submit">
//               {props?.label ? props?.label : containsSite2 ? "Instructor Search" : "Find My Instructor"}
//               </Button>
//             </div>
//             <FilterPopup
//               show={modalShow}
//               onHide={() => setModalShow(false)}
//               onSearch={handleSearch}
//             />
//           </div>
//         </form>
//       )}
//     </>
//   );
// }

// export default InputSelector;

import React, { useState } from "react";
import { Button } from "react-bootstrap";
import searchi from "../../assets/images/searchi.svg";
import searchiblack from "../../assets/images/searchiblack.svg";
import "./InputSelector.css";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { Routers } from "../../routes/Constant";
import { Link } from "react-router-dom";
import FilterPopup from "../filterpopup/FilterPopup";
import { useSelector, useDispatch } from "react-redux";
import { fetchInstructorsPostalCode } from "../../redux/slice/postalSlice";
import { fetchInstructorsFilter } from "../../redux/slice/InstructorSlice";
import { useEffect } from "react";
import automatic from "../../assets/images/automatic.png";
import manual from "../../assets/images/manual.png";
import Form from "react-bootstrap/Form";
import { AsyncPaginate } from "react-select-async-paginate";
import axios from "axios";

function InputSelector(props) {
  const currentUrlsite = window.location.href;
  const containsCorporateSiteone = currentUrlsite.includes("corporatesiteone");
  const containsCorporateSitetwo = currentUrlsite.includes("corporatesitetwo");
  console.log(containsCorporateSitetwo);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [vtchecked, setVtchecked] = useState(false);
  const { user, error } = useSelector((state) => state?.auth);
  const { instructors } = useSelector(
    (state) => state.instructorPostalCodeReducer
  );
  const [inputValue, setInputValue] = useState(""); // State to manage input value
  const response = instructors?.data?.map((e) => ({
    value: e.pin_code,
    label: `${e.pin_code} ${e.city_name}`,
  }));

  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes("site2");
  console.log(containsSite2);

  useEffect(() => {
    dispatch(fetchInstructorsPostalCode());
  }, [dispatch]);

  const [modalShow, setModalShow] = useState(false);
  const [searchFilters, setSearchFilters] = useState({});
  const [filterCount, setFilterCount] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedOption, setSelectedOption] = useState({
    rating: "",
    city_id: "",
    experience: "",
    transmission_type: "automatic",
    is_login: 0,
    learner_id: null,
  });

  const token = localStorage.getItem("token");

  useEffect(() => {
    if (token) {
      setSelectedOption({
        ...selectedOption,
        is_login: 1,
        learner_id: user?.data?.data?.id,
      });
    }
  }, [token]);

  const handleSearch = (filters) => {
    setSearchFilters(filters);
    setModalShow(false);
    const maxLimit = 2;

    if (Object.keys(filters).length === 0) {
      setFilterCount(0);
    } else {
      const newValueCapped = Math.min(filterCount, maxLimit);

      if (filters.experience && filters.rating) {
        setFilterCount(Math.min(newValueCapped + 2, maxLimit));
      } else {
        setFilterCount(Math.min(newValueCapped + 1, maxLimit));
      }
    }
  };

  const instructorSearchHandler = (event) => {
    event.preventDefault();
    const updatedOption = {
      ...selectedOption,
      rating: searchFilters.rating,
      experience: searchFilters.experience,
    };
    localStorage.setItem("updatedOption",JSON.stringify(updatedOption))
    setSelectedOption(updatedOption);
    console.log(updatedOption);
    dispatch(fetchInstructorsFilter(updatedOption));
    navigate(Routers.Manual);
  };

  const handleChange = (option) => {
    console.log(option);
    setSelectedOption({
      ...selectedOption,
      city_id: option.value,
    });
  };

  const vechtypehandler = (value) => {
    if (value === "automatic") {
      setVtchecked(false);
    } else {
      setVtchecked(true);
    }
  };

  const handleRadioChange = (event) => {
    setVtchecked(!vtchecked);
    setSelectedOption({
      ...selectedOption,
      transmission_type: event.target.value,
    });
  };

  const loadOptions = async (inputValue, loadedOptions, { page }) => {
    setInputValue(inputValue || ""); // Set inputValue to empty string if falsy

    try {
      const response = await axios.get(
        "http://167.71.238.150/keyzi-admin/api/get-postcodes",
        {
          params: {
            page,
            term: inputValue,
          },
        }
      );

      const data = response?.data?.data?.results;
      const formattedOptions = data.map((item) => ({
        value: item.id,
        label: item.text,
      }));
      console.log(formattedOptions);
      return {
        options: formattedOptions,
        hasMore: Boolean(data && data.length > 0),
        additional: {
          page: page + 1,
        },
      };
    } catch (error) {
      console.error("Error fetching options:", error.message);
      throw error;
    }
  };

  // ! INPUT SELECTOR ! This component is form input selector in which the user can find instructors nearly automatically or manually
  return (
    <>
      {containsCorporateSiteone || containsCorporateSitetwo ? (
        <form
          className="me-auto input_selector_filter"
          onSubmit={instructorSearchHandler}
        >
          <div className={`input-group input-search ${props.maxWidth}`}>
            <div className="border_inp_btn">
              <div className="d-flex justify-content-center search_instructor--wrapper align-items-center">
                <label
                  className={`btn border-0 ${!vtchecked && "auto_select"}`}
                  htmlFor="custom-switch"
                >
                  <img src={automatic} alt="" className="img-fluid auto-icon" />{" "}
                  <span className="ms-2 vehicle_type">Automatic</span>
                </label>
                {containsCorporateSitetwo ? <Form.Check
                  type="switch"
                  id="custom-switch"
                  className="cs-sitetwo"
                  checked={vtchecked}
                  onChange={handleRadioChange}
                  value={vtchecked ? "automatic" : "manual"}
                /> : <Form.Check
                type="switch"
                id="custom-switch"
                checked={vtchecked}
                onChange={handleRadioChange}
                value={vtchecked ? "automatic" : "manual"}
              />}
                <label
                  className={`btn rounded-3 border-0 ${
                    vtchecked && "manual_select"
                  }`}
                  htmlFor="custom-switch"
                >
                  <span className="vehicle_type">Manual</span>
                  <img
                    src={manual}
                    alt=""
                    className="img-fluid manual-icon ms-2"
                  />{" "}
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center p-lg-0 p-1 input_select_wrapper">
              <span className="search_icon--wrapper">
                {containsCorporateSitetwo ? <img src={searchiblack} alt="search_icon" /> : <img src={searchi} alt="search_icon" />}
              </span>
              <div className="filter_search_input_select_style">
                  {/* <Select
                    value={
                      response?.length > 0
                        ? response.find(
                            (opt) => opt.value === selectedOption?.pin_code
                          )
                        : null
                    }
                    options={response}
                    onChange={handleChange}
                    placeholder="Pop in postcode or suburb"
                    className="selector_li p-0"
                  /> */}
                <AsyncPaginate
                onChange={handleChange}
                placeholder="Enter post code or suburb*"
                className="selector_li p-0"
                isSearchable
                defaultOptions={[]} // Set defaultOptions to an empty array
                loadOptions={async (inputValue, loadedOptions, { page }) => {
                  setInputValue(inputValue); // Set inputValue here
                  console.log(inputValue);
                  return inputValue
                    ? loadOptions(inputValue, loadedOptions, { page })
                    : Promise.resolve({ options: [] });
                }}
                debounceTimeout={300}
                noOptionsMessage={() =>
                  inputValue.trim() === ""
                    ? "Please Enter a Suburb or Postcode"
                    : "No Record Found"
                }
                additional={{
                  page: currentPage,
                }}
              />
              </div>
            </div>
            <div className="input_filter_button_wrapper">
              <Button className="btn_search br-10 " type="submit">
                Instructor Search
              </Button>
            </div>
            <FilterPopup
              show={modalShow}
              onHide={() => setModalShow(false)}
              onSearch={handleSearch}
            />
          </div>
        </form>
      ) : (
        <form
          className="me-auto input_selector_filter"
          onSubmit={instructorSearchHandler}
        >
          <div
            className={`input-group mt-3 justify-content-evenly input-search ${props.maxWidth}`}
          >
            <div className="border_inp_btn">
              <div className="d-flex justify-content-center search_instructor--wrapper align-items-center">
                <label
                  className={`btn border-0 ${!vtchecked && "auto_select"}`}
                  htmlFor="custom-switch"
                >
                  <img src={automatic} alt="" className="img-fluid auto-icon" />{" "}
                  <span className="ms-2 vehicle_type">Automatic</span>
                </label>
                <Form.Check
                  type="switch"
                  id="custom-switch"
                  checked={vtchecked}
                  onChange={handleRadioChange}
                  value={vtchecked ? "automatic" : "manual"}
                />
                <label
                  className={`btn rounded-3 border-0 ${
                    vtchecked && "manual_select"
                  }`}
                  htmlFor="custom-switch"
                >
                  <img src={manual} alt="" className="img-fluid manual-icon" />{" "}
                  <span className="ms-2 vehicle_type">Manual</span>
                </label>
              </div>
            </div>
            <div className="d-flex align-items-center p-lg-0 p-1 input_select_wrapper">
              <span className="search_icon--wrapper">
                <img src={searchi} alt="search_icon" />
              </span>
              <div className="filter_search_input_select_style">
                  {/* <Select
                    value={
                      response?.length > 0
                        ? response.find(
                            (opt) => opt.value === selectedOption?.pin_code
                          )
                        : null
                    }
                    options={response}
                    onChange={handleChange}
                    placeholder={
                      containsSite2
                        ? "Pop in postcode or suburb"
                        : "Pop in postcode or suburb"
                        // : "Enter your post code or suburb"
                    }
                    className="selector_li p-0"
                  /> */}
                  <AsyncPaginate
                onChange={handleChange}
                placeholder="Enter post code or suburb*"
                className="selector_li p-0"
                isSearchable
                defaultOptions={[]} // Set defaultOptions to an empty array
                loadOptions={async (inputValue, loadedOptions, { page }) => {
                  setInputValue(inputValue); // Set inputValue here
                  console.log(inputValue);
                  return inputValue
                    ? loadOptions(inputValue, loadedOptions, { page })
                    : Promise.resolve({ options: [] });
                }}
                debounceTimeout={300}
                noOptionsMessage={() =>
                  inputValue.trim() === ""
                    ? "Please Enter a Suburb or Postcode"
                    : "No Record Found"
                }
                additional={{
                  page: currentPage,
                }}
              />
              </div>
            </div>
            <div className="input_filter_button_wrapper">
              <Button className="btn_search br-10 " type="submit">
              {props?.label ? props?.label : containsSite2 ? "Instructor Search" : "Find My Instructor"}
              </Button>
            </div>
            <FilterPopup
              show={modalShow}
              onHide={() => setModalShow(false)}
              onSearch={handleSearch}
            />
          </div>
        </form>
      )}
    </>
  );
}

export default InputSelector;

