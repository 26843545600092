import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Modal, Button } from "react-bootstrap";
import { Steps } from "antd";
import { ConfirmDetails } from "./confirmdetails/ConfirmDetails";
import { PaymentDetails } from "./paymentdetails/PaymentDetails";
import { CheckAvailability } from "./checkavailability/CheckAvailability";
import { Routes, useNavigate } from "react-router-dom";
import { Routers } from "../../routes/Constant";
import { LeftArrow } from "../../assets/icons/LeftArrow";
import { SelectPackBox } from "./selectpackbox/SelectPackBox";
import { Route } from "react-router-dom";
import { useNavigation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useEffect, useRef } from "react";
import CalenderSlice, { getData } from "../../redux/slice/CalenderSlice";
import { InstructorBookingById } from "../../redux/slice/authSlice";
import { objectFiledDataStart } from "../../redux/slice/objectSlice";
import tick from "../../assets/images/Tick.png";
import "./Progress.css";
import Spinner from "react-bootstrap/Spinner";

export const Progress = () => {
  const dispatch = useDispatch();
  const [calender, setCalender] = useState([]);
  const [current, setCurrent] = useState(0);
  const { instructorsdetails } = useSelector((state) => state?.instructors);
  let learner_id = useSelector((state) => state?.auth?.user?.data?.data?.id);
  const { user } = useSelector((state) => state?.auth);
  const { instructorBooking } = useSelector((state) => state?.auth);
  const packageDataById =
    instructorsdetails?.length > 0 ? instructorsdetails[0]?.data?.id : "";
  const instructorName =
    instructorsdetails?.length > 0 ? instructorsdetails[0]?.data?.name : "";
  const [timeDurationPackage, setTimeDurationPackage] = useState("");
  const [lessonCount,setLessonCount] = useState("");
  const [tokenGet, setTokenGet] = useState("");
  const [bookDone, setBookDone] = useState(false);
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [isPayment, setIsPayment] = useState(false);
  // const yourObjectData = useSelector((state) => state?.objectReducer?.yourObject);
  const [isSubmitting, setIsSubmitting] = useState(false);
  useEffect(() => {
    if (instructorBooking) {
      setMessage(instructorBooking?.message);
    }
  }, [instructorBooking]);

  const storedData = JSON.parse(localStorage.getItem("bookingData"));

  const [bookingData, setBookingData] = useState(
    storedData || {
      instructor_id: packageDataById,
      package_id: "",
      learner_id: learner_id,
      start_date: "",
      pickup_location: "",
      city: "",
      postal_code: "",
      end_date: "",
      packageDuration: "",
      payment_type: "",
      name_instructor: instructorName,
    }
  );

  const updateFieldData = (field, value) => {
    setBookingData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const tokenGetter = (value) => {
    if (value) {
      bookingData["token"] = value;
    }
  };

  useEffect(() => {
    const storedCount = parseInt(window.localStorage.getItem("count"));
    setCurrent(storedCount || 0);
  }, []);

  useEffect(() => {
    localStorage.setItem("bookingData", JSON.stringify(bookingData));
  }, [bookingData]);

  useEffect(() => {
    window.localStorage.setItem("count", current);
    const jsonString = JSON.stringify(bookingData);
    window.localStorage.setItem("bookingData", jsonString);
  }, [current]);

  const SelectPacknext = () => {
    setCurrent(current + 1);
    console.log(bookingData);

    setTimeDurationPackage(bookingData.packageDuration);
  };

  // const isFirstRender = useRef(true);

  const FinalPayment = () => {
    setIsPayment(true);

    if (isSubmitting) {
      return;
    }
    setIsSubmitting(true);

    try {
      tokenGetter();
      console.log(bookingData);
      dispatch(InstructorBookingById(bookingData));
      setBookDone(true);
      localStorage.setItem("count", "0");
      localStorage.removeItem("bookingData");
      setBookingData({
        instructor_id: packageDataById,
        package_id: "",
        learner_id: learner_id,
        start_date: "",
        pickup_location: "",
        city: "",
        postal_code: "",
        end_date: "",
        packageDuration: "",
        payment_type: "",
        name_instructor: instructorName,
      });
      setCurrent(0);
      handleShowModal();
      if (message) {
        setMessage(false);
      }
    } catch (error) {
    } finally {
      setIsSubmitting(false);
    }
  };



  const prev = () => {
    setCurrent(current - 1);
  };

  const steps = [
    {
      title: "Please select lesson pack",
      content: (
        <SelectPackBox
          SelectPacknext={SelectPacknext}
          updateFieldData={updateFieldData}
          bookingData={bookingData}
        />
      ),
    },
    {
      title: "Check Availability",
      content: (
        <CheckAvailability
          SelectPacknext={SelectPacknext}
          updateFieldData={updateFieldData}
          timeDurationPackage={timeDurationPackage}
          bookingData={bookingData}
        />
      ),
    },
    {
      title: "Confirm Details",
      content: (
        <ConfirmDetails
          SelectPacknext={SelectPacknext}
          updateFieldData={updateFieldData}
          bookingData={bookingData}
        />
      ),
    },
    {
      title: "Payment Details",
      content: (
        <PaymentDetails
          SelectPacknext={FinalPayment}
          tokenGetter={tokenGetter}
          bookingData={bookingData}
          updateFieldData={updateFieldData}
        />
      ),
    },
  ];

  const items = steps.map((item) => ({
    key: <div>{item.title}</div>,
    title: <div>{item.title}</div>,
  }));

  const [showModal, setShowModal] = useState(false); // State to control the modal visibility

  // Function to show the modal
  const handleShowModal = () => {
    setShowModal(true);
  };

  // Function to hide the modal
  const handleCloseModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <section className="progress_bar_sec">
        <div className="progress_handle bg-grey">
          <div className=" progress_sec d-flex">
            <div
              className={`back_icon d-flex align-items-center justify-content-between  ${
                current === 0 ? "col-0" : "col-lg-2"
              }`}
            >
              <div>
                {current >= 1 && (
                  <Link
                    onClick={() => prev()}
                    className="back_sec text-light-black d-flex align-items-center"
                  >
                    <LeftArrow />
                    Back
                  </Link>
                )}
              </div>
            </div>
            <div
              className={`stepper ${current === 0 ? "col-12" : "col-lg-10"}`}
            >
              <Steps
                current={current}
                items={items}
                labelPlacement="vertical"
                responsive={false}
              />
            </div>
          </div>
        </div>

        <div>
          {isPayment ? (
            <div className="progress_spinner_wrapper spinner-container d-flex justify-content-center flex-column">
              <Spinner animation="border" role="status">
                <span className="visually-hidden">Loading...</span>
              </Spinner>
              <h6 className="text-indigo mt-3 text-center">
                Please wait while we process your request. This may take a few
                moments. Do not refresh the page or close your browser.
              </h6>
            </div>
          ) : (
            <div>{steps[current]?.content}</div>
          )}
        </div>
      </section>
      {instructorBooking && bookDone && (
        <section className="payment_done_section">
          <Modal
            className="payment_done_Model"
            show={showModal}
            onHide={handleCloseModal}
            centered
            backdrop="static"
            keyboard={false}
          >
            <Modal.Body className="text-center pt-5 pb-5">
              <img src={tick} alt="Booked" height="150px" width="150px" />
              <p className=" booking_msg mt-3">{message}</p>
              <Link
                className="text-indigo bookPyament_btn_dashboard"
                to={`/auth/dashboard/booking/bookingDetails/${instructorBooking?.booking_id}`}
              >
                Go To Booking Detail
              </Link>
            </Modal.Body>
          </Modal>
        </section>
      )}
    </>
  );
};
