import React, { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { ListGroup, Offcanvas } from 'react-bootstrap';
import { RightArrow } from './../../../assets/icons/RightArrow';
import { logoutLearner } from '../../../redux/slice/authSlice';
import { resetAllState } from '../../../redux/slice/rootSlice';
import { Routers } from '../../../routes/Constant';
import './DashboardLeftSide.css';
import { message } from 'antd';

export const DashboardLeftSide = () => {
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const logoutHandler = () => {
    dispatch(logoutLearner());
    dispatch(resetAllState());
    localStorage.clear();
    localStorage.removeItem('persist:root');
    console.log("logoutHandler");
    message.success({
      content: "You Logout Successfully",
      duration: 2,
    });
  };
  

  return (
    <>
      <div className="resp_side_bar">
          <h5 onClick={handleShow} className="dash_menu">Dashboard Menu <span className="ps-2">
            <RightArrow />
          </span></h5>
        <Offcanvas show={show} onHide={handleClose}>
          <Offcanvas.Header closeButton></Offcanvas.Header>
          <Offcanvas.Body>
            <section className="">
              <div className="dashboard_list para_text">
                <ListGroup variant="flush">
                <ListGroup.Item>
                    <Link
                      to={Routers.Home}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Home
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Home
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.Dashboard}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Dashboard
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Dashboard
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.Profile}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Profile
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Profile
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.ChangePassword}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.ChangePassword
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Change Password
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.Booking}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Booking
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Bookings
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.Transaction}
                  onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Transaction
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Transaction History
                    </Link>
                  </ListGroup.Item>
                  <ListGroup.Item>
                    <Link
                      to={Routers.Wallet}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Wallet
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Wallet
                    </Link>
                  </ListGroup.Item>

                  <ListGroup.Item>
                    <Link
                      onClick={logoutHandler}
                      to={Routers.Login}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Logout
                          ?'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Logout
                    </Link>
                  </ListGroup.Item>
                </ListGroup>
              </div>
            </section>
          </Offcanvas.Body>
        </Offcanvas>
      </div>

      <section className="dashboard_leftside_wrapper">
        <div className="dashboard_list para_text">
          <ListGroup variant="flush">
          <ListGroup.Item>
                    <Link
                      to={Routers.Home}
                      onClick={handleClose}
                      className={`dashBoard_list_name ${
                        pathname === Routers.Home
                          ? 'dashBoard_focus'
                          : 'dashBoard_list_name'
                      }`}
                    >
                      Home
                    </Link>
                  </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to={Routers.Dashboard}
                className={`dashBoard_list_name ${
                  pathname === Routers.Dashboard
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Dashboard
              </Link>
            </ListGroup.Item>

            <ListGroup.Item>
              <Link
                to={Routers.Profile}
                className={`dashBoard_list_name ${
                  pathname === Routers.Profile
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Profile
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to={Routers.ChangePassword}
                className={`dashBoard_list_name ${
                  pathname === Routers.ChangePassword
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Change Password
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to={Routers.Booking}
                className={`dashBoard_list_name ${
                  pathname === Routers.Booking
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Bookings
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to={Routers.Transaction}
                className={`dashBoard_list_name ${
                  pathname === Routers.Transaction
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Transaction History
              </Link>
            </ListGroup.Item>
            <ListGroup.Item>
              <Link
                to={Routers.Wallet}
                className={`dashBoard_list_name ${
                  pathname === Routers.Wallet
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Wallet
              </Link>
            </ListGroup.Item>

            <ListGroup.Item>
              <Link
                to={Routers.Home}
                onClick={logoutHandler}
                className={`dashBoard_list_name ${
                  pathname === Routers.Home
                    ? 'dashBoard_focus'
                    : 'dashBoard_list_name'
                }`}
              >
                Logout
              </Link>
            </ListGroup.Item>
          </ListGroup>
        </div>
      </section>
    </>
  );
};


