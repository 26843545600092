export const Routers= {
  PageNotFound:"*",
  Home:"/",
  site2:"/site2",
  CorporateSiteOne:"/corporatesiteone",
  CorporateSiteTwo:"/corporatesitetwo",
  LandingPage:"/referalCode/:referralId",
  WhyKeyzi:"/whykeyzi",
  OurInstructor:"/instructor",
  School:"/schoolDetail",
  SignUpLearner:"/signUpLearner",
  SignUpInstructor:"/signUpInstructor",
  Contact:"/contact",
  Manual:"/driving_instructors",
  ManualCity:"/driving_instructors/:state/:city",
  ManualState:"/driving_instructors/:state",
  MoreInfo:"/moreInfo/:id",
  Login:"/login",
  ForgotPasword:"/login/forgotPassword",
  ResetPassoword:"/login/resetPassword",
  Progress:"/auth/progress",
  JoinKeyzi:"/joinkeyzi",
  Dashboard:"/auth/dashboard",
  Profile:"/auth/dashboard/profile",
  ChangePassword:"/auth/dashboard/password",
  Wallet:"/auth/dashboard/wallet",
  Booking:"/auth/dashboard/booking",
  bookingDetails:"/auth/dashboard/booking/bookingDetails/:id",
  updateBooking:"/auth/dashboard/booking/bookingDetails/updateBooking/:id",
  feedbacklearner:"/auth/dashboard/booking/bookingDetails/rating/:id",
  Transaction:"/auth/dashboard/transaction",
  // findanInstructor:"/findanInstructor/:state",
  // findanInstructor:"/findanInstructor",
  conquer:"/conquer",
  unlock:"/unlock",
  embark:"/embark",
  guide:"/guide",
  rediscover:"/rediscover",
  license:"/license",
  navigating:"/navigating",
  }