import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import {Button} from 'react-bootstrap'
import { Link } from 'react-router-dom'
import featuredone from "../../assets/images/featured-one.jpg"
import featuredtwo from "../../assets/images/featured-two.jpg"
import "./FeaturedCsOne.css"
export const FeaturedCsOne = () => {
  return (
    <section className="featuredcsone--wrapper">
      <Container>
          <div className='featuredcsone--inner'>
              <div className="featuredcsone--head">
              <h2 className='sub_head text-start'>Featured Blogs</h2>
              <Button>View All</Button>
              </div>
              <Row>
                <Col md={6} lg={4}>
                  <div className="featuredcsone--card">
                      <div className="card-img">
                        <img src={featuredone} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>28 Reasons Why You Might NOT Pass Your Driving Test</h5>
                        <Link><span className='arrow_icon'></span>Read More</Link>
                      </div>
                  </div>
                </Col>
                <Col md={6} lg={4}>
                  <div className="featuredcsone--card">
                      <div className="card-img">
                        <img src={featuredtwo} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>11 Tips for Choosing a Good Driving Instructor</h5>
                        <Link><span className='arrow_icon'></span>Read More</Link>
                      </div>
                  </div>
                </Col>
              </Row>
          </div>
      </Container>
    </section>
  )
}
