import React from 'react'

export const EyeClosed = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_92_1231)">
<path d="M19.9436 9.70947C19.9186 9.64697 19.2101 8.17822 17.6431 6.70166C15.5511 4.74854 12.9089 3.70947 10 3.70947C7.09111 3.70947 4.44894 4.74854 2.35688 6.70166C0.789911 8.17822 0.0814421 9.64697 0.0564374 9.70947C0.0192244 9.7882 0 9.87336 0 9.95947C0 10.0456 0.0192244 10.1307 0.0564374 10.2095C0.0814421 10.272 0.789911 11.7407 2.35688 13.2173C4.44894 15.1704 7.09111 16.2095 10 16.2095C12.9089 16.2095 15.5511 15.1704 17.6431 13.2173C19.2101 11.7407 19.9186 10.272 19.9436 10.2095C19.9808 10.1307 20 10.0456 20 9.95947C20 9.87336 19.9808 9.7882 19.9436 9.70947ZM10 14.9595C7.43284 14.9595 5.19075 14.0845 3.3404 12.3579C2.57569 11.652 1.92685 10.8437 1.41503 9.95947C1.92616 9.07245 2.57503 8.26152 3.3404 7.55322C5.19075 5.83447 7.43284 4.95947 10 4.95947C12.5672 4.95947 14.8092 5.83447 16.6596 7.55322C17.425 8.26152 18.0738 9.07245 18.585 9.95947C17.9849 11.0142 15.3677 14.9595 10 14.9595ZM10 6.20947C9.20872 6.20947 8.43522 6.42941 7.77729 6.84146C7.11937 7.25352 6.60659 7.83919 6.30378 8.52441C6.00097 9.20963 5.92174 9.96363 6.07611 10.6911C6.23048 11.4185 6.61152 12.0867 7.17103 12.6111C7.73055 13.1356 8.44342 13.4927 9.21949 13.6374C9.99556 13.7821 10.8 13.7078 11.531 13.424C12.2621 13.1402 12.8869 12.6595 13.3265 12.0429C13.7661 11.4262 14.0008 10.7012 14.0008 9.95947C14.0008 8.96491 13.5793 8.01108 12.829 7.30782C12.0787 6.60456 11.0611 6.20947 10 6.20947ZM10 12.4595C9.47248 12.4595 8.95681 12.3129 8.5182 12.0381C8.07958 11.7634 7.73772 11.373 7.53585 10.9162C7.33398 10.4594 7.28116 9.9567 7.38407 9.47175C7.48699 8.98679 7.74101 8.54134 8.11402 8.19171C8.48703 7.84207 8.96228 7.60397 9.47966 7.50751C9.99704 7.41105 10.5333 7.46055 11.0207 7.64977C11.508 7.83899 11.9246 8.15942 12.2177 8.57055C12.5107 8.98167 12.6672 9.46502 12.6672 9.95947C12.665 10.6219 12.3833 11.2566 11.8835 11.725C11.3838 12.1934 10.7067 12.4574 10 12.4595Z" fill="#999999"/>
</g>
<path d="M2.95972 3L16.9597 17" stroke="#999999" stroke-width="1.3" stroke-linecap="round"/>
<defs>
<clipPath id="clip0_92_1231">
<rect width="20" height="12.5" fill="white" transform="translate(0 3.70947)"/>
</clipPath>
</defs>
</svg>
  )
}


