import React from 'react';
import { Container } from 'react-bootstrap';
import carimg from '../../assets/images/carimg.png';
import './Bannermain.css';
export const Bannermain = ({ children, bgconfirm }) => {
  return (
    <>
      {/* ! BANNER COMPONENT ! This component is used to wrap other child component to show same backgorund  */}
      <section
        className={`main_section container-bg position-relative pb-2 ${bgconfirm}`}
      >
        <Container>
          {children}
          <div className="car_img_sec">
            <img src={carimg} alt="car_image" className="car_img" />
          </div>
        </Container>
      </section>
    </>
  );
};
