import React from 'react'
import { MainHome } from '../mainhome/MainHome'
import { BannerMain } from '../banner_main/BannerMain'
import  SecondSearch  from '../secondSearch/SecondSearch'

export const FindInstructor = () => {
  return (  
    <>
        <BannerMain/>
        <SecondSearch/>
    </>
  )
}
