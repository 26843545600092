// SelectPackBox.js
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./SelectPackBox.css";
import { objectFiledDataStart } from "../../../redux/slice/objectSlice";
import { PackageSubSerivceList } from "../../../redux/slice/authSlice";

export const SelectPackBox = ({ SelectPacknext, updateFieldData,bookingData }) => {
  const dispatch = useDispatch();
  const { instructorsdetails } = useSelector((state) => state?.instructors);
  const response =
    instructorsdetails && Array.isArray(instructorsdetails)
      ? instructorsdetails[0]?.data?.package
      : undefined;
  const [packageDetails, setPackageDetails] = useState([]);
  const [packSelect, setPackSelect] = useState(false);
  const [error, setError] = useState(false);
  const [selectedPackageIndex, setSelectedPackageIndex] = useState(-1);
  const {subServiceListData} = useSelector((state) => state?.auth);
  useEffect(() => {
    if (instructorsdetails) {
      setPackageDetails(response);
      
      if (bookingData?.package_id) {
        const selectedIndex = response.findIndex(
          (item) => item.package_id === bookingData?.package_id
        );
        setSelectedPackageIndex(selectedIndex);
      }
    }
  }, [instructorsdetails, bookingData?.package_id]);

  


  const handleCheckboxChange = (event, packageId, amount, time, index,service_id,lesson_count) => {
    console.log()
    if (event.target.checked) {
      setSelectedPackageIndex(index);
      updateFieldData("package_id", packageId);
      updateFieldData("packageDuration", time);
      updateFieldData("amount", amount);
      updateFieldData("lesson_count",lesson_count)
      dispatch(PackageSubSerivceList({
        instructor_id:instructorsdetails[0]?.data?.id,
        service_id:service_id
      }));
      setError(false);
    } else {
      setSelectedPackageIndex(-1); 
    }
  };

  const SelectPackButtonHandler = () => {
    if (selectedPackageIndex !== -1) {
      SelectPacknext();
    } else {
      setError(true);
    }
  };
 console.log(packageDetails);
  return (
    <>
      <section className="select_box_container m-bot">
        <Container>
          <Row>
            <Col>
              <div className="d-flex justify-content-center ">
                <div className="select_box  br-10 mt-5">
                  <div className="select_box_head text-center ">
                    <p className="progress_head d-flex justify-content-center m-auto">PLEASE SELECT LESSON PACK</p>
                    {/* <p className="lesson_head_inner mt-3">
                      How many lessons do I need?
                    </p> */}
                  </div>
                  {packageDetails?.map((e, index) => {
                    return (
                      <div
                        className="select_box_middle d-flex justify-content-between m-4 mb-0 p-3"
                        key={index}
                      >
                        <div className="main_test_pack">
                          <p>
                            <input
                              id={index}
                              type="radio"
                              name="setpackage"
                              className="form-check-input"
                              onChange={(event) =>
                                {
                                  handleCheckboxChange(event, e.package_id, e.amount, e.time, index,e.service_id,e.lesson_count)
                                }
                              }
                              checked={index === selectedPackageIndex}
                            />
                            <label htmlFor={index}><span className="progress_sub_head">{e?.package_name},{e.lesson_count} Lessons</span></label>
                          </p>
                        </div>
                        <div className="progress_sub_head">
                          <p>${e?.amount}</p>
                        </div>
                      </div>
                    );
                  })}
                  <div className="alert_wrap">
                    {error && (
                      <span className="text-danger">
                        Please select lesson pack
                      </span>
                    )}
                  </div>
                  <div className="button_wrapper text-center">
                    <Button
                      variant="primary"
                      className="orange_btn text_manual br-10 select_lesson_btn ms-5 me-5 mb-2"
                      onClick={SelectPackButtonHandler}
                    >
                      Select Lesson Pack
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
