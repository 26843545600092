import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import drivingInstructor from "../../assets/images/driving-instructor-work.jpg"
import drivingLessons from "../../assets/images/driving-lessone-work.png"
import learnDriving from "../../assets/images/learn-to-drive-work.jpg"
import "./WorkCsOne.css"
export const WorkCsOne = () => {
  return (
    <section className="workcsone--wrapper">
      <Container>
          <div className='workcsone--inner'>
              <h2 className='sub_head'>How Keyzi works</h2>
              <p className='para_text'>Simple & Flexible booking system</p>
              <Row>
                <Col lg={4} md={6}>
                  <div className="workcsone--card">
                      <div className="card-img">
                        <img src={drivingInstructor} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <div>
                        <h5>Find Your Driving Instructors</h5>
                        <p>Choose from a wide variety of instructors in your area. Check rating & reviews from real learners.</p>
                        </div>
                        <Link><span className='arrow_icon'></span> Start Learning To Drive Now</Link>
                      </div>
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="workcsone--card">
                      <div className="card-img">
                        <img src={drivingLessons} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <div>
                        <h5>Book Your Driving Lessons</h5>
                        <p>Book online with instant confirmation. Easily manage your lesson schedule via our online dashboard.</p>
                        </div>
                        <Link><span className='arrow_icon'></span> Start Learning To Drive Now</Link>
                      </div>
                  </div>
                </Col>
                <Col lg={4} md={6}>
                  <div className="workcsone--card mb-0">
                      <div className="card-img">
                        <img src={learnDriving} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <div>
                        <h5>Learn To Drive</h5>
                        <p>Your instructor picks you up from your chosen address and you're on your way</p>
                        </div>
                        <Link><span className='arrow_icon'></span> Start Learning To Drive Now</Link>
                      </div>
                  </div>
                </Col>
              </Row>
          </div>
      </Container>
    </section>
  )
}
