
import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Button, Col, Row } from "react-bootstrap";
import { Editpic } from "../../../assets/icons/Editpic";
import {
  learnerDashboardProfileUpdateById,
  learnerDashboardProfileInfoById,
} from "../../../redux/slice/authSlice";
import "./Profile.css";
import Spinner from "react-bootstrap/Spinner";
import * as yup from "yup";
import { message } from "antd";

export const Profile = () => {
  const [isSaving, setIsSaving] = useState(false);
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state?.auth);
  const response = user?.data?.data;
  const { learnerDashboardProfileInfo } = useSelector((state) => state.auth);
  console.log(learnerDashboardProfileInfo)
  const fileInputRef = useRef();
  const [selectedFile, setSelectedFile] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [learnerProfileInfo, setLearnerProfileInfo] = useState({
    user_id: "",
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    default_pickup_address: "",
    post_code: "",
    profile_image: "",
    suburb:"",
    street:"",
    street_number:"",
    state:""
    
  });

  useEffect(() => {
    const fetchLearnerProfileInfo = async () => {
      try {
        await dispatch(learnerDashboardProfileInfoById(response.id));
        const datagetUser = async () => {
          const { data } = (await learnerDashboardProfileInfo) || {};
          if (data) {
            const nameParts = data.name.split(" ");
            setLearnerProfileInfo({
              user_id: data.id,
              firstname: nameParts[0],
              lastname: nameParts[1] || "",
              email: data.email,
              phone: data.phone,
              street_number:data.street_number,
              default_pickup_address: data.address,
              post_code: data.post_code,
              profile_image: data.profile_image,
              suburb: data.suburb,
              city: data.city,
              post_code: data.post_code,
              street: data.street,
              state:data.state,
            });
          }
        };
        datagetUser();
      } catch (error) {
        console.log(error);
      }
    };
    if (response) {
      fetchLearnerProfileInfo();
    }
  }, [dispatch]);

  const validationSchema = yup.object().shape({
    firstname: yup.string().required("First Name is required"),
    lastname: yup.string().required("Last Name is required"),
    email: yup.string().email().required("Email is required"),
    phone: yup.string().required("Phone is required"),
    default_pickup_address: yup.string().required("Default Address is required"),
    post_code: yup.string().required("Postal Code is required"),
    suburb:yup.string().required("Suburb is required"),
    street:yup.string().required("Street Address is required"),
    street_number:yup.string().required("Street No is required"),
    state:yup.string().required("State is required"),
  });

  const [errors, setErrors] = useState({});

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    const reader = new FileReader();
    reader.onloadend = () => {
      setPreviewImage(reader.result);
    };
    reader.readAsDataURL(file);

    setLearnerProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      profile_image: file,
    }));
  };

  const handleEditPicClick = () => {
    fileInputRef.current.click();
  };

  const handleDeleteImage = () => {
    setLearnerProfileInfo((prevProfileInfo) => ({
      ...prevProfileInfo,
      profile_image: "",
    }));
    setPreviewImage(null);
    setSelectedFile(null);
  };

  const handleSaveProfile = async () => {
    setIsSaving(true);
  
    try {
      await validationSchema.validate(learnerProfileInfo, {
        abortEarly: false,
      });
      setErrors({});
  
      let formData = new FormData();
      formData.append("user_id", learnerProfileInfo.user_id);
      formData.append("firstname", learnerProfileInfo.firstname);
      formData.append("lastname", learnerProfileInfo.lastname);
      formData.append("email", learnerProfileInfo.email);
      formData.append("phone", learnerProfileInfo.phone);
      formData.append("default_pickup_address", learnerProfileInfo.default_pickup_address);
      formData.append("post_code", learnerProfileInfo.post_code);
      formData.append("profile_image", learnerProfileInfo.profile_image);
      formData.append("suburb", learnerProfileInfo.suburb);
      formData.append("street", learnerProfileInfo.street);
      formData.append("street_number", learnerProfileInfo.street_number);
      formData.append("state", learnerProfileInfo.state);
  
      await dispatch(learnerDashboardProfileUpdateById(formData));
  
      // Display success message
      console.log('success');
      message.success({
        content: "Your Profile Updated Successfully",
        duration: 3,
      });
    } catch (error) {
      const newErrors = {};
      error.inner.forEach((validationError) => {
        newErrors[validationError.path] = validationError.message;
      });
      setErrors(newErrors);
  
      // Display error message
      console.log('error');
      message.error({
        content: "Some Error Occurred in Profile Update",
        duration: 3,
      });
    } finally {
      setTimeout(() => {
        dispatch(learnerDashboardProfileInfoById(response.id));
        setIsSaving(false);
      }, 1000);
    }
  };
  

  return (
    <>
      {isSaving ? (
        <div className="spinner-container d-flex justify-content-center">
          <Spinner animation="border" role="status">
            <span className="visually-hidden">Loading...</span>
          </Spinner>
        </div>
      ) : (
        <section className="profile_section">
          <Row className="bg-white profile_in_sec br-10 p-3 p-md-4 pe-md-2 ps-md-2">
            <Col
              md={3}
              className="p-xl-5 pt-1 text-center div_border-r profile_in_sec_ab"
            >
              <div className="position-relative image_wrapper_profile">
                {previewImage || learnerProfileInfo.profile_image ? (
                  <img
                    src={
                      previewImage
                        ? previewImage
                        : `http://167.71.238.150/keyzi-admin/public${learnerProfileInfo.profile_image}`
                    }
                    alt="profile_pic"
                    className="profile_pic"
                  />
                ) : (
                  <div className="profile_pic bg-grey d-flex align-items-center justify-content-center">
                    No image
                  </div>
                )}
                <div className="picedit" onClick={handleEditPicClick}>
                  <Editpic />
                </div>
              </div>
              <input
                type="file"
                ref={fileInputRef}
                className="d-none"
                onChange={handleImageUpload}
              />
              {(previewImage || learnerProfileInfo.profile_image) && (
                <Button className="del_btn mt-3" onClick={handleDeleteImage}>
                  Delete Photo
                </Button>
              )}
            </Col>
            <Col md={9} className="p-xl-5 pt-0 pb-0 user_profile_dashboard_sec">
              <div className="fl_name d-flex pt-3 pb-3 div_border-b">
                <div className="col-md-6 me-1">
                  <p className="head_profil_detali_user_p">First Name</p>
                  <input
                    className="profil_detali_user_p form-control me-1"
                    value={learnerProfileInfo.firstname}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        firstname: e.target.value,
                      })
                    }
                  />
                  {errors.firstname && (
                    <p className="text-danger">{errors.firstname}</p>
                  )}
                </div>
                <div className="col-md-6 ps-lg-4 lastname_sec ms-1">
                  <p className="head_profil_detali_user_p">Last Name</p>
                  <input
                    className="profil_detali_user_p form-control"
                    value={learnerProfileInfo.lastname}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        lastname: e.target.value,
                      })
                    }
                  />
                  {errors.lastname && (
                    <p className="text-danger">{errors.lastname}</p>
                  )}
                </div>
              </div>
              <div className="pe_about d-md-flex pt-3 pb-3 div_border-b">
                <div className="col-md-6">
                  <p className="head_profil_detali_user_p">Email</p>
                  <input
                    className="profil_detali_user_p form-control"
                    value={learnerProfileInfo.email}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        email: e.target.value,
                      })
                    }
                  />
                  {errors.email && (
                    <p className="text-danger">{errors.email}</p>
                  )}
                </div>
                
                <div className="col-md-6 ps-md-4 phone_sec">
                  <p className="head_profil_detali_user_p">Phone</p>
                  <input
                    className="profil_detali_user_p form-control"
                    value={learnerProfileInfo.phone}
                    maxlength="10"
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        phone: e.target.value,
                      })
                    }
                  />
                  {errors.phone && (
                    <p className="text-danger">{errors.phone}</p>
                  )}
                </div>
              </div>


              {/* new */}

              <div className="fl_name d-flex pt-3 pb-3 div_border-b">
                <div className="col-md-6 me-1">
                  <p className="head_profil_detali_user_p">Street No.</p>
                  <input
                    className="profil_detali_user_p form-control me-1"
                    value={learnerProfileInfo.street_number}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        street_number: e.target.value,
                      })
                    }
                  />
                  {errors.street_number && (
                    <p className="text-danger">{errors.street_number}</p>
                  )}
                </div>
                <div className="col-md-6 ps-md-4">
                  <p className="head_profil_detali_user_p">Street Address</p>
                  <input
                    className="profil_detali_user_p form-control"
                    value={learnerProfileInfo.street}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        street: e.target.value,
                      })
                    }
                  />
                  {errors.street && (
                    <p className="text-danger">{errors.street}</p>
                  )}
                </div>
              </div>


              <div className="pe_about d-md-flex pt-3 pb-3 div_border-b">

                <div className="col-md-6 phone_sec">
                  <p className="head_profil_detali_user_p">Suburb</p>
                  <input
                    className="profil_detali_user_p form-control"
                    value={learnerProfileInfo.suburb}
                    onChange={(e) =>
                      setLearnerProfileInfo({
                        ...learnerProfileInfo,
                        suburb: e.target.value,
                      })
                    }
                  />
                  {errors.suburb && (
                    <p className="text-danger">{errors.suburb}</p>
                  )}
                </div>
                <div className="col-md-6 ps-md-4 phone_sec">
                <p className="head_profil_detali_user_p">Postal Code</p>
                <input
                  className="profil_detali_user_p form-control"
                  value={learnerProfileInfo.post_code}
                  onChange={(e) =>
                    setLearnerProfileInfo({
                      ...learnerProfileInfo,
                      post_code: e.target.value,
                    })
                  }
                />
                {errors.post_code && (
                  <p className="text-danger">{errors.post_code}</p>
                )}
              </div>
              </div>
              <div className="add_sec pt-3 pb-3">
                <p className="head_profil_detali_user_p">State</p>
                <input
                  className="profil_detali_user_p form-control"
                value={learnerProfileInfo.state}
                  onChange={(e) =>
                    setLearnerProfileInfo({
                      ...learnerProfileInfo,
                      state: e.target.value,
                    })
                  }
                />
                {errors.state && (
                  <p className="text-danger">{errors.state}</p>
                )}
              </div>


              <div className="add_sec pt-3 pb-3">
                <p className="head_profil_detali_user_p">Default Pickup Address</p>
                <input
                  className="profil_detali_user_p form-control"
                value={learnerProfileInfo.default_pickup_address}
                  onChange={(e) =>
                    setLearnerProfileInfo({
                      ...learnerProfileInfo,
                      default_pickup_address: e.target.value,
                    })
                  }
                />
                {errors.default_pickup_address && (
                  <p className="text-danger">{errors.default_pickup_address}</p>
                )}
              </div>

              <Button
                className="bg-indigo edit_btn"
                onClick={handleSaveProfile}
              >
                Save
              </Button>
            </Col>
          </Row>
        </section>
      )}
    </>
  );
};
