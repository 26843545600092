import Container from 'react-bootstrap/Container';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import { Row, Col } from 'react-bootstrap';
import { CardContent } from './CardContent';
import { Routers } from '../../routes/Constant';
import { useNavigate } from 'react-router-dom';
import './CardDetail.css';

// {/* ! JOIN KEYZI ! This component ask user can continue this website as instructor or learner  */}

export const CardDetail = () => {
  const navigate = useNavigate();
  return (
    <section className="cardDetail_section m-top m-bot">
      <Container>
        <Row className="card_sec flex-md-row  flex-column justify-content-center">
          {CardContent.map((i,idx) => {
            return (
              <Col className={`card_box br-10 col-12 col-md-6`}  key={idx}>
                <Card
                  className={`bg-grey card_box p-0 p-lg-4 pt-2 mb-4 mt-4 h-100 ${i.title==="LEARNER"?"me-lg-4 me-none":"me-none ms-lg-4"}`}
                 
                >
                  <Card.Body className="order-last order-sm-0 p-0 p-lg-auto ">
                    <Card.Img
                      variant="top"
                      src={i.URL}
                      style={{ width: 'unset' }}
                      className="img-fluid "
                    />
                  </Card.Body>
                  <Card.Body>
                    <Card.Title className="main_head">{i.title}</Card.Title>
                    <Card.Text className="para_text">{i.textDetail}</Card.Text>

                    <Button variant="outline-primary btn_started br-10 mt-2"
                     onClick={() => {
                      navigate(i.title==="LEARNER"?Routers.SignUpLearner:Routers.SignUpInstructor);
                    }}
                    >
                      Get Started
                    </Button>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};