import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visa from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/master-card.png";
import eftops from "../../../assets/images/eftops.png";
import InputSelector from "../../inputselector/InputSelector";
const GuideBanner = () => {
  return (
    <>
      <section className="keyzi_clickAway--wrapper keyzi_guideForward--wrapper">
        <div className="click_away--banner "></div>
        <Container>
          <div className="keyzi_clickAway--inner">
            <Row>
              <Col lg={6}>
                <p>
                  As you ponder the best path forward, consider the long-term
                  benefits and reassurances that professional instruction
                  offers. In a world where road safety has never been more
                  critical, the value of a comprehensive, up-to-date education
                  cannot be overstated.{" "}
                </p>
                <p>
                  Choose the path that leads not just to a licence, but to a
                  lifetime of safe, confident driving. success.
                </p>
              </Col>
              <Col lg={6}>
                <div className="newPage--InputSelector newPage_ahead-inputSelector">
                  <h3>
                  Choose us to guide you or your teenager to driving success!
                  </h3>
                  <InputSelector label="Instructor Search" />
                  <div className="fp_weaccept">
                    <h4>We Accept</h4>
                    <img src={visa} className="img-fluid " alt="Visa" />
                    <img
                      src={mastercard}
                      className="img-fluid master_card"
                      alt="Master Card"
                    />
                    <img src={eftops} className="img-fluid " alt="Eftops" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default GuideBanner;


