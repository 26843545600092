import { Banner } from "../components/banner/Banner";
import { CardSection } from "../components/whykeyzi_card/Why_Keyzi_Card";
import { FriendSec } from "../components/friendsec/FriendSec";
import { InstrucCard } from "../components/instruccard/InstrucCard";
import { Works } from "../components/work/Works";
import { LearnerSay } from "../components/learnersay/LearnerSay";
import { MainHome } from "../components/mainhome/MainHome";
import { CardDetail } from "../components/carddetail/CardDetail";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { resetAllState } from "../redux/slice/rootSlice";
import { logoutLearner } from "../redux/slice/authSlice";
import { BannerMain } from "../components/banner_main/BannerMain";
import { DrivingPackage } from "../components/drivingPackage/DrivingPackage";
import { FeaturedBlog } from "../components/featuredBlogs/FeaturedBlog";
import { AskedQuestions } from "../components/askedquestions/AskedQuestions";
import { DrivingLessons } from "../components/drvingLessons/DrivingLessons";
import BookDriving from "../components/bookdriving/BookDriving";
import { OurLearnerSay } from "../components/OurLearnerSay/OurLearnerSay";
import HowWorks from "../components/HowWorks/HowWorks";
import SecondSearch from "../components/secondSearch/SecondSearch";
import { GIftlife } from "../components/giftlife/GIftlife";
import { Contact } from "../components/contactus/Contact";
import { GetRoad } from "../components/getroad/GetRoad";
import { LicenseJourney } from "../components/licensejourney/LicenseJourney";
import { FlexiblesPackages } from "../components/flexiblepackages/FlexiblesPackages";
import { WhyChooseUs } from "../components/whychooseus/WhyChooseUs";
import { ExpertTutions } from "../components/expertTutions/ExpertTutions";
import { FromTeens } from "../components/fromTeens/FromTeens";
import { GetQuestions } from "../components/getQuestions/GetQuestions";

export const SiteTwo = () => {
  const dispatch = useDispatch();
  // useEffect(()=>{
  //   dispatch(resetAllState());
  //   localStorage.clear();
  //   dispatch(logoutLearner());
  //   localStorage.removeItem("persist:root");
  // },[])

  useEffect(()=>{
        window.localStorage.removeItem("prevsUrl")
  },[])

  return (
    <>
    <GetRoad/>
    <LicenseJourney/>
    <FlexiblesPackages/>
    <WhyChooseUs/>
    <ExpertTutions/>
    <FromTeens/>
    <GetQuestions/>
      {/* <BannerMain /> */}
      {/* <SecondSearch />
      <HowWorks />
      <OurLearnerSay />
      <DrivingPackage />
      <BookDriving />
      <GIftlife /> 
      <DrivingLessons />
      <AskedQuestions />
      <FeaturedBlog />
      <Contact/> */}





      {/* <MainHome/> */}
      {/* <InstrucCard /> */}
      {/* <Works /> */}
      {/* <LearnerSay /> */}
      {/* <CardSection /> */}
      {/* <Banner /> */}
      {/* <CardDetail /> */}
      {/* <FriendSec /> */}
    </>
  );
};
