export const NotePad=()=>{
  return(
    <>
      
<svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M22.5 30H37.5" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M22.5 37.5H37.5" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M13.125 9.375H46.875C47.3723 9.375 47.8492 9.57254 48.2008 9.92418C48.5525 10.2758 48.75 10.7527 48.75 11.25V46.875C48.75 48.3668 48.1574 49.7976 47.1025 50.8525C46.0476 51.9074 44.6168 52.5 43.125 52.5H16.875C15.3832 52.5 13.9524 51.9074 12.8975 50.8525C11.8426 49.7976 11.25 48.3668 11.25 46.875V11.25C11.25 10.7527 11.4475 10.2758 11.7992 9.92418C12.1508 9.57254 12.6277 9.375 13.125 9.375Z" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M18.75 5.625V13.125" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M30 5.625V13.125" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M41.25 5.625V13.125" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
    </>
  )
}