import React from "react";
import mind from "../../../assets/images/newpages/mind.png";
import road from "../../../assets/images/newpages/road.png";

const EmbarkContent = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner">
          <p>
          "Do you dream of hitting the open road with the windows down and your favourite tunes playing, feeling the rush of independence that comes with driving? But are you wondering how to turn that dream into a reality? Let's embark on the journey from learner's permit to proudly displaying your P plates, and unlock the adventure of a lifetime."
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default EmbarkContent;
