import React from "react";
import road from "../../../assets/images/newpages/road.png";

const LicenseContent = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner text-center">
          <h2>Navigating the Conversion Process: A Step-by-Step Guide</h2>
          <p>
          Understanding the Need for Conversion: Holding an international driver's licence allows you the freedom to explore Australian roads for a certain period. However, your visa or residency status dictates the duration this licence remains valid and outlines if/when a conversion to an Australian licence is necessary. 
Each Australian state has its unique rules and procedures 
for this conversion, ensuring you're well-informed 
and prepared is key.
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default LicenseContent;


