import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import roleOne from "../../../assets/images/newpages/roleone.jpg";
import roleTwo from "../../../assets/images/newpages/roletwo.jpg";
const GuideRole = () => {
  return (
    <>
      <section className="keyzi_guiderole--wrapper">
        <Container>
          <div className="keyzi_guiderole--inner">
            <Row className="upperlist--guidroll">
              <Col lg={6}>
                <div className="content-side">
                  <h2>The Role of Parent-Taught Lessons</h2>
                  <ul>
                    <li>
                      <span>Bonding Experience:</span> For some, teaching their
                      child to drive is a bonding experience, filled with shared
                      moments and milestones.
                    </li>
                    <li>
                      <span>Cost Considerations:</span> At first glance,
                      parent-taught lessons can seem like a cost-saving option,
                      bypassing professional instruction fees.
                    </li>
                    <li>
                      <span>Flexibility:</span> Parents and teens might find it
                      easier to schedule driving practice around busy lives
                      without being bound to an instructor's timetable.
                    </li>
                  </ul>
                </div>
              </Col>
              <Col lg={6}>
                <div className="image-side">
                  <img src={roleOne} className="img-fluid" alt="image" />
                </div>
              </Col>
            </Row>
            <Row className="lowerlist--guidroll">
              <Col lg={6}>
                  
                <div className="image-side"> <img src={roleTwo} className="img-fluid" alt="image" /></div>
              </Col>
              <Col lg={6}>
                <div className="content-side">
                <h2>The Case of Professional Driving Instructors</h2>
                  <ul>
                    <li>
                      <span>Structured Learning Approach:</span> Professional instructors bring a curriculum designed to cover every facet of driving, from basic manoeuvres to advanced defensive driving techniques. This structured approach ensures no essential skill is left untaught.
                    </li>
                    <li>
                      <span>Up-to-Date Knowledge:</span> Road rules and testing requirements evolve. Instructors are not only up-to-date with these changes but also proficient in teaching them, ensuring you're learning the most current practices.
                    </li>
                    <li>
                      <span>Focus on Safety:</span> Equipped with dual control cars, instructors provide a safe learning environment, reducing the risk of accidents and enhancing the learner's confidence.
                    </li>
                    <li>
                      <span>Insurance and Car Preservation:</span> Using a driving school means your family car remains safe from the wear and tear of learning to drive, and there's no need to worry about insurance complications in the event of a mishap.
                    </li>
                    <li>
                      <span>Emotional Well-being:</span> Learning from a neutral, patient instructor can preserve family harmony by avoiding the stress and tension that can arise in parent-taught lessons.
                    </li>
                    <li>
                      <span>Identification and Correction of Bad Habits:</span> Instructors are trained to spot and correct bad habits before they become ingrained, something parents might inadvertently overlook or even teach.
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default GuideRole;
