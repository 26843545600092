import australia from "../../assets/images/australia.png";
import clock from "../../assets/images/clock.png";
import statistics from "../../assets/images/statistics.png"
export const CardData=[
  {
    id:1,
    img_url:australia,
    head:"Quick and Easy",
    headDetail:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },
  {
    id:2,
    img_url:clock,
    head:"Grow Your Buisness",
    headDetail:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  },  {
    id:3,
    img_url:statistics,
    head:"Australian Owned",
    headDetail:"Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua."
  }
]