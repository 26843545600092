import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import markingJourney from "../../../assets/images/newpages/marking_journey.png";
import visa from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/master-card.png";
import eftops from "../../../assets/images/eftops.png";
import InputSelector from "../../inputselector/InputSelector";
const LicenseJourney = () => {
  return (
    <>
      <section className="keyzi_clickAway--wrapper keyzi_discJourney--wrapper keyzi_licenseJourney--wrapper">
        <div className="click_away--banner keyzi_Embark--banner "></div>
        <Container>
          <div className="keyzi_clickAway--inner">
            <Row>
              <Col lg={6}>
                <h2>Embark on Your Australian Driving Journey with Confidence</h2>
                <p>
                Making the transition from an international driver's licence to an Australian one marks a significant milestone in your journey here. It's about more than just changing documents; it's about ensuring your freedom and safety on the roads of this diverse country. With our expert guidance, flexible learning options, and comprehensive support, we're here to make this transition not just successful, but a positive step towards your new life in Australia.
                </p>
              </Col>
              <Col lg={6}>
                <div className="licenseJourny-inputWrapper">
                <div className="newPage--InputSelector">
                  <h3>
                  Ready to start the journey?
                  </h3>
                  <p>Contact us today to find your driving instructor and take the first step towards converting your international licence with confidence and ease.</p>
                  <InputSelector label="Instructor Search" />
                  <div className="fp_weaccept">
                    <h4>We Accept</h4>
                    <img src={visa} className="img-fluid " alt="Visa" />
                    <img
                      src={mastercard}
                      className="img-fluid master_card"
                      alt="Master Card"
                    />
                    <img src={eftops} className="img-fluid " alt="Eftops" />
                  </div>
                </div>
                <img src={markingJourney} className="img-fluid " alt="marking" />
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default LicenseJourney;
