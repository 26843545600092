import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import { SS_Dollar } from "../../assets/icons/SS_Dollar";
import Profile from "../../assets/icons/Profile";
import "./SecondSearch.css";

const SecondSearch = () => {
  return (
    <section className="second_searc_wrapper second_search">
      <Container>
        <Row>
          <Col md={6}>
            <div className="ss_card_wraper">
              <div className="ss_icon_wraper">
                <SS_Dollar />
              </div>
              <div>
                <h1 className="text-capitalize">Driving lesson pricing</h1>
                <p>Buy more lessons & get more discount</p>
              </div>
            </div>
          </Col>
          <Col md={6}>
            <div className="ss_card_wraper">
              <div className="ss_icon_wraper">
                <Profile />
              </div>
              <div>
                <h1 className="text-capitalize">Driving lesson guarantee!</h1>
                <p>Buy more lessons & get more discount</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default SecondSearch;
