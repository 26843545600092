import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import licenseHow from "../../../assets/images/newpages/license_how.png";

const LicenseBegine = () => {
  return (
    <div className="keyzi_whyChoose--wrapper">
      <Container>
        <div className="keyzi_whyChoose--inner">
          <Row>
            <Col xl={6}>
              <div className="whyChoose--leftSide">
               <div>
               <h4>How To Begin</h4>
                <p>
                  The pathway to converting your licence is straightforward but
                  requires attention to detail.{" "}
                </p>
                <p>Here’s how you can embark on this process</p>
               </div>
                <img src={licenseHow} className="img-fluid" alt="" />
              </div>
            </Col>
            <Col xl={6}>
              <div className="whyChoose--rightSide">
                <ul>
                  <li>
                    <h5>Gather Essential Documents:</h5>
                    <p>
                       You'll need your overseas
                      licence (with a translation if it's not in English), proof
                      of Australian permanent residency, and two forms of ID as
                      per the local government requirements.
                    </p>
                  </li>
                  <li>
                    <h5>Eyesight Check:</h5>
                    <p>
                       Safety starts with seeing clearly. An
                      eyesight test confirms you meet the Australian driving
                      standards, ensuring you and others on the road are safe.
                    </p>
                  </li>
                  <li>
                    <h5> Medical Assessment (If Needed):</h5>
                    <p>
                      If you have a medical
                      condition that might affect your driving, a medical test
                      will assess your fitness to drive, ensuring everyone's
                      safety on the road.
                    </p>
                  </li>
                  <li>
                    <h5>Driver Knowledge Test:</h5>
                    <p>
                      Familiarise yourself with
                      Australian road rules with the Driver Knowledge Test
                      (DKT). This step is crucial if you're converting your
                      licence after three months of residency or if your
                      international licence has expired.
                    </p>
                  </li>
                  <li>
                    <h5>Driving Test:</h5>
                    <p>
                     Demonstrating your driving skills may be required as part of the conversion process. This ensures your driving proficiency aligns with Australian standards.
                    </p>
                  </li>
                  <li>
                    <h5>Fee Payment:</h5>
                    <p>
                     The conversion process involves administrative fees for testing and application submissions. Ensuring these are settled is the final step in the process.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default LicenseBegine;
