import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import "./FromTeens.css";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/swiper-bundle.css";
import { Navigation, Pagination } from "swiper";
import teenOne from "../../assets/images/instruc_1.png"
import {RatingStart} from "../../assets/icons/RatingStart"
export const FromTeens = () => {
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_FromTeens--wrapper">
      <Container>
        <div className={containsSite2 ? "keyzi_FromTeensSiteTwo--inner" : "keyzi_FromTeens--inner"}>
          <h2>Hear from teens who've passed with flying colours!</h2>
          <div className="swiper--fromTeens">
          <Swiper
            slidesPerView={3}
            spaceBetween={30}
            autoplay={true}
            navigation={{
              nextEl: '.custom-next',
              prevEl: '.custom-prev',
            }}
            pagination={{
              clickable: true,
            }}
            modules={[Navigation, Pagination]}
            breakpoints={{
              1024: {
                slidesPerView: 3,
              },
              768: {
                slidesPerView: 2,
              },
              640: {
                slidesPerView: 1,
              },
              0: {
                slidesPerView: 1,
              },
            }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="fromTeens--card">
                <div className="card-header">
                  <img src={teenOne} className="img-fluid" alt="" />
                  <div className="cardHeader-content">
                    <h6>Name</h6>
                   <RatingStart dynamicColor={containsSite2 ? "#FFCE00" : "#252237"}/>
                  </div>
                </div>
                <div className="card-body">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquam officia dolore distinctio magnam dolor cupiditate
                    enim doloremque quisquam nostrum.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="fromTeens--card">
                <div className="card-header">
                  <img src={teenOne} className="img-fluid" alt="" />
                  <div className="cardHeader-content">
                    <h6>Name</h6>
                   <RatingStart dynamicColor={containsSite2 ? "#FFCE00" : "#252237"}/>
                  </div>
                </div>
                <div className="card-body">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquam officia dolore distinctio magnam dolor cupiditate
                    enim doloremque quisquam nostrum.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="fromTeens--card">
                <div className="card-header">
                  <img src={teenOne} className="img-fluid" alt="" />
                  <div className="cardHeader-content">
                    <h6>Name</h6>
                   <RatingStart dynamicColor={containsSite2 ? "#FFCE00" : "#252237"}/>
                  </div>
                </div>
                <div className="card-body">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquam officia dolore distinctio magnam dolor cupiditate
                    enim doloremque quisquam nostrum.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="fromTeens--card">
                <div className="card-header">
                  <img src={teenOne} className="img-fluid" alt="" />
                  <div className="cardHeader-content">
                    <h6>Name</h6>
                   <RatingStart dynamicColor={containsSite2 ? "#FFCE00" : "#252237"}/>
                  </div>
                </div>
                <div className="card-body">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquam officia dolore distinctio magnam dolor cupiditate
                    enim doloremque quisquam nostrum.
                             </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="fromTeens--card">
                <div className="card-header">
                  <img src={teenOne} className="img-fluid" alt="" />
                  <div className="cardHeader-content">
                    <h6>Name</h6>
                   <RatingStart dynamicColor={containsSite2 ? "#FFCE00" : "#252237"}/>
                  </div>
                </div>
                <div className="card-body">
                  <p>
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Aliquam officia dolore distinctio magnam dolor cupiditate
                    enim doloremque quisquam nostrum.
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
          <div className="custom-next"></div>
          <div className="custom-prev"></div>
          </div>
        </div>
      </Container>
    </div>
  );
};
