import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import "./WhyChooseUs.css";
import highPass from "../../assets/images/high-pass.jpg"
import easyBooking from "../../assets/images/easy-booking.jpg"
import flexibleScheduling from "../../assets/images/flexible-scheduling.jpg"
import supportiveInstructor from "../../assets/images/supportive-instructor.jpg"

import passRate from "../../assets/images/top-pass-rate.png"
import paymentSecurity from "../../assets/images/payment-sequrity.png"
import flexibleSchedulingSiteTwo from "../../assets/images/sitetwo-flexible-scheduling.png"
import supportiveInstructorSiteTwo from "../../assets/images/sitetwo-supportive-instructor.png"


export const WhyChooseUs = () => {
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_WhyChooseUs--wrapper">
      <Container>
        <div className="keyzi_WhyChooseUs--inner">
          <div className="WhyChooseUs--head">
            <h2>{containsSite2?"Good Reasons To Choose Us":"Why Choose Us"}</h2>
          </div>
          <Row>
            <Col lg={3} sm={6}>
              <div className={containsSite2?"goodReasonChoose--card":"whychoose--card"}>
                <img src={containsSite2?passRate:highPass} alt="" className="img-fluid" />
                <h5>{containsSite2?"Top Pass Rate":"High Pass Rates"}</h5>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className={containsSite2?"goodReasonChoose--card":"whychoose--card"}>
                <img src={containsSite2?paymentSecurity:easyBooking} alt="" className="img-fluid" />
                <h5>{containsSite2?"Payment Security":"Easy Booking System"}</h5>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className={containsSite2?"goodReasonChoose--card":"whychoose--card"}>
                <img src={containsSite2?flexibleSchedulingSiteTwo:flexibleScheduling} alt="" className="img-fluid" />
                <h5>{containsSite2?"Flexible Scheduling":"Flexible Scheduling"}</h5>
              </div>
            </Col>
            <Col lg={3} sm={6}>
              <div className={containsSite2?"goodReasonChoose--card mb-0":"whychoose--card"}>
                <img src={containsSite2?supportiveInstructorSiteTwo:supportiveInstructor} alt="" className="img-fluid" />
                <h5>{containsSite2?"Supportive Instructor":"Supportive Instructor"}</h5>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
