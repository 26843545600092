import React from 'react'

export const EyeShoow = () => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_92_907)">
<path d="M19.9436 9.75C19.9186 9.6875 19.2101 8.21875 17.6431 6.74219C15.5511 4.78906 12.9089 3.75 10 3.75C7.09111 3.75 4.44894 4.78906 2.35688 6.74219C0.789911 8.21875 0.0814421 9.6875 0.0564374 9.75C0.0192244 9.82873 0 9.91389 0 10C0 10.0861 0.0192244 10.1713 0.0564374 10.25C0.0814421 10.3125 0.789911 11.7812 2.35688 13.2578C4.44894 15.2109 7.09111 16.25 10 16.25C12.9089 16.25 15.5511 15.2109 17.6431 13.2578C19.2101 11.7812 19.9186 10.3125 19.9436 10.25C19.9808 10.1713 20 10.0861 20 10C20 9.91389 19.9808 9.82873 19.9436 9.75V9.75ZM10 15C7.43284 15 5.19075 14.125 3.3404 12.3984C2.57569 11.6925 1.92685 10.8843 1.41503 10C1.92616 9.11298 2.57503 8.30205 3.3404 7.59375C5.19075 5.875 7.43284 5 10 5C12.5672 5 14.8092 5.875 16.6596 7.59375C17.425 8.30205 18.0738 9.11298 18.585 10C17.9849 11.0547 15.3677 15 10 15ZM10 6.25C9.20872 6.25 8.43522 6.46993 7.77729 6.88199C7.11937 7.29404 6.60659 7.87971 6.30378 8.56494C6.00097 9.25016 5.92174 10.0042 6.07611 10.7316C6.23048 11.459 6.61152 12.1272 7.17103 12.6517C7.73055 13.1761 8.44342 13.5333 9.21949 13.6779C9.99556 13.8226 10.8 13.7484 11.531 13.4645C12.2621 13.1807 12.8869 12.7001 13.3265 12.0834C13.7661 11.4667 14.0008 10.7417 14.0008 10C14.0008 9.00544 13.5793 8.05161 12.829 7.34835C12.0787 6.64509 11.0611 6.25 10 6.25ZM10 12.5C9.47248 12.5 8.95681 12.3534 8.5182 12.0787C8.07958 11.804 7.73772 11.4135 7.53585 10.9567C7.33398 10.4999 7.28116 9.99723 7.38407 9.51227C7.48699 9.02732 7.74101 8.58186 8.11402 8.23223C8.48703 7.8826 8.96228 7.6445 9.47966 7.54804C9.99704 7.45157 10.5333 7.50108 11.0207 7.6903C11.508 7.87952 11.9246 8.19995 12.2177 8.61107C12.5107 9.0222 12.6672 9.50555 12.6672 10C12.665 10.6624 12.3833 11.2971 11.8835 11.7655C11.3838 12.2339 10.7067 12.4979 10 12.5Z" fill="#999999"/>
</g>
<defs>
<clipPath id="clip0_92_907">
<rect width="20" height="12.5" fill="white" transform="translate(0 3.75)"/>
</clipPath>
</defs>
</svg>

  )
}

