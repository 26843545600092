// import React, { useEffect, useRef, useState } from "react";
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css";
// import { Button } from "react-bootstrap";
// import { Dropdown } from "../../assets/icons/Dropdown";
// import { Routers } from "../../routes/Constant";
// import { useNavigate } from "react-router-dom";
// import { useDispatch, useSelector } from "react-redux";
// import { InstructorDayTimeFilterAPI } from "../../services/instructorsAPI";
// export const TabData = () => {
//   const [timeTab, setTimeTab] = useState("");
//   const [dayTab, setDayTab] = useState("");
//   const [selectedDate, setSelectedDate] = useState(null);
//   const [tabData, setTabData] = useState({});
//   const innerref = useRef();
//   const navigate = useNavigate();
//   const dispatch = useDispatch();
//   const { InstructorDayTimeFilter } = useSelector((state) => state.instructors);


//   useEffect(() => {
//     console.log(tabData);
//     if((dayTab || selectedDate) && timeTab){
//       try{
//         dispatch(InstructorDayTimeFilterAPI(tabData));
//         console.log(InstructorDayTimeFilter)
//       }
//       catch(error){
//         console.log(error);
//       }
//     }
//   }, [tabData]);

//   const Input = React.forwardRef(
//     ({ onChange, placeholder, value, isSecure, id, onClick }, ref) => (
//       <>
//         <input
//           placeholder={placeholder}
//           onChange={onChange}
//           value={value}
//           id={id}
//           onClick={onClick}
//           className="custom_input sec_text_subhead"
//           ref={ref}
//         />
//       </>
//     )
//   );

//   const handleTimeTabChange = (tab) => {
//     setTimeTab(tab);
//     setTabData(prevTabData => ({
//       ...prevTabData,
//       timeTab: timeTab,
//     }));
//   };

//   const handleDayTabChange = (tab) => {
//     setDayTab(tab);
//     setTabData(prevTabData => ({
//       ...prevTabData,
//       selectedDate: null,
//       dayTab: dayTab
//     }));
//   };

//   const handleDateChange = (date) => {
//     if (date) {
//       setSelectedDate(date)
//       const year = date.getFullYear();
//       const month = String(date.getMonth() + 1).padStart(2, "0");
//       const day = String(date.getDate()).padStart(2, "0");
//       const reversedDate = `${year}-${month}-${day}`;
//       setTabData(prevTabData => ({
//         ...prevTabData,
//         selectedDate: reversedDate,
//         dayTab: ""
//       }));
      
//     }
//   };

//   const clearFilter = () => {
//     navigate(Routers.findanInstructor);
//   };

//   return (
//     <>
//       <div className="d-flex instructor_tab_wrapper justify-content-center col-12 tab_Sec mx-auto">
//         <div className="tab_first tab">
//           <ul className="br-10 bg-grey">
//             <li className="tab_time">Time</li>
//             <li
//               role="button"
//               className={` ${
//                 timeTab === "any_time" ? " text_manual text-indigo" : ""
//               }`}
//               onClick={() => handleTimeTabChange("any_time")}
//             >
//               Any Time
//             </li>
//             <li
//               role="button"
//               className={` ${
//                 timeTab === "am" ? "text_manual text-indigo" : ""
//               }`}
//               onClick={() => handleTimeTabChange("am")}
//             >
//               AM
//             </li>
//             <li
//               role="button"
//               className={` ${
//                 timeTab === "pm" ? "text_manual text-indigo" : ""
//               }`}
//               onClick={() => handleTimeTabChange("pm")}
//             >
//               PM
//             </li>
//           </ul>
//         </div>
//         <div className="tab_second tab mt-2 mb-2 mt-lg-auto">
//           <ul className="br-10 bg-grey">
//             <li className="tab_time">Day</li>
//             <li
//               role="button"
//               className={`${
//                 dayTab === "today_sun" ? "text-indigo text_manual" : ""
//               }`}
//               onClick={() => handleDayTabChange("today_sun")}
//             >
//               Today-Sun
//             </li>
//             <li
//               role="button"
//               className={`sec_text_subhead ${
//                 dayTab === "mon_thu" ? "text-indigo text_manual" : ""
//               }`}
//               onClick={() => handleDayTabChange("mon_thu")}
//             >
//               Mon-Thu
//             </li>
//             <li>
//               <DatePicker
//                 className="manual_datePicker"
//                 selected={selectedDate}
//                 placeholderText="Custom"
//                 onChange={handleDateChange}
//                 customInput={<Input />}
//               />
//             </li>
//           </ul>
//         </div>
//         <div>
//           <Button
//             variant="outline-primary"
//             className="br-10 text_manual clear_filter"
//             onClick={clearFilter}
//           >
//             Clear filter
//           </Button>
//         </div>
//       </div>
//     </>
//   );
// };


import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Button } from "react-bootstrap";
import { Dropdown } from "../../assets/icons/Dropdown";
import { Routers } from "../../routes/Constant";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { InstructorDayTimeFilter, fetchInstructorsFilter  } from "../../redux/slice/InstructorSlice";


export const TabData = () => {
  const [timeTab, setTimeTab] = useState("");
  const [dayTab, setDayTab] = useState("");
  const [tabData, setTabData] = useState({});
  const [selectedDate, setSelectedDate] = useState(null);
  const innerref = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const data = useSelector((state) => state.instructors);
  const [updatedOption,setUpdatedOption] = useState({})

  useEffect(() => {
   
    const getfilteredData = async () => {
      console.log(tabData);
      if ((dayTab || selectedDate) && timeTab) {
        try {
         await dispatch(InstructorDayTimeFilter(tabData));
        } catch (error) {
          console.log(error);
        }
      }
    }
    getfilteredData();
  }, [dayTab, selectedDate, timeTab]);

  const Input = React.forwardRef(({ onChange, placeholder, value, isSecure, id, onClick }, ref) => (
    <input
      placeholder={placeholder}
      onChange={onChange}
      value={value}
      id={id}
      onClick={onClick}
      className="custom_input sec_text_subhead"
      ref={ref}
    />
  ));

  const handleTimeTabChange = (tab) => {
    setTimeTab(tab);
    setTabData((prevTabData) => ({
      ...prevTabData,
      timeTab: tab,
    }));
  };

  const handleDayTabChange = (tab) => {
    setDayTab(tab);
    setTabData((prevTabData) => ({
      ...prevTabData,
      selectedDate: null,
      dayTab: tab,
    }));
  };

  const handleDateChange = (date) => {
    if (date) {
      setSelectedDate(date);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const day = String(date.getDate()).padStart(2, "0");
      const reversedDate = `${year}-${month}-${day}`;
      setTabData((prevTabData) => ({
        ...prevTabData,
        selectedDate: reversedDate,
        dayTab: "",
      }));
    }
  };

 useEffect(()=>{
  const option = localStorage.getItem("updatedOption");
  const parsedData = JSON.parse(option);
  setUpdatedOption(parsedData)
 },[])

  const clearFilter = () => {
    handleTimeTabChange("")
    handleDayTabChange("")
    handleDateChange("")
    setSelectedDate("")
    dispatch(fetchInstructorsFilter(updatedOption));
  };

  return (
    <>
       <div className="d-flex instructor_tab_wrapper justify-content-center col-12 tab_Sec mx-auto">
        <div className="tab_first tab">
          <ul className="br-10 bg-grey">
            <li className="tab_time">Time</li>
            <li
              role="button"
              className={` ${
                timeTab === "any_time" ? " text_manual text-indigo" : ""
              }`}
              onClick={() => handleTimeTabChange("any_time")}
            >
              Any Time
            </li>
            <li
              role="button"
              className={` ${
                timeTab === "am" ? "text_manual text-indigo" : ""
              }`}
              onClick={() => handleTimeTabChange("am")}
            >
              AM
            </li>
            <li
              role="button"
              className={` ${
                timeTab === "pm" ? "text_manual text-indigo" : ""
              }`}
              onClick={() => handleTimeTabChange("pm")}
            >
              PM
            </li>
          </ul>
        </div>
        <div className="tab_second tab mt-2 mb-2 mt-lg-auto">
          <ul className="br-10 bg-grey">
            <li className="tab_time">Day</li>
            <li
              role="button"
              className={`${
                dayTab === "today_sun" && selectedDate === null ? "text-indigo text_manual" : ""
              }`}
              onClick={() => {
                setSelectedDate(null);
                handleDayTabChange("today_sun")
              }}
            >
              Today-Sun
            </li>
            <li
              role="button"
              className={`sec_text_subhead ${
                dayTab === "mon_thu" && selectedDate === null ? "text-indigo text_manual" : ""
              }`}
              onClick={() => 
                {
                  setSelectedDate(null)
                  handleDayTabChange("mon_thu")
                }
              }
            >
              Mon-Thu
            </li>
            <li>
              <DatePicker
                className="manual_datePicker"
                selected={selectedDate}
                placeholderText="DD/MM/YYYY"
                onChange={handleDateChange}
                customInput={<Input />}
              />
            </li>
          </ul>
        </div>
        <div>
          <Button
            variant="outline-primary"
            className="br-10 text_manual clear_filter"
            onClick={clearFilter}
          >
            Clear filter
          </Button>
        </div>
      </div>
    </>
  );
};
