// import React, { useState } from "react";
// import { Button, Col, Row } from "react-bootstrap";
// import { useDispatch, useSelector } from "react-redux";
// import { learnerDashboardPwdChangeById } from "../../../redux/slice/authSlice";
// import "./Changepwd.css";
// import { useEffect,useRef } from "react";
// import * as yup from "yup";
// import { message } from "antd";
// import { EyeClosed } from "../../../assets/icons/EyeClosed";
// import { EyeShoow } from "../../../assets/icons/EyeShoow";

// export const Changepwd = () => {
//   const token = localStorage.getItem("token");
//   const [response, setResponse] = useState(token);
//   const { learnerDashboardPwdChange, error } = useSelector(
//     (state) => state?.auth
//   );
//   const isInitialRender = useRef(true);
//   const dispatch = useDispatch();
//   const [pwdchange, setPwdchange] = useState({
//     old_password: "",
//     password: "",
//     password_confirmation: "",
//   });
//   const [successMessage, setSuccessMessage] = useState(null);
//   const [errorMessage, setErrorMessage] = useState(null);
//   const [errors, setErrors] = useState({});

//   const validationSchema = yup.object().shape({
//     old_password: yup.string().required("Old Password is required"),
//     password: yup
//       .string()
//       .min(6, "Password must be at least 6 characters")
//       .required("New Password is required"),
//     password_confirmation: yup
//       .string()
//       .oneOf([yup.ref("password"), null], "Passwords must match")
//       .required("Confirm Password is required"),
//   });

//   const handleInputChange = (event) => {
//     setPwdchange({
//       ...pwdchange,
//       [event.target.name]: event.target.value,
//     });
//   };

//   const handleSubmit = async (event) => {
//     event.preventDefault();
//     try {
//       await validationSchema.validate(pwdchange, { abortEarly: false });
//       setErrors({});
//       const updatedPwdChange = {
//         ...pwdchange,
//         token: response,
//       };

//       dispatch(learnerDashboardPwdChangeById(updatedPwdChange));

//       setPwdchange({
//         old_password: "",
//         password: "",
//         password_confirmation: "",
//       });
//       // if (error) {
//       //   message.error({
//       //     content: error,
//       //     duration: 3,
//       //   });
//       // } else if (learnerDashboardPwdChange?.success) {
//       //   message.success({
//       //     content: learnerDashboardPwdChange.success,
//       //     duration: 3,
//       //   });
//       // }
      
//       // setErrorMessage(null);
//       // setSuccessMessage(null);
      
//     } catch (err) {
//       const newErrors = {};
//       err.inner.forEach((error) => {
//         newErrors[error.path] = error.message;
//       });
//       setErrors(newErrors);
//     }
//   };

//   useEffect(() => {
//       if (error) {
//         setErrorMessage(error);
//       } else if(learnerDashboardPwdChange?.success) {
//         setSuccessMessage(learnerDashboardPwdChange?.success);
//       }
//   }, [dispatch,learnerDashboardPwdChange?.success,error]);

//   useEffect(() => {
//     if (isInitialRender.current) {
//       isInitialRender.current = false;
//     } else {
//     if (error) {
//       message.error({
//         content: error,
//         duration: 3,
//       });
//     } else if (learnerDashboardPwdChange?.success) {
//       message.success({
//         content: learnerDashboardPwdChange.success,
//         duration: 3,
//       });
//     }
//     }
  
//     setErrorMessage(null);
//     setSuccessMessage(null);
//   }, [dispatch, learnerDashboardPwdChange?.success, error]);
  

//   return (
//     <>
//       <section className="changepwd_section br-10">
//         <Row className="bg-white profile_in_sec br-10 p-3 p-lg-5">
//           <Col>
//             <form onSubmit={handleSubmit}>
//               <div className="input_chpwd text-center text-lg-end">
//                 <div className="position-relative">
//                 <input
//                   type="password"
//                   placeholder="Old Password*"
//                   className="form-control"
//                   name="old_password"
//                   value={pwdchange.old_password}
//                   onChange={handleInputChange}
//                 />
//                 <EyeClosed/><EyeShoow/>
//                 {errors.old_password && (
//                   <p className="text-danger">{errors.old_password}</p>
//                 )}
//                 </div>

//                <div className="position-relative">
//                <input
//                   type="password"
//                   placeholder="New Password*"
//                   className="form-control"
//                   name="password"
//                   value={pwdchange.password}
//                   onChange={handleInputChange}
//                 />
//                 <EyeClosed/><EyeShoow/>

//                 {errors.password && (
//                   <p className="text-danger">{errors.password}</p>
//                 )}
//                </div>

//                <div className="position-relative">
//                <input
//                   type="password"
//                   placeholder="Confirm Password*"
//                   className="form-control"
//                   name="password_confirmation"
//                   value={pwdchange.password_confirmation}
//                   onChange={handleInputChange}
//                 />
//                 <EyeClosed/><EyeShoow/>

//                 {errors.password_confirmation && (
//                   <p className="text-danger">{errors.password_confirmation}</p>
//                 )}
//                </div>

//                 <Button type="submit" className="bnt-chpwd bg-indigo me-2">
//                   Submit
//                 </Button>
//                 <Button
//                   className="bnt-chpwd btn-cancel"
//                   onClick={() => {
//                     setPwdchange({
//                       old_password: "",
//                       password: "",
//                       password_confirmation: "",
//                     });
//                   }}
//                 >
//                   Cancel
//                 </Button>
//               </div>
//             </form>
//           </Col>
//         </Row>
//       </section>
//     </>
//   );
// };


import React, { useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { learnerDashboardPwdChangeById } from "../../../redux/slice/authSlice";
import "./Changepwd.css";
import { useEffect, useRef } from "react";
import * as yup from "yup";
import { message } from "antd";
import { EyeClosed } from "../../../assets/icons/EyeClosed";
import { EyeShoow } from "../../../assets/icons/EyeShoow";

export const Changepwd = () => {
  const token = localStorage.getItem("token");
  const [response, setResponse] = useState(token);
  const { learnerDashboardPwdChange, error } = useSelector(
    (state) => state?.auth
  );
  const isInitialRender = useRef(true);
  const dispatch = useDispatch();
  const [pwdchange, setPwdchange] = useState({
    old_password: "",
    password: "",
    password_confirmation: "",
  });
  const [successMessage, setSuccessMessage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errors, setErrors] = useState({});

  const validationSchema = yup.object().shape({
    old_password: yup.string().required("Old Password is required"),
    password: yup
      .string()
      .min(6, "Password must be at least 6 characters")
      .required("New Password is required"),
    password_confirmation: yup
      .string()
      .oneOf([yup.ref("password"), null], "Passwords must match")
      .required("Confirm Password is required"),
  });

  const handleInputChange = (event) => {
    setPwdchange({
      ...pwdchange,
      [event.target.name]: event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      await validationSchema.validate(pwdchange, { abortEarly: false });
      setErrors({});
      const updatedPwdChange = {
        ...pwdchange,
        token: response,
      };

      dispatch(learnerDashboardPwdChangeById(updatedPwdChange));

      setPwdchange({
        old_password: "",
        password: "",
        password_confirmation: "",
      });
      // if (error) {
      //   message.error({
      //     content: error,
      //     duration: 3,
      //   });
      // } else if (learnerDashboardPwdChange?.success) {
      //   message.success({
      //     content: learnerDashboardPwdChange.success,
      //     duration: 3,
      //   });
      // }
      
      // setErrorMessage(null);
      // setSuccessMessage(null);
      
    } catch (err) {
      const newErrors = {};
      err.inner.forEach((error) => {
        newErrors[error.path] = error.message;
      });
      setErrors(newErrors);
    }
  };

  useEffect(() => {
      if (error) {
        setErrorMessage(error);
      } else if(learnerDashboardPwdChange?.success) {
        setSuccessMessage(learnerDashboardPwdChange?.success);
      }
  }, [dispatch,learnerDashboardPwdChange?.success,error]);

  useEffect(() => {
    if (isInitialRender.current) {
      isInitialRender.current = false;
    } else {
    if (error) {
      message.error({
        content: error,
        duration: 3,
      });
    } else if (learnerDashboardPwdChange?.success) {
      message.success({
        content: learnerDashboardPwdChange.success,
        duration: 3,
      });
    }
    }
  
    setErrorMessage(null);
    setSuccessMessage(null);
  }, [dispatch, learnerDashboardPwdChange?.success, error]);

  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  // Function to toggle password visibility
  const togglePasswordVisibility = (field) => {
    switch (field) {
      case "old_password":
        setShowOldPassword(!showOldPassword);
        break;
      case "password":
        setShowNewPassword(!showNewPassword);
        break;
      case "password_confirmation":
        setShowConfirmPassword(!showConfirmPassword);
        break;
      default:
        break;
    }
  };

  return (
    <>
      <section className="changepwd_section br-10">
        <Row className="bg-white profile_in_sec br-10 p-3 p-lg-5">
          <Col>
            <form onSubmit={handleSubmit}>
              <div className="input_chpwd text-center text-lg-end">
                <div className="position-relative">
                  <input
                    type={showOldPassword ? "text" : "password"}
                    placeholder="Old Password*"
                    className="form-control"
                    name="old_password"
                    value={pwdchange.old_password}
                    onChange={handleInputChange}
                  />
                  {showOldPassword ? (
                   <span onClick={() => togglePasswordVisibility("old_password")}><EyeShoow  /></span> 
                  ) : (
                    <span onClick={() => togglePasswordVisibility("old_password")}><EyeClosed  /></span>
                  )}
                  {errors.old_password && (
                    <p className="text-danger">{errors.old_password}</p>
                  )}
                </div>

                <div className="position-relative">
                  <input
                    type={showNewPassword ? "text" : "password"}
                    placeholder="New Password*"
                    className="form-control"
                    name="password"
                    value={pwdchange.password}
                    onChange={handleInputChange}
                  />
                  {showNewPassword ? (
                   <span onClick={() => togglePasswordVisibility("password")}><EyeShoow  /></span> 
                  ) : (
                    <span onClick={() => togglePasswordVisibility("password")}><EyeClosed  /></span>
                  )}
                  {errors.password && (
                    <p className="text-danger">{errors.password}</p>
                  )}
                </div>

                <div className="position-relative">
                  <input
                    type={showConfirmPassword ? "text" : "password"}
                    placeholder="Confirm Password*"
                    className="form-control"
                    name="password_confirmation"
                    value={pwdchange.password_confirmation}
                    onChange={handleInputChange}
                  />
                  {showConfirmPassword ? (
                    <span onClick={() => togglePasswordVisibility("password_confirmation")}><EyeShoow/></span>
                  ) : (
                    <span  onClick={() => togglePasswordVisibility("password_confirmation")}><EyeClosed  /></span>
                  )}
                  {errors.password_confirmation && (
                    <p className="text-danger">{errors.password_confirmation}</p>
                  )}
                </div>

                <Button type="submit" className="bnt-chpwd bg-indigo me-2">
                  Submit
                </Button>
                <Button
                  className="bnt-chpwd btn-cancel"
                  onClick={() => {
                    setPwdchange({
                      old_password: "",
                      password: "",
                      password_confirmation: "",
                    });
                  }}
                >
                  Cancel
                </Button>
              </div>
            </form>
          </Col>
        </Row>
      </section>
    </>
  );
};