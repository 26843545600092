import React from "react";
import "./ExploreMore.css";
import { Container,Button } from "react-bootstrap";
const ExploreMore = () => {
  return (
    <section className="exploremore--wrapper">
      <Container>
        <div className="exploremore--inner">
          <h2 className="sub_head">Explore More</h2>
          <div className="exploremore--btnWrapper">
            <Button>Why choose Keyzi</Button>
            <Button>The Keyzi advantage</Button>
            <Button>Gift Vouchers</Button>
            <Button>Featured Blogs</Button>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default ExploreMore;
