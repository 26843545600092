import React from "react";
import { Container, Row, Col,Button } from "react-bootstrap";
import "./FlexiblesPackages.css";
import single from "../../assets/images/single-lesson.png"
import bulk from "../../assets/images/bulk-lessons.png"
import visa from "../../assets/images/visa.png"
import mastercard from "../../assets/images/master-card.png"
import eftops from "../../assets/images/eftops.png"

import site2single from "../../assets/images/sitetwo--single.jpg"
import site2bulk from "../../assets/images/sitetwp--bulk.png"



export const FlexiblesPackages = () => {
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_flexiblesPackages--wrapper">
      <Container>
        <div className="keyzi_flexiblesPackages--inner">
          <div className="flexiblesPackages--head">
          <h2>{containsSite2?"Combo Packs Save You Heaps":"Flexible Packages to Suit Your Pace"}</h2>
          <p>{containsSite2?"Turn a single lesson into a package after your first lesson":"From a single lesson to bulk buys, our packages are designed for every learner"}</p>
          </div>
              <div className="flexiblesPackages--cardWrapper">
              <div className="flexiblesPackages--card">
                <div className="card-img">
                  <img src={containsSite2 ? site2single:single} alt="Single Lesson" />
                </div>
                <div className="card-content">
                    <h5>SINGLE LESSON</h5>
                    <p>Dip your toes in with a one-off lesson.</p>
                </div>
              </div>
              <div className="flexiblesPackages--card">
                <div className="card-img">
                  <img src={containsSite2 ? site2bulk:bulk} alt="Bulk Lessons" />
                </div>
                <div className="card-content">
                    <h5>BULK LESSONS</h5>
                    <p>Commit to success and save with lesson packages.</p>
                </div>
              </div>
              </div>
          <div className="fp__lowerContent">
            <Button>{containsSite2?"See Gift Vouchers":"Click for Gift Vouchers"}</Button>
            <div className="fp_weaccept">
                <h4>We Accept</h4>
                <img src={visa} className="img-fluid master_card" alt="Visa" />
                <img src={mastercard} className="img-fluid master_card" alt="Master Card" />
                <img src={eftops} className="img-fluid master_card" alt="Eftops" />
            </div>
          </div>
        </div>
      </Container>
    </div>
  );
};