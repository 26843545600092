import React from 'react'
import { useEffect } from 'react'
import {BannerCsOne} from '../components/bannercsone/BannerCsOne'
import { PackageCsOne } from '../components/packagecsone/PackageCsOne'
import { WorkCsOne } from '../components/workscsone/WorkCsOne'
import { DrivingLessonsCsOne } from '../components/drivinglessonscsone/DrivingLessonsCsOne'
import { LearnerSayCsOne } from '../components/learnersaycsone/LearnerSayCsOne'
import { FaqCsOne } from '../components/faqcsone/FaqCsOne'
import { FeaturedCsOne } from '../components/featuredcsone/FeaturedCsOne'

export const CorporateSiteOne = () => {
  useEffect(()=>{
    window.localStorage.removeItem("prevsUrl")
},[])
  return (
   <>
    <BannerCsOne/>
    <PackageCsOne/>
    <WorkCsOne/>
    <DrivingLessonsCsOne/>
    <LearnerSayCsOne/>
    <FaqCsOne/>
    <FeaturedCsOne/>
   </>
  )
}
