import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import './GetRoad.css'
import getRoad from "../../assets/images/get-road-banner.png"
import banersitetwo from "../../assets/images/sitetwo-banner.png"

export const GetRoad = () => {
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_getRoad--wrapper">
      <Container>
        <div className="keyzi_getRoad--inner">
          <Row>
            <Col md={6}>
              <div className="keyzi_gtLeftSide">
                <h1>{containsSite2?("Your Time To Rise and Shine"):("Get on the Road to Freedom!")}</h1>
                <p>{containsSite2 ? ("Grab your lesson today and get started with a patient local Instructor"):("Connect instantly with top-rated local instructors and start driving today!")}</p>
                <InputSelector/>
              </div>
            </Col>
            <Col md={6}>
              <div className={containsSite2?"keyzi_gtRightSide--sitetwo":"keyzi_gtRightSide"}>
                {containsSite2?<img src={banersitetwo} alt="Time To Rise" className="img-fluid" />:<img src={getRoad} alt="Get on the Road" className="img-fluid" />}
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
