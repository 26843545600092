import {CalenderAvailabilityAPI} from '../../services/instructorsAPI';
import {CalenderAvailabilityData,CalenderAvailabilityDataSuccess,CalenderAvailabilityDataFailure} from "../slice/CalenderSlice"
import { call, put, takeLatest } from 'redux-saga/effects';


  function *CalenderAvailabilitySaga(action){
    try {
    const res =yield call(CalenderAvailabilityAPI,action);
    yield put(CalenderAvailabilityDataSuccess(res.data))
      
    } catch (error) {
      yield put(CalenderAvailabilityDataFailure(error.mess))
    }
    }

    export default function* calenderSaga() {
        yield takeLatest(CalenderAvailabilityData.type,CalenderAvailabilitySaga)
      }    