import React from "react";
import { Container, Row, Col,Button } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import './ExpertTutions.css'
import {expertTutions} from "../../assets/images/expert-trainner.png"

export const ExpertTutions = () => {
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_expertTutions--wrapper">
      <Container>
        <div className="keyzi_expertTutions--inner">
          <Row>
            <Col lg={containsSite2 ? 4 : 6} >
              <div className={`keyzi_turtionLeftSide ${containsSite2 && "keyzi_turtionLeftSIdesiteTwo"}`}>
                {!containsSite2 && <h2>Expert Tuition For Learner Drivers</h2>}
                <ul>
                  {containsSite2 ? <li>Road Safety + Experienced</li> : <li>Safety and Experienced</li>}
                  {containsSite2 ? <li>Gov’t Certified Instructors</li> : <li>Certified Instructors</li>}
                  {containsSite2 ? <li>Top Learners Pass Rate</li> : <li>Verified Top Pass Rates</li>}
                  {containsSite2 && <li>1000’s of Success Stories</li>}
                  {containsSite2 && <li>Learners Hall of Fame</li>}
                </ul>
                <Button className={`${containsSite2 && "mb-0"}`}>Join the Success Stories</Button>
              </div>
            </Col>
            <Col lg={containsSite2 ? 8 : 6}>
              <div className={containsSite2 ? "sitetwo_keyziturtionRightSide" : "keyzi_turtionRightSide"}>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};
