import Container from "react-bootstrap/Container";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/esm/Button.js";
import { ManualData } from "./ManualData.jsx";
import "./Manual.css";
import { PurpleLocation } from "../../assets/icons/Purple_location.js";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Calender } from "../../assets/icons/Calender.js";
import { TabData } from "./Tab.jsx";
import { Routers } from "./../../routes/Constant";
import { MainHome } from "../mainhome/MainHome";
import { useState } from "react";
import {GetRoad} from "./../getroad/GetRoad.jsx"
import React, { useEffect, useRef } from "react";
import Spinner from "react-bootstrap/Spinner";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { InstructorStateList, citylistByState, fetchInstructorsFilter, fetchInstructorsSingle } from "../../redux/slice/InstructorSlice.js";
import { CalendarModal } from "./CalendarModal.jsx";
import { BannerMain } from "../banner_main/BannerMain.jsx";
import GetStorage from "redux-persist/lib/storage/getStorage";
import { FaqCsOne } from '../../components/faqcsone/FaqCsOne'
import RediscoverChooseUs from '../../components/newpages/rediscover/RediscoverChooseUs'
import { GuideTestimonials } from '../../components/newpages/guide/GuideTestimonials'
import StateSuburb from '../../components/statesuburb/StateSuburb'
export const Manual = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showCalendar, setShowCalendar] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const { instructorsFilter,loading, error } = useSelector(
    (state) => state.instructors
  );
  const data = instructorsFilter?.length > 0 ? instructorsFilter[0]?.data : [];
  const [hideInput,setHideInput]=useState(false);
  useEffect(() => {
    if (!loading && data.length === 0) {
      console.log("No instructors found.");
    }
  }, [loading, data]);

  const handleClick = (id,index) => {
    navigate(`/MoreInfo/${id+"_"+index}`);
  };

  const checkAvailabilityHandler = (id) => {
    setShowCalendar(true);
    setSelectedId(id);
  };

  const location = useLocation();
  const targetRef = useRef(null);



  useEffect(()=>{
    if(window.localStorage.getItem("prevsUrl")){
      setHideInput(true);
    }
    else{
      setHideInput(false);
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: "smooth" });
      }
    }
  },[location])


  const handleBookNow = (packageDataById) => {

    localStorage.setItem("booking", "pending");
    dispatch(fetchInstructorsSingle(packageDataById));
    navigate(Routers.Login);
  };

  const updatedDataArray = data.map((obj) => {
    const date = new Date(obj.created_at);
    const year = date.getFullYear();
    return { ...obj, year };
  });

  const { state,city } = useParams();
  useEffect(()=>{
    if(state){
      dispatch(citylistByState(state))
    dispatch(fetchInstructorsFilter({
      state:state
    }))
    }
  },[state])
  const { citylist } = useSelector(
    (state) => state.instructors || { citylist: [] }
  );
  console.log(citylist);

  const cityIdhandler = (city_id) => {
    dispatch(fetchInstructorsFilter({
      city_id:city_id
    }))
    console.log(city_id);
  }

  return (
    <>
      {!hideInput && <GetRoad/>}
      <section className="instructor_wrapper m-top m-bot" >
        <Container>
          <Row className="text-center">
            <Col ref={targetRef}>
              <h5  className="choose_instruct text-light-black">
                CHOOSE YOUR INSTRUCTOR
              </h5>
              <h2 className="text-light-black sub_light_head mt-4">
                WE FOUND{" "}
                <span className="sub_head text-indigo">
                  {updatedDataArray?.length || 0} INSTRUCTOR
                </span>{" "}
                NEAR YOU
              </h2>
            </Col>
            <Col className="mt-3">
              <TabData />
            </Col>
          </Row>
          <Row  className="flex-sm-row row flex-column instructor_inner flex-wrap justify-content-start mt-5">
            {loading ? (
              <Col>
                <div className="spinner-container d-flex justify-content-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              </Col>
            ) : error ? (
              <Col>
                <p>Error: {error.message}</p>
              </Col>
            ) : updatedDataArray.length > 0 ? (
              updatedDataArray.map((i, index) => (
                <Col
                  className="col-md-4 col-12 inst_card_Sec m-2 mt-5 d-flex justify-content-center"
                  key={i.id}
                >
                  <Card className="instr_card bg-grey border-0 br-10 text-center">
                    <Card.Img
                      variant="top"
                      src={`http://167.71.238.150/keyzi-admin/public/${i.profile_image}`}
                      className="img_fluid img_instr mx-auto"
                    />
                    <Card.Body className="card_instr_body">
                      <div>
                        <Card.Title className="manual_title text-light-black">
                          {i.name}
                        </Card.Title>
                        <Card.Text className="year_jonin_instructor">
                          Instructor since {i.year}
                        </Card.Text>
                        <Card.Text className="text_manual">
                          {i.transmission_type}
                        </Card.Text>
                        <Link to="#" className="d-flex flex-start icon_link">
                          <PurpleLocation />
                          <span className="ms-2 location_Add text-light-black text-center">
                            {i.address} {i.pin_code}
                          </span>
                        </Link>
                      </div>
                      <div>
                        <Link
                          to="#"
                          onClick={() => checkAvailabilityHandler(i.id)}
                          className="link_calender text-indigo"
                        >
                          <Calender />
                          Check Availability
                        </Link>
                      </div>
                      <div className="d-flex justify-content-around">
                        <Button
                          variant="primary"
                          className="orange_btn ins_btn text_manual br-10 me-2"
                          onClick={() => handleBookNow(i.id,index)}
                        >
                          Book Now
                        </Button>
                        <Button
                          variant="outline-primary"
                          className="more_info_btn ins_btn br-10 text_manual ms-2"
                          onClick={() => handleClick(i.id,index)}
                        >
                          More Info
                        </Button>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              ))
            ) : (
              <Col>
                <h5 className="choose_instruct text-light-black text-center">No instructors found</h5>
              </Col>
            )}
          </Row>
        </Container>
      </section>
      {selectedId && showCalendar && (
        <CalendarModal
          showCalendar={showCalendar}
          id={selectedId}
          setShowCalendar={setShowCalendar}
        />
      )}
        {state && <StateSuburb cityIdhandler={cityIdhandler} citylist={citylist} name={`Driving Instructors in ${state}`}/>}
    {/* <StateSuburb name="Driving Instructors By Region" bgwhite="bg-white"/> */}
    <GuideTestimonials/>
    <RediscoverChooseUs/>
    <FaqCsOne/>
    </>
  );
};
