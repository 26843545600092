import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import cstwolearnerone from "../../assets/images/cstwo-learnerone.jpg"
import cstwolearnertwo from "../../assets/images/cstwo-learnertwo.jpg"
import cstwolearnerthree from "../../assets/images/cstwo-learnerthree.jpg"
import "./WorkCsTwo.css"
export const WorkCsTwo = () => {
  return (
    <section className="workcstwo--wrapper">
      <Container>
          <div className='workcstwo--inner'>
              <h2 className='sub_head'>How Keyzi works</h2>
              <p className='para_text'>Simple & Flexible booking system</p>
              <Row>
                <Col sm={6} md={4}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={cstwolearnerone} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Find Your Driving Instructors</h5>
                        <Link>Explore<span className='arrow_icon'></span></Link>
                      </div>
                  </div>
                </Col>
                <Col sm={6} md={4}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={cstwolearnertwo} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Book Your Driving Lessons</h5>
                        <Link>Explore<span className='arrow_icon'></span></Link>
                      </div>
                  </div>
                </Col>
                <Col sm={6} md={4}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={cstwolearnerthree} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h5>Learn To Drive</h5>
                        <Link>Explore<span className='arrow_icon'></span></Link>
                      </div>
                  </div>
                </Col>
              </Row>
          </div>
      </Container>
    </section>
  )
}
