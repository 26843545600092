import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/stylesheet/Stylesheet.css";
import "./components/newpages/newPage.css"
import "antd/dist/reset.css";
import { Router } from "./routes/Index";
// import RatingPage from "./components/rating/RatingPage";
// import { BannerMain } from "./components/banner_main/BannerMain";

function App() {
  return (
    <>
        <Router/>
      {/* <RatingPage/> */}
      {/* <BannerMain/> */}
    </>
  );
}

export default App;
