import React from 'react'
import Forward from '../components/newpages/guide/Forward'
import GuideBanner from '../components/newpages/guide/GuideBanner'
import GuideContent from '../components/newpages/guide/GuideContent'
import GuideRole from '../components/newpages/guide/GuideRole'
import { GuideTestimonials } from '../components/newpages/guide/GuideTestimonials'
import { FaqCsOne } from '../components/faqcsone/FaqCsOne'

const Guide = () => {
  return (
    <>
    <GuideBanner/>
    <GuideContent/>
    <GuideRole/>
    <GuideTestimonials/>
    <FaqCsOne/>
    <Forward/>
    </>
  )
}

export default Guide