import { call, put, takeLatest } from 'redux-saga/effects';

import {FeedBackAPI} from '../../services/instructorsAPI'
import { FeedBackData,FeedBackFailure,FeedBackSuccess } from "../slice/LearnerSlice";

function *FeedBackSaga(){
    try {
      const res =yield call(FeedBackAPI);
    yield put(FeedBackSuccess(res.data))
      
    } catch (error) {
      yield put(FeedBackFailure(error.mess))
    }
    }

    export default function* FeedBack() {
        yield takeLatest(FeedBackData.type , FeedBackSaga)
      }