import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Routers } from "./Constant";
import React from "react";
import { CardSection } from "../components/whykeyzi_card/Why_Keyzi_Card";
import { SchoolDetail } from "../pages/SchoolDetail";
import { CardDetail } from "../components/carddetail/CardDetail";
import { InstructorDetail } from "../pages/InstructorDetail";
import { Home } from "../pages/HomePage";
import { SiteTwo } from "../pages/SiteTwo";
import { Header } from "../components/header/Header";
import { Manual } from "../components/manual/Manual";
import { PublicRoutes } from "./PublicRoutes";
import { DashboardRoutes } from "./DashboardRoutes";
import { Progress } from "../components/Progress/Progress";
import { Login } from "../components/login/Login";
import { Footer } from "../components/footer/Footer";
import { AboutInstructor } from "../components/aboutinstructor/AboutInstructor";
import { Wallet } from "../components/dashboard/wallet/Wallet";
import { Profile } from "../components/dashboard/profile/Profile";
import { Booking } from "../components/dashboard/booking/Booking";
import { TransectionHistory } from "../components/dashboard/transactionhistory/TansectionHistory";
import { Changepwd } from "../components/dashboard/changepassword/Changepwd";
import { DashBoardMain } from "../components/dashboard/dashboardmain/DashBoardMain";
import ScrollTop from "../ScrollTop";
import { SignupInstructor } from "../pages/SignUpInstructor";
import { FindInstructor } from "../components/findInstructor/FindInstructor";
import { SignUpLearner } from "../pages/SignUpLearner";
import { ForgotPassword } from "../components/login/ForgotPassword";
import { ResetPassword } from "../components/login/ResetPassword";
import { useSelector, shallowEqual } from "react-redux";
import { useEffect } from "react";
import { FeedBackPage } from "../components/dashboard/booking/FeedBackPage";
import { LandingPage } from "../pages/LandingPage";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PageNotFound from "../pages/PageNotFound";
import { BookingDetails } from "../components/dashboard/booking/BookingDetails";
import { Contact } from "../components/contactus/Contact";
import { UpdateBooking } from "../components/dashboard/booking/UpdateBooking";
import { CorporateSiteOne } from "../pages/CorporateSiteOne";
import { CorporateSiteTwo } from "../pages/CorporateSiteTwo";
import Conquer from "../pages/Conquer"
import Unlock from "../pages/Unlock"
import Embark from "../pages/Embark";
import Guide from "../pages/Guide";
import Rediscover from "../pages/Rediscover";
import License from "../pages/License";
import Navigating from "../pages/Navigating";

export const Router = () => {
  const [referal, setReferal] = useState(false);
  const currentURL = window.location.href;
  useEffect(() => {
    if (currentURL.includes("/referalCode")) {
      setReferal(true);
    }
  }, [currentURL]);

  const publicRoutes = [
    {
      key: 1,
      path: Routers.Home,
      element: <PublicRoutes element={Home} />,
    },
    {
      key: 2,
      path: Routers.WhyKeyzi,
      element: <PublicRoutes element={CardSection} />,
    },
    {
      key: 3,
      path: Routers.OurInstructor,
      element: <PublicRoutes element={InstructorDetail} />,
    },
    {
      key: 4,
      path: Routers.School,
      element: <PublicRoutes element={SchoolDetail} />,
    },
    {
      key: 5,
      path: Routers.Contact,
      element: <PublicRoutes element={Contact} />,
    },
    {
      key: 6,
      path: Routers.SignUpLearner,
      element: <PublicRoutes element={SignUpLearner} />,
    },
    {
      key: 7,
      path: Routers.Manual,
      element: <PublicRoutes element={Manual} />,
    },
    {
      key: 8,
      path: Routers.MoreInfo,
      element: <PublicRoutes element={AboutInstructor} />,
    },
    {
      key: 9,
      path: Routers.Login,
      element: <PublicRoutes element={Login} />,
    },
    {
      key: 11,
      path: Routers.SignUpInstructor,
      element: <PublicRoutes element={SignupInstructor} />,
    },
    {
      key: 12,
      path: Routers.findanInstructor,
      element: <PublicRoutes element={FindInstructor} />,
    },
    {
      key: 14,
      path: Routers.ForgotPasword,
      element: <PublicRoutes element={ForgotPassword} />,
    },
    {
      key: 15,
      path: Routers.ResetPassoword,
      element: <PublicRoutes element={ResetPassword} />,
    },
    {
      key: 16,
      path: Routers.JoinKeyzi,
      element: <PublicRoutes element={CardDetail} />,
    },
    {
      key: 17,
      path: Routers.LandingPage,
      element: <PublicRoutes element={LandingPage} />,
    },
    {
      key: 17,
      path: Routers.Contact,
      element: <PublicRoutes element={Contact} />,
    },
    ,
    {
      key: 18,
      path: Routers.conquer,
      element: <PublicRoutes element={Conquer} />,
    },
    {
      key: 19,
      path: Routers.unlock,
      element: <PublicRoutes element={Unlock} />,
    },
    ,
    {
      key: 20,
      path: Routers.embark,
      element: <PublicRoutes element={Embark} />,
    },{
      key: 21,
      path: Routers.guide,
      element: <PublicRoutes element={Guide} />,
    },
    {
      key: 22,
      path: Routers.rediscover,
      element: <PublicRoutes element={Rediscover} />,
    },
    {
      key: 23,
      path: Routers.license,
      element: <PublicRoutes element={License} />,
    },
    {
      key: 23,
      path: Routers.navigating,
      element: <PublicRoutes element={Navigating} />,
    },
    {
      key: 24,
      path: Routers.ManualState,
      element: <PublicRoutes element={Manual} />,
    },
    {
      key: 25,
      path: Routers.ManualCity,
      element: <PublicRoutes element={Manual} />,
    },
    
  ];

  const dashboardRoutes = [
    {
      key: 1,
      path: Routers.Progress,
      element: <DashboardRoutes element={Progress} />,
    },
    {
      key: 2,
      path: Routers.Dashboard,
      element: <DashboardRoutes element={DashBoardMain} />,
    },
    {
      key: 3,
      path: Routers.Wallet,
      element: <DashboardRoutes element={Wallet} />,
    },
    {
      key: 4,
      path: Routers.Profile,
      element: <DashboardRoutes element={Profile} />,
    },
    {
      key: 5,
      path: Routers.Booking,
      element: <DashboardRoutes element={Booking} />,
    },
    {
      key: 6,
      path: Routers.Transaction,
      element: <DashboardRoutes element={TransectionHistory} />,
    },
    {
      key: 7,
      path: Routers.ChangePassword,
      element: <DashboardRoutes element={Changepwd} />,
    },
    {
      key: 10,
      path: Routers.bookingDetails,
      element: <DashboardRoutes element={BookingDetails} />,
    },
    ,
    {
      key: 11,
      path: Routers.feedbacklearner,
      element: <DashboardRoutes element={FeedBackPage} />,
    },
    {
      key: 12,
      path: Routers.updateBooking,
      element: <DashboardRoutes element={UpdateBooking} />,
    },
    {
      key:13,
      path:Routers.site2,
      element:<SiteTwo/>
    },
    {
      key:14,
      path:Routers.CorporateSiteOne,
      element:<CorporateSiteOne/>
    },
    {
      key:15,
      path:Routers.CorporateSiteTwo,
      element:<CorporateSiteTwo/>
    }

  ];

  const [update, setUpdate] = useState("");
  const updateDataHandler = (data) => {
    setUpdate(data);
  };
  return (
    <>
      <BrowserRouter>
        <ScrollTop />
        {!referal && <Header update={update} />}
        <Routes>
          {publicRoutes.map((route) => (
           <React.Fragment key={route.key}>
             <Route
              exact
              key={route.key}
              path={route.path}
              element={route.element}
            />
           </React.Fragment>
          ))}

          {dashboardRoutes.map((route) => (
            <React.Fragment key={route.key}>
              <Route

                key={route.key}
                path={route.path}
                element={route.element}
                updateDataHandler={updateDataHandler}
              />
            </React.Fragment>
          ))}
        </Routes>
        {!referal && <Footer />}
      </BrowserRouter>
    </>
  );
};
