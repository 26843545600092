import { useEffect, useState } from "react";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/esm/Button";
import "./Login.css";
import { RightArrow } from "./../../assets/icons/RightArrow";
import { Bannermain } from "../bannermain/Bannermain";
import { Routers } from "../../routes/Constant";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { loginStart } from "../../redux/slice/authSlice";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
export const Login = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { user, error } = useSelector((state) => state?.auth);
  const [ErrorloginMessage, setErrorloginMessage] = useState(false);
  const schema = yup.object().shape({
    email: yup
      .string()
      .required("Email is required")
      .email("Enter a valid email"),
    password: yup.string().min(6, "Password must be at least 6 characters"),
  });

  const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });



  const onSubmit = async (values) => {
    console.log('submit')
    try {
      await dispatch(loginStart(values));
    } catch (error) {
      console.error("An error occurred while logging in:", error);
    }
  };

  useEffect(() => {
    if (error && !user) {
      setErrorloginMessage(true);
    }
  }, [onSubmit]);

  useEffect(()=>{
    setErrorloginMessage(false);
  },[])




  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        localStorage.setItem("token", user?.data?.data.token);
        const booking = localStorage.getItem("booking");
        if (booking) {
          navigate(Routers.Progress);
        } else {
          navigate(Routers.Dashboard);
        }
      } catch (error) {
        console.error(
          "An error occurred while fetching dashboard info:",
          error
        );
      }
    };

    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);
  return (
    <>
      <Bannermain bgconfirm="container-o-bg-clr">
        <section className="login_card">
          <h1 className="main_head text-indigo text-center">WELCOME</h1>
          <Container className="login_container mt-5">
            <Row className="login_sec br-10">
              <Col lg={6} md={12} className="left_login_Col">
                <div className="left_login_sec pt-0 pt-lg-2">
                  <h2 className=" sub_light_head  text-light-black left_login_head mb-0">
                    DON'T HAVE AN
                    <span className="main_head text-indigo"> ACCOUNT?</span>
                  </h2>
                  <p className="sec_text_subhead mb-0 pt-lg-4 pt-0">
                    Join Keyzi today and get started with quick and easy lesson
                    bookings.
                  </p>

                  <div className="d-flex  flex-md-row flex-column justify-content-between inst_learn_btn  btn_left pt-lg-5 pt-3">
                    <div>
                      <Button
                        variant="primary"
                        className=" btn_login_card  btn_learn br-10 text-light-grey "
                        onClick={() => {
                          navigate(Routers.SignUpLearner);
                        }}
                      >
                        I'm Learner
                        <span>
                          <RightArrow />
                        </span>
                      </Button>
                    </div>

                    <div className="pt-md-0">
                      <Button
                        variant="primary"
                        className=" btn_login_card btn_instr br-10 text-light-grey"
                        onClick={() => {
                          navigate(Routers.SignUpInstructor);
                        }}
                      >
                        I'm Instructor
                        <span>
                          <RightArrow />
                        </span>
                      </Button>
                    </div>
                  </div>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="login_right pt-3">
                  <h4 className="  text-light-grey login_right_head">LOGIN</h4>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <Row className="pt-none pt-md-3">
                      <Col>
                        <div className="pt-2 pt-md-4">
                          <input
                            type="Email"
                            className="input_tag br-10 p-2 p-md-4 "
                            {...register("email", { required: true })}
                            placeholder="Email*"
                          />
                          {errors.email && (
                            <div className="text-danger m-1">
                              {errors.email.message}
                            </div>
                          )}
                        </div>
                        <div className="pt-2 pt-md-4">
                          <input
                            type="Password"
                            placeholder="Password*"
                            className="input_tag br-10 p-2 p-md-4 me-2"
                            {...register("password", { required: true })}
                          />
                          {errors.password && (
                            <div className="text-danger m-1">
                              {errors.password.message}
                            </div>
                          )}
                        </div>
                        <div className="d-flex  flex-sm-row  flex-column  justify-content-between inst_learn_btn pt-4 pt-sm-5">
                          <div>
                            <Button
                              variant="primary"
                              className="orange_btn text_manual bg-orange login_btn br-10 "
                              type="submit"
                            >
                              Login
                            </Button>
                          </div>
                          <div>
                            <div className="text-lg-end  forget_password text_manual  p-3 ">
                              <Link
                                to={Routers.ForgotPasword}
                                className="text-indigo text-decoration-none"
                              >
                                Forgot password?
                              </Link>
                            </div>
                          </div>
                        </div>
                        {ErrorloginMessage && (
                          <p className="text-danger">
                            Invalid Login Credentials
                          </p>
                        )}
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Bannermain>
    </>
  );
};
