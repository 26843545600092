// path/to/your/redux/slice.js

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  yourObject: {},
};

const objectSlice = createSlice({
  name: 'object', // The name of your slice
  initialState,
  reducers: {
    objectFiledDataStart: (state, action) => {
      state.yourObject = action.payload
    }
    // Other reducer functions here
  },
});

export const { objectFiledDataStart } = objectSlice.actions;
export default objectSlice.reducer;
