import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Form } from "react-bootstrap";
import "./FilterPopup.css";
import { useState } from "react";

function FilterPopup(props) {
  const [amount, setAmount] = useState(20);
  const [experience, setExperience] = useState("");
  const [rating, setRating] = useState("");

  const changeFilterAmount = (e) => {
    setAmount(e.target.value);
  };

  const handleExperienceChange = (e) => {
    setExperience(e.target.value);
  };

  const handleRatingChange = (e) => {
    setRating(e.target.value);
  };

  const handleSearch = () => {
    const filters = {
      amount,
      experience,
      rating,
    };
    props.onSearch(filters);
  };

  const restFilter = () => {
    setExperience("");
    setRating("");
    props.onSearch({});
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="filter_popup"
    >
      <Modal.Header className="border-0" closeButton>
        <Modal.Title
          id="contained-modal-title-vcenter"
          className="filter_card_heading text-indigo"
        >
          More Filters
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="filter_modal_section">
          <div className="me-2 filter_pop_select_style">
            <Form.Label className="filterpopup_labels">
              Minimum experience
            </Form.Label>
            <select
              name=""
              id=""
              className="form-control p-none p-md-3 border-0 prodes_text"
              value={experience}
              onChange={handleExperienceChange}
            >
              <option value="" disabled>Experience (Years)</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5+</option>
            </select>
          </div>
          <div className="ms-2 filter_pop_select_style">
            <Form.Label className="filterpopup_labels">
              Minimum rating
            </Form.Label>
            <select
              name=""
              id=""
              className="form-control p-none p-md-3 border-0 prodes_text"
              value={rating}
              onChange={handleRatingChange}
            >
              <option value="" disabled>Rating</option>
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
            </select>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="border-0">
        <Button onClick={handleSearch} className="orange_btn filterSearch_btn">
          Search
        </Button>
        <Button onClick={restFilter} className="clear_btn filterSearch_btn bg-indigo">
          Clear
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default FilterPopup;
