import React from 'react'
import NavigationBanner from '../components/newpages/navigating/NavigationBanner'
import NavigatingContent from '../components/newpages/navigating/NavigatingContent'
import NavigatingTeritory from '../components/newpages/navigating/NavigatingTeritory'
import NavigatingJourney from '../components/newpages/navigating/NavigatingJourney'

const Navigating = () => {
  return (
   <>
    <NavigationBanner/>
    <NavigatingContent/>
    <NavigatingTeritory/>
    <NavigatingJourney/>
   </>
  )
}

export default Navigating