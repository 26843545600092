import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import InputSelector from "../inputselector/InputSelector";
import "./LicenseJourney.css";

import bookLesson from "../../assets/images/book-lesson.png"
import chooseInstructor from "../../assets/images/choose-instructor.png"
import driverSucceed from "../../assets/images/driver-succed.png"

import stepone from "../../assets/images/book-stepone.png"
import steptwo from "../../assets/images/book-steptwo.png"
import stepthree from "../../assets/images/book-stepthree.png"




export const LicenseJourney = () => {
    const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes('site2');
  console.log(containsSite2);
  return (
    <div className="keyzi_LicenseJourney--wrapper">
      <Container>
        <div className="keyzi_LicenseJourney--inner">
          <div className="licenseJourney--head">
          <h2>{containsSite2?"3 Steps To Get You There":"Your Licence Journey, Simplified"}</h2>
          </div>
              <div className="licensejourney--card">
                <div className="card-img">
                  {containsSite2? <img src={stepone} alt="Choose Your Instructor" className="img-fluid" />:<img src={chooseInstructor} alt="Choose Your Instructor" className="img-fluid" />}
                </div>
                <div className="card-content">
                {containsSite2 && <h6>Select Your Instructor</h6>}
                    <h5>{containsSite2?"Step 1":"Choose Your Instructor"}</h5>
                    {!containsSite2 && <p>Browse profiles of trusted local instructors</p>}
                </div>
              </div>
              <div className="licensejourney--card">
                <div className="card-img">
                  {containsSite2?<img src={steptwo} alt="Book Your Lesson" className="img-fluid image-flip" />:<img src={bookLesson} alt="Book Your Lesson" className="img-fluid image-flip" />}
                </div>
                <div className="card-content">
                {containsSite2 && <h6>Select Your Time</h6>}
                    <h5>{containsSite2?"Step 2":"Book Your Lesson"}</h5>
                    {!containsSite2 && <p>Select a time that suits you,book instantly.</p>}
                </div>
              </div>
              <div className="licensejourney--card licensejourney--cardlast">
                <div className="card-img">
                  {containsSite2?<img src={stepthree} alt="Drive & Succeed" className="img-fluid" />:<img src={driverSucceed} alt="Drive & Succeed" className="img-fluid" />}
                </div>
                <div className="card-content">
                {containsSite2 && <h6>Be Picked Up</h6>}
                    <h5>{containsSite2?"Step 3":"Drive & Succeed"}</h5>
                    {!containsSite2 && <p>Hit the road with confidence and get ready to ace your test.</p>}
                </div>
              </div>
        </div>
        
      </Container>
    </div>
  );
};
