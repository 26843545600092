import React from "react";
import "./StateSuburb.css" 
import {Link} from "react-router-dom";
import {Routers} from "../../routes/Constant"

const StateSuburb = ({bgwhite,name,citylist,cityIdhandler}) => {
  const chunkedData = [];
  for (let i = 0; i < citylist?.[0]?.length; i += 60) {
    chunkedData?.push(citylist?.[0]?.slice(i, i + 20));
  }
  return (
    <>
      <section class={`keyzi_stateSuburbList--wrapper ${bgwhite}`}>
        <div class="container">
          <div class="keyzi_stateSuburbList--inner">
          <h5 class="">{name}</h5>
          <div className="row">
          {chunkedData.map((chunk, columnIndex) => (
                    <div key={columnIndex} className="city-list">
                        <ul className="list location-list">
                            {chunk.map((item, index) => (
                                <li key={index}><Link onClick={()=>cityIdhandler(item?.id)} to={`/driving_instructors/${item?.state_code.trim()}/${item?.place_name.trim().replace(/\s+/g, '-')}`}>{item?.place_name}</Link></li>
                            ))}
                        </ul>
                    </div>
                ))}
          </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default StateSuburb;
