import React from 'react'

const MainConquer = () => {
  return (
    <section className="keyzi_conquer--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
              <h3>Conquer Your Driving Test with Confidence — Our Comprehensive
              Driving Test Packages</h3>
              <p>Are You Nervously Anticipating Your Driving Test?</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side">
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default MainConquer