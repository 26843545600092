import { call, put, takeLatest } from 'redux-saga/effects';
import {  fetchInstructors,
  fetchInstructorsSuccess,
  fetchInstructorsFailure,
  fetchInstructorsSingle,
  fetchInstructorsSingleSuccess,
  fetchInstructorsSingleFailure  ,fetchInstructorsFilter,
  fetchInstructorsFilterSuccess,
  fetchInstructorsFilterFailure,
  InstructorDayTimeFilter,
  InstructorDayTimeFilterSuccess,
  InstructorStateList,
  InstructorStateListSuccess,
  InstructorStateListFailure,
  citylistByState,
  citylistByStateSuccess,
  citylistByStateFailure,
  InstructorDayTimeFilterFailure } from '../slice/InstructorSlice';
import { fetchInstructorsAPI, fetchInstructorByIdAPI ,instructorFilterAPI,InstructorDayTimeFilterAPI,InstructorStateListAPI,citylistByStateAPI } from '../../services/instructorsAPI';

function* fetchInstructorsSaga() {
  try {
    const response = yield call(fetchInstructorsAPI);
    yield put(fetchInstructorsSuccess(response.data));
  } catch (error) {
    yield put(fetchInstructorsFailure(error.message));
  }
}

function* fetchInstructorByIdSaga(action) {
  try {
    console.log(action.payload);
    const response = yield call(fetchInstructorByIdAPI, action.payload);
    console.log(response);
    yield put(fetchInstructorsSingleSuccess(response.data));
  } catch (error) {
    yield put(fetchInstructorsSingleFailure(error.message));
  }
}

function* fetchInstructorByFilterSaga(action) {
  try {
    const response = yield call(instructorFilterAPI, action.payload);
    console.log(response);

    yield put(fetchInstructorsFilterSuccess(response.data));
  } catch (error) {
    yield put(fetchInstructorsFilterFailure(error.message));
  }
}

function* InstructorDayTimeFilterSaga(action) {
  try {
    const response = yield call(InstructorDayTimeFilterAPI, action.payload);
    yield put(InstructorDayTimeFilterSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(InstructorDayTimeFilterFailure(error.message));
  }
}

function* InstructorStateListSaga(action) {
  try {
    const response = yield call(InstructorStateListAPI, action.payload);
    yield put(InstructorStateListSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(InstructorStateListFailure(error.message));
  }
}

function* citylistByStateSaga(action) {
  try {
    const response = yield call(citylistByStateAPI, action.payload);
    yield put(citylistByStateSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(citylistByStateFailure(error.message));
  }
}




export default function* instructorsSaga() {
  yield takeLatest(fetchInstructors.type, fetchInstructorsSaga);
  yield takeLatest(  fetchInstructorsSingle.type, fetchInstructorByIdSaga);
  yield takeLatest(  fetchInstructorsFilter.type, fetchInstructorByFilterSaga);
  yield takeLatest(  InstructorDayTimeFilter.type, InstructorDayTimeFilterSaga);
  yield takeLatest(  InstructorStateList.type, InstructorStateListSaga);
  yield takeLatest(  citylistByState.type, citylistByStateSaga);
}


