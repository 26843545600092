import React from "react";
import pathOne from "../../../assets/images/sitetwo--single.jpg";
import pathTwo from "../../../assets/images/sitetwp--bulk.png";
import { Container } from "react-bootstrap";

const Path = () => {
  return (
    <>
      <section className="keyzi_journey--wrapper keyzi_path--wrapper">
        <Container>
          <div className="keyzi_journey--inner">
            <h2>Choose The Path That Suits You Best</h2>
            <div className="path_card_wrapper">
              <div className="path--card">
                <div className="card-img"><img src={pathOne} className="img-fluid" alt="icon" /></div>
                <div className="card-content">

                  <h5>Driving Test Package Plus Lessons</h5>
                  <p>
                    Combine the driving test package with additional driving
                    lessons for a comprehensive experience. It's the perfect
                    blend to ensure you're as prepared as can be.
                  </p>
                </div>
              </div>
              <div className="path--card">
                

                <div className="card-content">
                  <h5>Stand-Alone Driving Test Package</h5>
                  <p>
                    Opt for a focused approach with our stand-alone package,
                    ideal for those who feel ready to take the test.
                  </p>
                </div>
                <div className="card-img"><img src={pathTwo} className="img-fluid" alt="icon" /></div>

              </div>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Path;
