import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import licenseWhyone from "../../../assets/images/newpages/licenseWhyone.png";
import licenseWhytwo from "../../../assets/images/newpages/licenseWhytwo.png";
import licenseWhythree from "../../../assets/images/newpages/licenseWhythree.png";
import licenseWhyfour from "../../../assets/images/newpages/licenseWhyfour.png";
const LicenseWhy = () => {
  return (
    <section className="keyzi_discChoose--wrapper">
      <Container>
        <div className="keyzi_discChoose--inner">
          <h2>Why Choose Us</h2>

          <div className="discChooseCards--wrapper">
            <div className="choose--card">
              <img src={licenseWhyone} className="img-fluid" alt="icon" />
              <h5>Expert Guidance and Support</h5>
              <p>
                Our driving lessons and test packages are designed to prepare
                you for the Australian driving test, making the transition as
                smooth and stress-free as possible.
              </p>
            </div>
            <div className="choose--card">
              <img src={licenseWhytwo} className="img-fluid" alt="icon" />
              <h5>Flexible Learning Options</h5>
              <p>
                Whether you prefer learning in our provided vehicles equipped
                with dual control systems for safety or in your own car, we
                adapt to your comfort and learning preferences.
              </p>
            </div>
            <div className="choose--card">
              <img src={licenseWhythree} className="img-fluid" alt="icon" />
              <h5>Comprehensive Test Preparations</h5>
              <p>
                With access to resources like practice tests and personalised
                instruction, we ensure you're not just ready for the test but
                confident in your driving skills on Australian roads.
              </p>
            </div>
            <div className="choose--card">
              <img src={licenseWhyfour} className="img-fluid" alt="icon" />
              <h5>Tailored Packages</h5>
              <p>
                From documentation preparation to passing your driving test, our
                packages offer comprehensive support tailored to your unique
                needs during this transition.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default LicenseWhy;
