import React, { useState } from 'react';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import { Bannermain } from '../bannermain/Bannermain';
import { Routers } from '../../routes/Constant';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { forgotPasswordStart } from '../../redux/slice/authSlice';
import { message } from 'antd';
import * as Yup from 'yup';
import { LeftArrow } from '../../assets/icons/LeftArrow';
import { Link } from 'react-router-dom';
export const ForgotPassword = () => {
  const Dispatch = useDispatch();
  const [formValues, setFormValues] = useState({
    email: '',
  });

  const [formErrors, setFormErrors] = useState({});

  const response = useSelector((state) => state.auth);
  const navigate = useNavigate();

  const validationSchema = Yup.object().shape({
    email: Yup.string().email('Invalid email address').required('Email is required'),
  });

  const handleClick = (e) => {
    const { name, value } = e.target;
    setFormValues({
      ...formValues,
      [name]: value,
    });
  };

  const handleBlur = async () => {
    try {
      await validationSchema.validate(formValues.email, { abortEarly: false });
      setFormErrors({ ...formErrors, email: '' });
    } catch (error) {
      setFormErrors({ ...formErrors, email: error.message });
    }
  };

  const handleVerifyEmail = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formValues, { abortEarly: false });
      Dispatch(forgotPasswordStart(formValues));
      console.log(response);
      setFormValues({ email: '' });
      setFormErrors({});
      message.success({
        content: "Password reset link sent to your email. Check your inbox.",
        duration: 5,
      });
      navigate(Routers.Login);
    } catch (error) {
      const errors = {};
      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });
      setFormErrors(errors);
    }
  };


  return (
    <>
      <Bannermain bgconfirm="container-o-bg-clr">
        <section className="login_card">
          <h1 className="main_head text-indigo text-center">WELCOME</h1>
          <h6><Link className="back_Link" to={Routers.Login}><LeftArrow />Back</Link></h6>
          <Container className="login_container Forgot_section mt-5">
            <Row className="login_sec br-10  forgot_password_wrapper">
              <Col lg={6} md={12} className="left_login_Col">
                <div className="left_login_sec forgot_sec_left  pt-0 pt-lg-2">
                  <h2 className=" sub_light_head  text-light-black left_login_head mb-0">
                    Forgot Your<span className="main_head text-indigo"> Password?</span>
                  </h2>
                  <p className="sec_text_subhead mb-0 pt-lg-4 pt-0">
                    Join Keyzi today and get started with quick and easy lesson bookings.
                  </p>
                </div>
              </Col>
              <Col lg={6} md={12}>
                <div className="login_right forgot_right pt-3">
                  <h4 className="  text-light-grey login_right_head">Forgot Password</h4>
                  <form action="">
                    <Row className="pt-3">
                      <Col>
                        <div className="">
                          <input
                            type="email"
                            placeholder="Email*"
                            className={`input_tag br-10 p-2 p-md-4 ${formErrors.email ? 'is-invalid' : ''}`}
                            name="email"
                            value={formValues.email}
                            onChange={handleClick}
                          />
                          {formErrors.email && (
                            <div className="invalid-feedback">{formErrors.email}</div>
                          )}
                        </div>

                        <div className="d-flex  flex-sm-row  flex-column  justify-content-between inst_learn_btn pt-3">
                          <div>
                            <Button
                              variant="primary"
                              className="orange_btn text_manual bg-orange login_btn br-10 "
                              onClick={handleVerifyEmail}
                            >
                              Verify email
                            </Button>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </form>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </Bannermain>
    </>
  );
};
