
import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import visa from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/master-card.png";
import eftops from "../../../assets/images/eftops.png";
import InputSelector from "../../inputselector/InputSelector";
const NavigatingJourney = () => {
  return (
    <>
       <section className="keyzi_quite--wrapper ">
    <Container>
    <div className='keyzi_quite--inner'>
      <h2 className="m-0">Navigating Your Path to Licensure</h2>

    </div>
    </Container>
  </section>
      <section className="keyzi_clickAway--wrapper keyzi_discJourney--wrapper keyzi_navigatingJourney--wrapper">
        <div className="click_away--banner  "></div>
        
        <Container>
          <div className="keyzi_clickAway--inner ">
            <Row>
              <Col lg={6}>
                <p>
                Each state and territory in Australia offers a unique set of resources, requirements, and tests to ensure that drivers are well-prepared for the road. By visiting the provided links, you'll access the most current and comprehensive information directly from the official government sources, ensuring you're on the right track to achieving your driving goals, no matter where you are in Australia. Whether you're a beginner, converting your licence, or looking to refresh your skills, understanding these regional distinctions will guide you towards a smoother, more informed driving journey.            
                </p>
              </Col>
              <Col lg={6}>
                <div className="licenseJourny-inputWrapper">
                <div className="newPage--InputSelector">
                  <h3>
                  Ready to start the journey?
                  </h3>
                  <p>Contact us today to find your driving instructor and take the first step towards converting your international licence with confidence and ease.</p>
                  <InputSelector label="Instructor Search" />
                  <div className="fp_weaccept">
                    <h4>We Accept</h4>
                    <img src={visa} className="img-fluid " alt="Visa" />
                    <img
                      src={mastercard}
                      className="img-fluid master_card"
                      alt="Master Card"
                    />
                    <img src={eftops} className="img-fluid " alt="Eftops" />
                  </div>
                </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default NavigatingJourney;
