import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import * as Yup from 'yup'; // Import Yup for form validation
import "./Contact.css";
import { contactusUser } from "../../redux/slice/authSlice";
import { useDispatch } from "react-redux";

export const Contact = () => {
  const dispatch = useDispatch();
  const [success,setSuccess] = useState(false)
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    message: "",
  });

  const [formErrors, setFormErrors] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    email: Yup.string().email("Invalid email address").required("Email is required"),
    phone: Yup.string().required("Phone is required"),
    message: Yup.string().required("Message is required"),
  });

  const contactUsFormHandler = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      dispatch(contactusUser(formData));
      setSuccess(true);
      setTimeout(()=>{
        setSuccess(false);
      },2000)
      setFormData({
        name: "",
        email: "",
        phone: "",
        message: "",
      })

    } catch (error) {
      const errors = {};

      error.inner.forEach((e) => {
        errors[e.path] = e.message;
      });

      setFormErrors(errors);
    }
  };

  return (
    <section className="contact_form_wrapper">
      <Container>
        <div className="contactform_inner">
          <h4 className="sub_light_head text-center">
            CONTACT <span className="text-indigo sub_head">US</span>
          </h4>
          <Form onSubmit={contactUsFormHandler}>
            <Row>
              <Col sm={12}>
                <Form.Group className="mb-3" controlId="formBasicName">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="example name"
                    name="name"
                    value={formData.name}
                    onChange={handleInputChange}
                  />
                  {formErrors.name && <div className="text-danger">{formErrors.name}</div>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="example@example.com"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                  />
                  {formErrors.email && <div className="text-danger">{formErrors.email}</div>}
                </Form.Group>
              </Col>
              <Col md={6}>
                <Form.Group className="mb-3" controlId="formBasicPhone">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="+01 000004444"
                    name="phone"
                    value={formData.phone}
                    onChange={handleInputChange}
                  />
                  {formErrors.phone && <div className="text-danger">{formErrors.phone}</div>}
                </Form.Group>
              </Col>

              <Col sm={12}>
                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                  <Form.Label>Message</Form.Label>
                  <Form.Control
                    as="textarea"
                    placeholder="example message..."
                    rows={3}
                    name="message"
                    value={formData.message}
                    onChange={handleInputChange}
                  />
                  {formErrors.message && <div className="text-danger">{formErrors.message}</div>}
                </Form.Group>
              </Col>

              <Col md={12}>
                <div className="text-center">
                  <Button variant="primary" type="submit">
                    Submit
                  </Button>
                </div>
              </Col>
              {success ? <span className="text-center text-success">Message Delivered! Your inquiry is important to us, and we will respond as soon as we can.</span> : ""}
            </Row>
          </Form>
        </div>
      </Container>
    </section>
  );
};
