

import React from "react";
import road from "../../../assets/images/newpages/road.png";

const RediscoverContent = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner">
          <p>
          Has the open road been calling your name, yet something holds you back? Whether it's been months or years since you last sat behind the wheel, the journey back to confident driving is closer than you think. Life takes us through various detours—moving cities, changes in lifestyle, or simply the evolution of our daily routines. In these times, our driving skills can take a backseat. But it's never too late to reignite your driving passion and polish those skills to shine on the road once again.
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default RediscoverContent;
