
export const RatingStart=({dynamicColor})=>{
  return(
    <>
   <svg width="108" height="24" viewBox="0 0 108 24" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.15299 5.408C10.42 3.136 11.053 2 12 2C12.947 2 13.58 3.136 14.847 5.408L15.175 5.996C15.535 6.642 15.715 6.965 15.995 7.178C16.275 7.391 16.625 7.47 17.325 7.628L17.961 7.772C20.421 8.329 21.65 8.607 21.943 9.548C22.235 10.488 21.397 11.469 19.72 13.43L19.286 13.937C18.81 14.494 18.571 14.773 18.464 15.117C18.357 15.462 18.393 15.834 18.465 16.577L18.531 17.254C18.784 19.871 18.911 21.179 18.145 21.76C17.379 22.342 16.227 21.811 13.925 20.751L13.328 20.477C12.674 20.175 12.347 20.025 12 20.025C11.653 20.025 11.326 20.175 10.672 20.477L10.076 20.751C7.77299 21.811 6.62099 22.341 5.85599 21.761C5.08899 21.179 5.21599 19.871 5.46899 17.254L5.53499 16.578C5.60699 15.834 5.64299 15.462 5.53499 15.118C5.42899 14.773 5.18999 14.494 4.71399 13.938L4.27999 13.43C2.60299 11.47 1.76499 10.489 2.05699 9.548C2.34999 8.607 3.57999 8.328 6.03999 7.772L6.67599 7.628C7.37499 7.47 7.72399 7.391 8.00499 7.178C8.28499 6.965 8.46499 6.642 8.82499 5.996L9.15299 5.408Z" fill={dynamicColor}/>
<path d="M93.153 5.408C94.42 3.136 95.053 2 96 2C96.947 2 97.58 3.136 98.847 5.408L99.175 5.996C99.535 6.642 99.715 6.965 99.995 7.178C100.275 7.391 100.625 7.47 101.325 7.628L101.961 7.772C104.421 8.329 105.65 8.607 105.943 9.548C106.235 10.488 105.397 11.469 103.72 13.43L103.286 13.937C102.81 14.494 102.571 14.773 102.464 15.117C102.357 15.462 102.393 15.834 102.465 16.577L102.531 17.254C102.784 19.871 102.911 21.179 102.145 21.76C101.379 22.342 100.227 21.811 97.925 20.751L97.328 20.477C96.674 20.175 96.347 20.025 96 20.025C95.653 20.025 95.326 20.175 94.672 20.477L94.076 20.751C91.773 21.811 90.621 22.341 89.856 21.761C89.089 21.179 89.216 19.871 89.469 17.254L89.535 16.578C89.607 15.834 89.643 15.462 89.535 15.118C89.429 14.773 89.19 14.494 88.714 13.938L88.28 13.43C86.603 11.47 85.765 10.489 86.057 9.548C86.35 8.607 87.58 8.328 90.04 7.772L90.676 7.628C91.375 7.47 91.724 7.391 92.005 7.178C92.285 6.965 92.465 6.642 92.825 5.996L93.153 5.408Z" fill={dynamicColor}/>
<path d="M30.1532 5.408C31.4202 3.136 32.0532 2 33.0002 2C33.9472 2 34.5802 3.136 35.8472 5.408L36.1752 5.996C36.5352 6.642 36.7152 6.965 36.9952 7.178C37.2752 7.391 37.6252 7.47 38.3252 7.628L38.9612 7.772C41.4212 8.329 42.6502 8.607 42.9432 9.548C43.2352 10.488 42.3972 11.469 40.7202 13.43L40.2862 13.937C39.8102 14.494 39.5712 14.773 39.4642 15.117C39.3572 15.462 39.3932 15.834 39.4652 16.577L39.5312 17.254C39.7842 19.871 39.9112 21.179 39.1452 21.76C38.3792 22.342 37.2272 21.811 34.9252 20.751L34.3282 20.477C33.6742 20.175 33.3472 20.025 33.0002 20.025C32.6532 20.025 32.3262 20.175 31.6722 20.477L31.0762 20.751C28.7732 21.811 27.6212 22.341 26.8562 21.761C26.0892 21.179 26.2162 19.871 26.4692 17.254L26.5352 16.578C26.6072 15.834 26.6432 15.462 26.5352 15.118C26.4292 14.773 26.1902 14.494 25.7142 13.938L25.2802 13.43C23.6032 11.47 22.7652 10.489 23.0572 9.548C23.3502 8.607 24.5802 8.328 27.0402 7.772L27.6762 7.628C28.3752 7.47 28.7242 7.391 29.0052 7.178C29.2852 6.965 29.4652 6.642 29.8252 5.996L30.1532 5.408Z" fill={dynamicColor}/>
<path d="M51.153 5.408C52.42 3.136 53.053 2 54 2C54.947 2 55.58 3.136 56.847 5.408L57.175 5.996C57.535 6.642 57.715 6.965 57.995 7.178C58.275 7.391 58.625 7.47 59.325 7.628L59.961 7.772C62.421 8.329 63.65 8.607 63.943 9.548C64.235 10.488 63.397 11.469 61.72 13.43L61.286 13.937C60.81 14.494 60.571 14.773 60.464 15.117C60.357 15.462 60.393 15.834 60.465 16.577L60.531 17.254C60.784 19.871 60.911 21.179 60.145 21.76C59.379 22.342 58.227 21.811 55.925 20.751L55.328 20.477C54.674 20.175 54.347 20.025 54 20.025C53.653 20.025 53.326 20.175 52.672 20.477L52.076 20.751C49.773 21.811 48.621 22.341 47.856 21.761C47.089 21.179 47.216 19.871 47.469 17.254L47.535 16.578C47.607 15.834 47.643 15.462 47.535 15.118C47.429 14.773 47.19 14.494 46.714 13.938L46.28 13.43C44.603 11.47 43.765 10.489 44.057 9.548C44.35 8.607 45.58 8.328 48.04 7.772L48.676 7.628C49.375 7.47 49.724 7.391 50.005 7.178C50.285 6.965 50.465 6.642 50.825 5.996L51.153 5.408Z" fill={dynamicColor}/>
<path d="M72.153 5.408C73.42 3.136 74.053 2 75 2C75.947 2 76.58 3.136 77.847 5.408L78.175 5.996C78.535 6.642 78.715 6.965 78.995 7.178C79.275 7.391 79.625 7.47 80.325 7.628L80.961 7.772C83.421 8.329 84.65 8.607 84.943 9.548C85.235 10.488 84.397 11.469 82.72 13.43L82.286 13.937C81.81 14.494 81.571 14.773 81.464 15.117C81.357 15.462 81.393 15.834 81.465 16.577L81.531 17.254C81.784 19.871 81.911 21.179 81.145 21.76C80.379 22.342 79.227 21.811 76.925 20.751L76.328 20.477C75.674 20.175 75.347 20.025 75 20.025C74.653 20.025 74.326 20.175 73.672 20.477L73.076 20.751C70.773 21.811 69.621 22.341 68.856 21.761C68.089 21.179 68.216 19.871 68.469 17.254L68.535 16.578C68.607 15.834 68.643 15.462 68.535 15.118C68.429 14.773 68.19 14.494 67.714 13.938L67.28 13.43C65.603 11.47 64.765 10.489 65.057 9.548C65.35 8.607 66.58 8.328 69.04 7.772L69.676 7.628C70.375 7.47 70.724 7.391 71.005 7.178C71.285 6.965 71.465 6.642 71.825 5.996L72.153 5.408Z" fill={dynamicColor}/>
</svg>

    </>
  )
}