
export const Editpic=()=>{
    return(
      <>
      <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="15" cy="15" r="15" fill="white"/>
<path d="M12.2422 21.874H8.75C8.58424 21.874 8.42527 21.8081 8.30806 21.6909C8.19085 21.5737 8.125 21.4147 8.125 21.249V17.7568C8.12472 17.6756 8.14044 17.5952 8.17128 17.5201C8.20211 17.445 8.24745 17.3768 8.30469 17.3193L17.6797 7.94427C17.7378 7.88521 17.8072 7.83832 17.8836 7.80631C17.9601 7.7743 18.0421 7.75781 18.125 7.75781C18.2079 7.75781 18.2899 7.7743 18.3664 7.80631C18.4428 7.83832 18.5122 7.88521 18.5703 7.94427L22.0547 11.4286C22.1137 11.4868 22.1606 11.5561 22.1927 11.6326C22.2247 11.709 22.2411 11.7911 22.2411 11.874C22.2411 11.9568 22.2247 12.0389 22.1927 12.1153C22.1606 12.1918 22.1137 12.2611 22.0547 12.3193L12.6797 21.6943C12.6222 21.7515 12.5539 21.7968 12.4788 21.8277C12.4038 21.8585 12.3233 21.8742 12.2422 21.874Z" stroke="#FF8354" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M15.625 10L20 14.375" stroke="#FF8354" strokeWidth="1.25" strokeLinecap="round" strokeLinejoin="round"/>
</svg>
      </>
    )
  }