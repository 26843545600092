import React, { useState, useEffect } from "react";
import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { Alert } from "../../../assets/icons/ALert";
import { Calender } from "../../../assets/icons/Calender";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { objectFiledDataStart } from "../../../redux/slice/objectSlice";
const StripePaymentForm = ({
  SelectPacknext,
  tokenGetter,
  bookingData,
  updateFieldData,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = useState("");
  const [paymentMethod, setPaymentMethod] = useState("card");
  const [loading, setLoading] = useState(false);
  const { learnerDashboardProfileInfo } = useSelector((state) => state.auth);
  const [walletError, setWalletError] = useState(false);
  const cal = useSelector((state) => state.calender.name);

  const handleSubmit = async (event) => {
    if(paymentMethod === "card"){
      setLoading(true);
    }
    updateFieldData("payment_type", paymentMethod);
    event.preventDefault();
    if (paymentMethod === "card") {
      const cardElement = elements.getElement(CardNumberElement);
      const { error, token } = await stripe.createToken(cardElement);

      if (error) {
        setErrorMessage(error.message);
      } else {
        setErrorMessage("");
        const tokennew = token.id;
        tokenGetter(tokennew);
        SelectPacknext();
      }
    } else {
      if (bookingData.amount > learnerDashboardProfileInfo.data.wallet_amount) {
        setWalletError(true);
      } else {
        SelectPacknext();
      }
    }
  };

  const CARD_ELEMENT_OPTIONS = {
    style: {
      base: {
        color: "#303238",
        fontSize: "16px",
        fontFamily: "sans-serif",
        fontSmoothing: "antialiased",
        "::placeholder": {
          color: "#CFD7DF",
        },
      },
      invalid: {
        color: "#e5424d",
        ":focus": {
          color: "#303238",
        },
      },
    },
  };

  useEffect(() => {
    updateFieldData("payment_type", paymentMethod);
  }, [paymentMethod]);

  return (
    <form onSubmit={handleSubmit}>
      <div className="d-flex justify-content-center flex-column form-cd br-10 pb-3 m-auto">
        <div className="cd_head_bg p-3">
          <h5 className="text-center pt-2 progress_head">PAYMENT DETAILS</h5>
        </div>
        <div className="p-3">
          <h6 className="text-indigo progress_sub_head">Payment</h6>
          <div className="bg-grey rounded-3 mt-3 mb-2 p-3">
            <h6 className="progress_sub_head">
              Booking with {bookingData.name_instructor}
            </h6>
            <div className="d-flex">
              <Calender />
              <div className="mt-2 ms-1">
              <p className="confirm_select">    {cal.map((i, index) => {
                          return (
                            <span key={index}>
                              <span key={index}>
                                {i.newtime} {i.newday}, {i.currDate}
                              </span>
                            </span>
                          );
                        })}</p>
              <p className="confirm_select">
                {bookingData.pickup_location} {bookingData.city}{" "}
                {bookingData.postal_code}
              </p>
              </div>
            </div>
            <div>
           
            </div>
          </div>
          <div className="p-2 ps-0 pb-4 d-flex align-items-center gap-1">
            <Alert />
            <span className="alert_pg">
              {" "}
              Cancellation requires 48hrs notice
            </span>
          </div>

          <div className="d-flex justify-content-between mb-2">
            <h6 className="progress_sub_head">Payment Type </h6>
            <div className="payment_method_wrapper">
              <div className="form-check">
                <input
                  type="radio"
                  id="cardRadio"
                  name="paymentMethod"
                  className="form-check-input"
                  value="card"
                  checked={paymentMethod === "card"}
                  onChange={() => setPaymentMethod("card")}
                />
                <label htmlFor="cardRadio" className="form-check-label">
                  Card
                </label>
              </div>
              <div className="form-check">
                <input
                  type="radio"
                  id="walletRadio"
                  name="paymentMethod"
                  className="form-check-input"
                  value="wallet"
                  checked={paymentMethod === "wallet"}
                  onChange={() => setPaymentMethod("wallet")}
                />
                <label htmlFor="walletRadio" className="form-check-label">
                  Wallet
                </label>
              </div>
            </div>
          </div>
          {paymentMethod === "wallet" ? (
            <div className="mb-2 bg-grey rounded-3 p-3">
              <p className="text-indigo m-auto progress_sub_head">
                Wallet Balance : $
                {learnerDashboardProfileInfo?.data.wallet_amount}
              </p>
            </div>
          ) : (
            <div>
              <h6 className="text-indigo progress_sub_head">
                Credit Card Information
              </h6>
              <div className="row">
                <div className="col-md-12 mb-3">
                  <CardNumberElement
                    id="cc-number"
                    className="form-control bg-grey border-0 p-2 p-md-3 payment_step_wrapper_input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-6 mb-3">
                  <CardExpiryElement
                    id="expiry"
                    className="form-control bg-grey border-0 p-2 p-md-3 payment_step_wrapper_input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
                <div className="col-md-6 mb-3">
                  <CardCvcElement
                    id="cvc"
                    className="form-control bg-grey border-0 p-2 p-md-3 payment_step_wrapper_input"
                    options={CARD_ELEMENT_OPTIONS}
                  />
                </div>
              </div>
            </div>
          )}
          {paymentMethod === "card" && (
            <div className="row">
              <div className="col-md-12 error form-group">
                {errorMessage && (
                  <div className="alert-danger alert">{errorMessage}</div>
                )}
              </div>
            </div>
          )}
          {paymentMethod === "wallet" && (
            <div className="row">
              <div className="col-md-12 error form-group">
                {walletError && (
                  <div className="alert-danger alert">
                    Your wallet amount is not sufficient
                  </div>
                )}
              </div>
            </div>
          )}
          <div className="d-flex justify-content-center">
            <Button
              type="submit"
              className="orange_btn border-0 br-10 pay_btn"
              disabled={loading}
              onClick={(e) => handleSubmit(e)}
            >
              {loading ? (
                <div
                  className="spinner-border spinner-border-sm text-light"
                  role="status"
                ></div>
              ) : (
                `Pay $${bookingData.amount}`
              )}
            </Button>
          </div>
        </div>
      </div>
    </form>
  );
};

export default StripePaymentForm;
