import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import discChooseOne from "../../../assets/images/newpages/discChooseOne.png";
import discChooseTwo from "../../../assets/images/newpages/discChooseTwo.png";
import discChooseThree from "../../../assets/images/newpages/discChooseThree.png";
import discChooseFour from "../../../assets/images/newpages/discChooseFour.png";
const RediscoverChooseUs = () => {
  return (
    <section className="keyzi_discChoose--wrapper">
      <Container>
        <div className="keyzi_discChoose--inner">
        <h2>Why Choose Us</h2>

          <div className="discChooseCards--wrapper">
            <div className="choose--card">
              <img src={discChooseOne} className="img-fluid" alt="icon" />
              <h5>Personalised Approach</h5>
              <p>
                We recognize that every driver is unique. Our refresher courses
                are tailored to your specific needs, ensuring you get the
                support and instruction that matches your driving goals.
              </p>
            </div>
            <div className="choose--card">
              <img src={discChooseTwo} className="img-fluid" alt="icon" />
              <h5>Expert Instructors</h5>
              <p>
                Our team of friendly, professional instructors brings not just
                expertise but empathy to each lesson. They understand the
                challenges and concerns of returning drivers.
              </p>
            </div>
            <div className="choose--card">
              <img src={discChooseThree} className="img-fluid" alt="icon" />
              <h5>Flexible Scheduling</h5>
              <p>
                Life is busy, and we get that. Our scheduling is flexible,
                allowing you to fit driving lessons into your life, not the
                other way around.
              </p>
            </div>
            <div className="choose--card">
              <img src={discChooseFour} className="img-fluid" alt="icon" />
              <h5>Safety First</h5>
              <p>
                With dual-controlled vehicles and a focus on defensive driving
                techniques, we ensure that safety is paramount, giving you the
                peace of mind to focus on learning.
              </p>
            </div>
          </div>
        </div>
      </Container>
    </section>
  );
};

export default RediscoverChooseUs;
