import { Wallet } from '../../../assets/icons/Wallet';
import InputSelector from '../../inputselector/InputSelector'
import './DashBoardMain.css';
import { CheckCircle } from './../../../assets/icons/CheckCircle';
import { NotePad } from '../../../assets/icons/NotePad';
import { Row, Col, Button } from 'react-bootstrap';
import { CalenderPage } from './Calender';
import { PhoneBlack } from '../../../assets/icons/PhoneBlack';
import { GoogleMap } from './GoogleMap';
import { useDispatch, useSelector } from 'react-redux';
import { Routers } from '../../../routes/Constant';
import { useState,useEffect } from 'react';
import {
  learnerDashboardInfoById,
  learnerDashboardProfileInfoById,
  learnerDashboardUpcomingBookingById
} from "../../../redux/slice/authSlice";
import { useNavigate } from 'react-router-dom';
export const DashBoardMain = () => {
  const navigate = useNavigate();
  const { learnerDashboardInfo } = useSelector((state)=>state?.auth);
  const { upcomingBooking } = useSelector((state)=>state?.auth);
  const data = upcomingBooking?.data[0];
  const { learnerDashboardProfileInfo } = useSelector((state) => state.auth);
  const {user,error} = useSelector((state) => state?.auth);
  const [learnerInfo,setLearnerInfo] = useState({});
  const [prevsUrl,setprevsUrl] = useState(null);
  const dispatch = useDispatch();
    useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(learnerDashboardInfoById(user?.data?.data.id));
        await dispatch(learnerDashboardProfileInfoById(user?.data?.data.id));
        await dispatch(learnerDashboardUpcomingBookingById(user?.data?.data.id));
      } catch (error) {
        console.error('An error occurred while fetching dashboard info:', error);
      }
    };
    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);

  
  useEffect(() => {
    const currentUrl = window.location.href;
    setprevsUrl(currentUrl);
    console.log(currentUrl);
  }, []);


  useEffect(() => {
    if (learnerDashboardInfo) {
      setLearnerInfo(learnerDashboardInfo?.data);
    }
    localStorage.setItem("prevsUrl",prevsUrl)
  }, [learnerDashboardInfo,learnerDashboardProfileInfo]);
  

    const today = new Date();

    function convertDateFormat(inputDateStr) {
      const originalDate = new Date(inputDateStr);
    
      const day = originalDate.toLocaleString("en-us", { weekday: "long" });
      const time = originalDate.toLocaleString("en-us", { hour: "2-digit", minute: "2-digit" });
      const month = originalDate.toLocaleString("en-us", { month: "long" });
      const date = originalDate.getDate();
    
      return `${time} ${day}, ${month} ${date}`;
    }

  
    const daysUntil = (date) => {
      const eventDate = new Date(date);
      const timeDifference = eventDate - today;
      return Math.ceil(timeDifference / (1000 * 3600 * 24));
    };

  return (
    <>
      <section className="dashboard_main">
        <div>
          {/* here this is dashcard section */}
          <Row className="justify-content-between dashboard_inner text-white">
            <Col className="dashboard_card d-flex dashcard_first br-10 align-items-center mt-md-3 ">
              <div>
                <Wallet />
              </div>
              <div className="pt-5">
                <h6 className="dashboard_prosec">Wallet</h6>
                <p className="dashcard_price ">${learnerDashboardProfileInfo?.data.wallet_amount}</p>
              </div>
            </Col>
            <Col className="dashboard_card d-flex br-10 dashcard_Second align-items-center  mt-md-3">
              <div>
                <NotePad />
              </div>
              <div className="pt-5">
                <h6 className="dashboard_prosec">Upcoming</h6>
                <p className="dashcard_price ">{learnerInfo.upcomming_booking_count}</p>
              </div>
            </Col>
            <Col className="dashboard_card d-flex br-10 dashcard_third align-items-center  mt-md-3">
              <div>
                <CheckCircle />
              </div>
              <div className="pt-5">
                <h6 className="dashboard_prosec">Complete</h6>
                <p className="dashcard_price">{learnerInfo.complete_booking_count}</p>
              </div>
            </Col>
          </Row>

          <Row> 
              <div className="input_wrapper_dashboard">
                <h5 className='text-center'>Book Your Instructor</h5>
                  <InputSelector maxWidth="max_width_dashboard" prevsUrl={prevsUrl}/>
              </div>
          </Row>
          {/* here this is Calender section */}
          <Row>
            <Col>
              <CalenderPage />
            </Col>
          </Row>

          {/* booking detail section */}
          {data && 
          <div className="booking_detail bg-white br-10 mt-3 ">
            <Row>
              <Col>
                <div className="d-flex  flex-md-row  flex-column  justify-content-between p-4 align-items-center calender_head">
                  <div className="book_calender">
                    <p className="mb-0 bg-white">Your next booking is in {daysUntil(data?.start)} {`${daysUntil(data?.start) > 1 ? 'days' : 'day' }`}</p>
                  </div>
                  <div className="mt-md-0 mt-3">
                    <Button
                      variant="primary"
                      className="view_btn view_booking_btn me-0 me-sm-3 bg-indigo"
                      onClick={()=>navigate(`/auth/dashboard/booking/bookingDetails/${data?.id}`)}
                    >
                      View Booking
                    </Button>
                    {/* <Button
                      variant="primary"
                      className="view_btn learner_detail_btn "
                    >
                      Learner Details
                    </Button> */}
                  </div>
                </div>
              </Col>
            </Row>
            <Row className="p-4 j book_detail_inner ">
              <Col lg={4}>
                <h6 className="book_head_cont book_less mb-2">{data?.package?.sub_service?.service?.name} - {data?.package?.time} min</h6>
                <p className=" book_time ">
                  {convertDateFormat(data?.start)}
                </p>
                <p className=" about_book_detail">
                  {data?.pickup_location} {data?.city} {data?.postal_code}
                </p>
              </Col>
              <Col lg={4} className="mt-md-0 mt-3">
                <h6 className="book_head_cont book_main mb-2">Instructor</h6>
                <p className=" book_time ">{data?.instructor?.name}</p>
                <p className="about_book_detail">
                  <PhoneBlack />
                  <span className="ps-2">{data?.instructor?.phone}</span>
                </p>
              </Col>
              {/* <Col className="mt-md-0 mt-3">
                <p className="book_head_cont book_main">Parent</p>
                <p className=" book_time ">Ronald Richards</p>
                <p className=" about_book_detail">
                  <PhoneBlack />
                  <span className="ps-2">(252) 555-0126</span>
                </p>
              </Col> */}
            </Row>
          </div>}

          {/* this is a  Google map section */}
          <div>
            <GoogleMap />
          </div>
        </div>
      </section>
    </>
  );
};
