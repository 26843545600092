import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import "./SignUpLearner.css";
import { useState, useEffect } from "react";
import { Bannermain } from "../components/bannermain/Bannermain";
import { SignUpLearnerData } from "../redux/slice/authSlice";
import { useDispatch, useSelector } from "react-redux";
import { Routers } from "../routes/Constant";
import { useNavigate } from "react-router-dom";

import { useForm } from "react-hook-form";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { EyeClosed } from "../assets/icons/EyeClosed";
import { EyeShoow } from "../assets/icons/EyeShoow";
import { LeftArrow } from "../assets/icons/LeftArrow";
import { Link } from "react-router-dom";

function PasswordInput({ label, name, register, errors }) {
  const [showPassword, setShowPassword] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword((prev) => !prev);
  };

  return (
    <>
      <div className="input_block">
        <div className="position-relative">
          <input
            type={showPassword ? "text" : "password"}
            className="input_tag br-10 p-4"
            {...register(name, { required: true })}
            placeholder={`${label}*`}
          />
          <span className="password-toggle" onClick={togglePasswordVisibility}>
            {showPassword ? <EyeShoow /> : <EyeClosed />}
          </span>
        </div>
        {errors[name] && (
          <div className="text-danger m-1">{errors[name].message}</div>
        )}
      </div>
    </>
  );
}

export const SignUpLearner = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [generateDefaultAddress, setGenerateDefaultAddress] = useState(false);
  // const res=useSelector(state=>state.auth.LearnerSign);
  // console.log(res);
  const schema = yup.object().shape({
    first_name: yup.string().required("First Name  is required"),
    last_name: yup.string().required("Last Name is required"),
    email: yup
      .string()
      .required("Email is required")
      .email("Invalid email format"),
    phone: yup.string().required("Phone number is required"),
    notification_checkbox: "",
    condition_checkbox: yup
      .bool()
      .oneOf([true], "Please accept Terms and Conditions to continue"),
    dob: yup.string().required("Date of Birth is required"),
    street: yup.string().required("Street Address is required"),
    street_number: yup.string().required("Street No. is required"),
    suburb: yup.string().required("Suburb is required"),
    state: yup.string().required("State is required"),
    password: yup.string().min(6, "Password must contain at least 6 character"),
    post_code: yup.string().required("Postal Code is required"),
    password_confirmation: yup
      .string()
      .min(6, "Password must contain at least 6 character")
      .oneOf([yup.ref("password")], "Password do not match"),

  });

   const {
    register,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });

 const onSubmitdata = (values) => {
    const { generateDefaultAddress, referal_code, heard_about_us, ...formData } = values;
    if (generateDefaultAddress) {
      formData.address = `${values.street_number} ${values.street}, ${values.suburb}, ${values.post_code}, ${values.state}`;
    } else {
      formData.address = values.address;
    }
    formData.referal_code = referal_code || "";
    formData.heard_about_us = heard_about_us || "";
    console.log(formData);
    dispatch(SignUpLearnerData(formData));
    reset();
    navigate(Routers.Login);
  };

  const { instructors } = useSelector(
    (state) => state.instructorPostalCodeReducer
  );

  const postCode = instructors.data.map((e) => e.pin_code);

  // const [selectedDate, setSelectedDate] = useState("");
  // const currentDate = new Date();
  // const minDate = new Date(
  //   currentDate.getFullYear() - 18,
  //   currentDate.getMonth(),
  //   currentDate.getDate()
  // );
  // const minDateString = minDate.toISOString().split("T")[0];

  // const handleDateChange = (e) => {
  //   setSelectedDate(e.target.value);
  // };

  return (
    <>
      <Bannermain bgconfirm="container-o-bg-clr ">
        <section className="signup_card m-top">
          <h5 className="main_head text-indigo text-center">SIGN UP</h5>
          <h6>
            <Link className="back_Link" to={Routers.Login}>
              <LeftArrow />
              Back
            </Link>
          </h6>
          <Container className="login_container mt-5 login_sec br-10">
            <h5 className="sub_head text-indigo text-center ">
              SIGN UP TO START TAKING DRIVING LESSONS
            </h5>
            <Row>
              <Col>
                <form onSubmit={handleSubmit(onSubmitdata)}>
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        className="input_tag br-10 p-4"
                        {...register("first_name", { required: true })}
                        placeholder="First Name*"
                      />
                      {errors.first_name && (
                        <div className="text-danger m-1">
                          {errors.first_name.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="text"
                        className="input_tag br-10 p-4 "
                        {...register("last_name", { required: true })}
                        placeholder="Last Name*"
                      />
                      {errors.last_name && (
                        <div className="text-danger m-1">
                          {errors.last_name.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <input
                    type="text"
                    placeholder="Date of Birth*(DD/MM/YYYY)"
                    className="input_tag br-10 p-4"
                    {...register("dob", { required: true })}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    // value={selectedDate}
                    // max={minDateString}
                    // onChange={handleDateChange}
                  />
                  {errors.dob && (
                    <div className="text-danger m-1">{errors.dob.message}</div>
                  )}
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        placeholder="Street No.*"
                        className="input_tag br-10 p-4"
                        {...register("street_number", { required: true })}
                      />
                      {errors.street_number && (
                        <div className="text-danger m-1">
                          {errors.street_number.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="text"
                        placeholder="Street Address*"
                        className="input_tag br-10 p-4"
                        {...register("street", { required: true })}
                      />

                      {errors.street && (
                        <div className="text-danger m-1">
                          {errors.street.message}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        placeholder="Suburb*"
                        className="input_tag br-10 p-4 "
                        {...register("suburb", { required: true })}
                      />
                      {errors.suburb && (
                        <div className="text-danger m-1">
                          {errors.suburb.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="text"
                        placeholder="Postal Code*"
                        className="input_tag br-10 p-4 "
                        {...register("post_code", { required: true })}
                      />

                      {errors.post_code && (
                        <div className="text-danger m-1">
                          {errors.post_code.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="input_block">
                    <select
                      className="input_tag form-control select_signup_learner br-10 p-3"
                      name="state"
                      {...register("state")}
                    >
                      <option value="" selected disabled>
                        State
                      </option>
                      <option value="New South Wales">New South Wales</option>
                      <option value="Australian Capital Territory">
                        Australian Capital Territory
                      </option>
                      <option value="Northern Territory">
                        Northern Territory
                      </option>
                      <option value="Queensland">Queensland</option>
                      <option value="South Australia">South Australia</option>
                      <option value="Tasmania">Tasmania</option>
                      <option value="Victoria">Victoria</option>
                      <option value="Western Australia">
                        Western Australia
                      </option>
                    </select>
                  </div>
                  {errors.state && (
                    <div className="text-danger m-1">
                      {errors.state.message}
                    </div>
                  )}
                  <div className="d-flex">
                    <div className="input_block mt-2 me-2">
                      <input
                        type="checkbox"
                        className="form-check-input"
                        {...register("generateDefaultAddress")}
                        id="generate-default-address"
                        onChange={(e) => setGenerateDefaultAddress(e.target.checked)}
                      />
                      <label
                        htmlFor="generate-default-address"
                        className="form-check-label ms-2"
                      >
                        Default Pickup Address is same as given address
                      </label>
                    </div>
                  </div>
                  {generateDefaultAddress ? null : (
                    <div className="input_block">
                      <input
                        type="text"
                        placeholder="Default Pickup Address"
                        className="input_tag br-10 p-4"
                        {...register("address")}
                      />
                      {errors.address && (
                        <div className="text-danger m-1">
                          {errors.address.message}
                        </div>
                      )}
                    </div>)
                  }
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        maxLength="10"
                        placeholder="Phone*"
                        className="input_tag br-10 p-4 me-2"
                        {...register("phone", { required: true })}
                      />
                      {errors.phone && (
                        <div className="text-danger m-1">
                          {errors.phone.message}
                        </div>
                      )}
                    </div>
                    <div className="input_block">
                      <input
                        type="Email"
                        className="input_tag br-10 p-4 "
                        {...register("email", { required: true })}
                        placeholder="Email*"
                      />
                      {errors.email && (
                        <div className="text-danger m-1">
                          {errors.email.message}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="d-block d-sm-flex gap-2">
                    <PasswordInput
                      label="Password"
                      name="password"
                      register={register}
                      errors={errors}
                    />

                    <PasswordInput
                      label="Confirm Password"
                      name="password_confirmation"
                      register={register}
                      errors={errors}
                    />
                  </div>
                  <div className="d-flex">
                    <div className="input_block me-2">
                      <input
                        type="text"
                        placeholder="Referral Code"
                        className="input_tag br-10 p-4"
                        {...register("referal_code")}
                      />
                    </div>
                    <div className="input_block">
                      <select
                        className="input_tag form-control select_signup_learner br-10 p-3"
                        name="heard_about_us"
                        {...register("heard_about_us")}
                      >
                        <option value="" selected disabled>
                          Referral Source
                        </option>
                        <option value="Friend's">Friend's</option>
                        <option value="Social Media">Social Media</option>
                        <option value="Other">Other</option>
                      </select>
                    </div>
                  </div>

                  <div className="form-check checkbox-xl mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="condition_checkbox"
                      {...register("condition_checkbox", { required: true })}
                      id="checkbox-1"
                    />
                    <label
                      type="button"
                      className="form-check-label"
                      htmlFor="checkbox-1"
                    >
                      I agree to the{" "}
                      <span className="text-indigo fw-bold">
                        Terms & Conditions*
                      </span>
                    </label>
                  </div>
                  {errors.condition_checkbox && (
                    <div className="text-danger">
                      {errors.condition_checkbox.message}
                    </div>
                  )}
                  <div className="form-check checkbox-xl mt-3">
                    <input
                      className="form-check-input"
                      type="checkbox"
                      name="notification_checkbox"
                      {...register("notification_checkbox")}
                      id="checkbox-2"
                    />
                    <label
                      type="button"
                      className="form-check-label"
                      htmlFor="checkbox-2"
                    >
                      Receive sms notifications.
                    </label>
                  </div>

                  <div className="d-flex  flex-sm-row  flex-column  justify-content-between inst_learn_btn pt-4">
                    <div>
                      <Button
                        variant="primary"
                        type="submit"
                        className="orange_btn text_manual create_account br-10 "
                      >
                        Create Account
                      </Button>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </Container>
        </section>
      </Bannermain>
    </>
  );
};
