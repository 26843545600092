// import React, { useState, useEffect } from "react";
// import Button from "react-bootstrap/Button";
// import Container from "react-bootstrap/Container";
// import logologin from "../../assets/images/logologin.png";
// import Nav from "react-bootstrap/Nav";
// import Navbar from "react-bootstrap/Navbar";
// import { useLocation } from "react-router-dom";
// import "./Header.css";
// import { Link, useNavigate } from "react-router-dom";
// import { Routers } from "../../routes/Constant";
// import Dropdown from "react-bootstrap/Dropdown";
// import { useSelector } from "react-redux";
// import { logoutLearner } from "../../redux/slice/authSlice";
// import { useDispatch } from "react-redux";
// import { resetAllState } from "../../redux/slice/rootSlice";
// import { message } from "antd";
// import defaultUserPic from '../../assets/images/loginuserpic.png'

// export const Header = ({ update }) => {
//   const dispatch = useDispatch();
//   const navigate = useNavigate();
//   const [stickyClass, setStickyClass] = useState("relative");
//   const location = useLocation();
//   const pathname = useLocation().pathname;
//   const [loggedIn, setLoggedIn] = useState(false);
// const { learnerDashboardProfileInfo } = useSelector((state) => state?.auth);
// const { learnerDashboardProfileUpdate } = useSelector((state) => state?.auth);
//   const [userName, setUserName] = useState("");
//   const [userImage, setUserImage] = useState("");
//   const [navbarOpen, setNavbarOpen] = useState(false);
//   const [showDropdown, setShowDropdown] = useState(false);
//   const token = localStorage.getItem("token");
//   useEffect(() => {
//     if (learnerDashboardProfileInfo) {
//       setUserName(learnerDashboardProfileInfo.data.name.split(" ")[0]);
//       setUserImage(learnerDashboardProfileInfo.data.profile_image);
//     }
//   }, [learnerDashboardProfileInfo]);

//   useEffect(() => {
//     window.addEventListener("scroll", stickNavbar);
//     return () => {
//       window.removeEventListener("scroll", stickNavbar);
//     };
//   });

//   const logoutHandler = () => {
//     dispatch(resetAllState());
//     localStorage.clear();
//     dispatch(logoutLearner(token));
//     localStorage.removeItem("persist:root");
//     setShowDropdown(!showDropdown);
//     message.success({
//       content: "You Logout Successfully",
//       duration: 2,
//     });
//   };

//   const stickNavbar = () => {
//     if (window !== undefined) {
//       let windowHeight = window.scrollY;
//       if (windowHeight > 100 && location.pathname === "/") {
//         setStickyClass("sticky-nav");
//       } else {
//         setStickyClass("relative");
//       }
//     }
//   };

//   const toggleDropdown = () => {
//     setShowDropdown(!showDropdown);
//   };

//   useEffect(() => {
//     if (location.pathname.includes("/auth")) {
//       setLoggedIn(true);
//     } else {
//       setLoggedIn(false);
//     }
//   }, [location]);

//   const handleNavlinkClick = () => {
//     setNavbarOpen(false);
//   };
//   return (
//     <>
//       <header>
//         <Navbar
//           expand="lg"
//           className={`Nav-bg header_size ${
//             loggedIn
//               ? "container-w-bg-clr header_login"
//               : `container-w-bg-clr  ${
//                   !loggedIn && token ? "header_login" : "p-lg-0"
//                 }`
//           } ${stickyClass}`}
//           expanded={navbarOpen}
//         >
//           <Container>
//             {/* {(!loggedIn && !token) && ( */}
//             <Navbar.Toggle onClick={() => setNavbarOpen(!navbarOpen)} className={token && (!loggedIn || loggedIn) && `d-none`}/>
//             {/* // )} */}
//             <Navbar.Brand className="m-0">
//               {/* {loggedIn ? ( */}
//               <Link to={Routers.Home}>
//                 <img
//                   src={logologin}
//                   alt="logo"
//                   className={`float-end keyzi_icon keyzi_icon_nav_login ${token && (!loggedIn || loggedIn) && 'new_login_nav_buttonclass'}`}
//                 />
//               </Link>
//             </Navbar.Brand>

//             {!token && !loggedIn && (
//               <>
//                 <Navbar.Collapse>
//                   <Nav
//                     className={`m-auto mt-0 ${
//                       loggedIn ? "long_navbarfont" : ""
//                     } mb-0 navbar_link`}
//                   >
//                     <Link
//                       to={Routers.findanInstructor}
//                       onClick={handleNavlinkClick}
//                       className={`default_class ${
//                         pathname === Routers.findanInstructor
//                           ? "active_class"
//                           : ""
//                       }`}
//                     >
//                       Find an Instructor
//                     </Link>
//                     <Link
//                       to={Routers.WhyKeyzi}
//                       onClick={handleNavlinkClick}
//                       className={`default_class ${
//                         pathname === Routers.WhyKeyzi ? "active_class" : ""
//                       }`}
//                     >
//                       Why Keyzi
//                     </Link>
//                     <Link
//                       to={Routers.JoinKeyzi}
//                       onClick={handleNavlinkClick}
//                       className={`default_class ${
//                         pathname === Routers.JoinKeyzi ? "active_class" : ""
//                       }`}
//                     >
//                       Join Keyzi
//                     </Link>
//                   </Nav>
//                 </Navbar.Collapse>
//               </>
//             )}
//             <>
//               {loggedIn || token ? (
//                 <div className="login_user_section d-flex align-items-center d-lg-flex">
//                   <div className="profile_wrapper">
//                     <img
//                       src={userImage ? `http://167.71.238.150/keyzi-admin/public${userImage}` : defaultUserPic}
//                       alt="login_user_pic"
//                       className="login_user_profile"
//                     />
//                   </div>
//                   <span className="d-none">{update}</span>
//                   <Dropdown
//                     align="end"
//                     show={showDropdown}
//                     onToggle={toggleDropdown}
//                   >
//                     <Dropdown.Toggle
//                       className={`login_dropdown ${
//                         !loggedIn && "home_color_text"
//                       }`}
//                       variant="success"
//                       id="dropdown-basic"
//                       onClick={toggleDropdown}
//                     >
//                       {userName}
//                     </Dropdown.Toggle>

//                     <Dropdown.Menu>
//                     <Link
//                         className="drop_popup"
//                         to={Routers.Dashboard}
//                         onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
//                       >
//                         Dashboard
//                       </Link>
//                       <Dropdown.Divider />
//                       <Link
//                         className="drop_popup"
//                         to={Routers.Profile}
//                         onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
//                       >
//                         My Profile
//                       </Link>
//                       <Dropdown.Divider />
//                       <Link
//                         onClick={logoutHandler}
//                         to={Routers.Login}
//                         className="drop_popup"
//                       >
//                         Log Out
//                       </Link>
//                     </Dropdown.Menu>
//                   </Dropdown>
//                 </div>
//               ) : (
//                 <Button
//                   className="loginheader_btn d-lg-block br-10"
//                   onClick={() => {
//                     navigate(Routers.Login);
//                   }}
//                 >
//                   Login
//                 </Button>
//               )}
//             </>
//           </Container>
//         </Navbar>
//       </header>
//     </>
//   );
// };

import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import logo from "../../assets/images/logo.png";
import { useDispatch } from "react-redux";
import { resetAllState } from "../../redux/slice/rootSlice";
import "./Header.css";
import { useLocation } from "react-router-dom";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../routes/Constant";
import defaultUserPic from "../../assets/images/loginuserpic.png";
import Button from "react-bootstrap/Button";
import Dropdown from "react-bootstrap/Dropdown";
import { message } from "antd";
import { logoutLearner } from "../../redux/slice/authSlice";
import { useSelector } from "react-redux";

export const Header = ({ update }) => {
  const currentUrl = window.location.href;
  const containsCorporateSiteone = currentUrl.includes("corporatesiteone");
  const containsCorporateSitetwo = currentUrl.includes("corporatesitetwo");
  console.log(update);
  const dispatch = useDispatch();
  const [hamShow, setHamShow] = useState(false);
  const [stickyClass, setStickyClass] = useState("relative");
  const location = useLocation();
  const pathname = useLocation().pathname;
  const navigate = useNavigate();
  const [loggedIn, setLoggedIn] = useState(false);
  const [userImage, setUserImage] = useState("");
  const [showDropdown, setShowDropdown] = useState(false);
  const token = localStorage.getItem("token");
  const [userName, setUserName] = useState("");
  const { learnerDashboardProfileInfo } = useSelector((state) => state?.auth);
  const { learnerDashboardProfileUpdate } = useSelector((state) => state?.auth);
  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      if (windowHeight > 100 ) {
        setStickyClass("sticky-nav");
      } else {
        setStickyClass("position-relative");
      }
    }
  };

  const toggleDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => {
      window.removeEventListener("scroll", stickNavbar);
    };
  });

  useEffect(() => {
    if (learnerDashboardProfileInfo) {
      setUserName(learnerDashboardProfileInfo.data.name.split(" ")[0]);
      setUserImage(learnerDashboardProfileInfo.data.profile_image);
    }
  }, [learnerDashboardProfileInfo]);

  const logoutHandler = () => {
    dispatch(resetAllState());
    localStorage.clear();
    dispatch(logoutLearner(token));
    localStorage.removeItem("persist:root");
    setShowDropdown(!showDropdown);
    message.success({
      content: "You Logout Successfully",
      duration: 2,
    });
  };
  return (
    <header>
      {containsCorporateSitetwo ? (
        <nav className={`keyzi_navbar--wrapper-white ${stickyClass}`}>
          <Container>
            <div className="keyzi_navbar--inner">
              <div className="keyzi_logo--link">
                <h1>
                  <Link to={Routers.Home}>keyzi</Link>
                </h1>
              </div>
                <div
                  className={`${
                    containsCorporateSiteone
                      ? "keyzi_navList--listCsTwo"
                      : "keyzi_navLink--list"
                  }   ${hamShow ? "keyzi_navLink--list-show" : ""}`}
                >
                  <ul>
                    <li>
                      <Link to={Routers.Home}>Driving Lessons</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Test Packages</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Gift Vouchers </Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Pricing</Link>
                    </li>
                    <li className="hide2-class">
                    {loggedIn || token ? (
                        <div className="login_user_section d-flex align-items-center d-lg-flex">
                          <div className="profile_wrapper">
                            <img
                              src={
                                userImage
                                  ? `http://167.71.238.150/keyzi-admin/public${userImage}`
                                  : defaultUserPic
                              }
                              alt="login_user_pic"
                              className="login_user_profile"
                            />
                          </div>
                          <span className="d-none">{update}</span>
                          <Dropdown
                            align="end"
                            show={showDropdown}
                            onToggle={toggleDropdown}
                          >
                            <Dropdown.Toggle
                              className={`login_dropdown ${
                                !loggedIn && "home_color_text"
                              }`}
                              variant="success"
                              id="dropdown-basic"
                              onClick={toggleDropdown}
                            >
                              {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="drop_popup"
                                to={Routers.Dashboard}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                Dashboard
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                className="drop_popup"
                                to={Routers.Profile}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                My Profile
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                onClick={logoutHandler}
                                to={Routers.Login}
                                className="drop_popup"
                              >
                                Log Out
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <Button
                          className="loginheader_btn d-lg-block br-10"
                          onClick={() => {
                            navigate(Routers.Login);
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
                <div
                  className={`keyzi_navLink--list  ${
                    hamShow ? "keyzi_navLink--list-show hide-class" : ""
                  }`}
                >
                  <ul>
                    <li>
                    {loggedIn || token ? (
                        <div className="login_user_section d-flex align-items-center d-lg-flex">
                          <div className="profile_wrapper">
                            <img
                              src={
                                userImage
                                  ? `http://167.71.238.150/keyzi-admin/public${userImage}`
                                  : defaultUserPic
                              }
                              alt="login_user_pic"
                              className="login_user_profile"
                            />
                          </div>
                          <span className="d-none">{update}</span>
                          <Dropdown
                            align="end"
                            show={showDropdown}
                            onToggle={toggleDropdown}
                          >
                            <Dropdown.Toggle
                              className={`login_dropdown ${
                                !loggedIn && "home_color_text"
                              }`}
                              variant="success"
                              id="dropdown-basic"
                              onClick={toggleDropdown}
                            >
                              {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="drop_popup"
                                to={Routers.Dashboard}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                Dashboard
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                className="drop_popup"
                                to={Routers.Profile}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                My Profile
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                onClick={logoutHandler}
                                to={Routers.Login}
                                className="drop_popup"
                              >
                                Log Out
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <Button
                          className="loginheader_btn d-lg-block br-10"
                          onClick={() => {
                            navigate(Routers.Login);
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              
              <div
                className="hamburger hamburger_white"
                onClick={() => {
                  setHamShow(!hamShow);
                }}
              >
                <div className="hb-line"></div>
                <div className="hb-line"></div>
                <div className="hb-line"></div>
              </div>
            </div>
          </Container>
        </nav>
      ) : (
        <nav className={`keyzi_navbar--wrapper ${stickyClass}`}>
          <Container>
            <div className="keyzi_navbar--inner">
              <div className="keyzi_logo--link">
                <h1>
                  <Link to={Routers.Home}>keyzi</Link>
                </h1>
              </div>
              {containsCorporateSiteone && (
                <div
                  className={`${
                    containsCorporateSiteone
                      ? "keyzi_navList--listCsTwo"
                      : "keyzi_navLink--list"
                  }   ${hamShow ? "keyzi_navLink--list-show" : ""}`}
                >
                  <ul >
                    <li>
                      <Link to={Routers.Home}>Home</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Local Instructors</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Book A Lesson</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Reffer A Friend</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Testimonials</Link>
                    </li>
                    <li>
                    {loggedIn || token ? (
                        <div className="login_user_section d-flex align-items-center d-lg-flex">
                          <div className="profile_wrapper">
                            <img
                              src={
                                userImage
                                  ? `http://167.71.238.150/keyzi-admin/public${userImage}`
                                  : defaultUserPic
                              }
                              alt="login_user_pic"
                              className="login_user_profile"
                            />
                          </div>
                          <span className="d-none">{update}</span>
                          <Dropdown
                            align="end"
                            show={showDropdown}
                            onToggle={toggleDropdown}
                          >
                            <Dropdown.Toggle
                              className={`login_dropdown ${
                                !loggedIn && "home_color_text"
                              }`}
                              variant="success"
                              id="dropdown-basic"
                              onClick={toggleDropdown}
                            >
                              {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="drop_popup"
                                to={Routers.Dashboard}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                Dashboard
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                className="drop_popup"
                                to={Routers.Profile}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                My Profile
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                onClick={logoutHandler}
                                to={Routers.Login}
                                className="drop_popup"
                              >
                                Log Out
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <Button
                          className="loginheader_btn  br-10"
                          onClick={() => {
                            navigate(Routers.Login);
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              )}
              {containsCorporateSiteone ? (
                <div
                className="keyzi_navLink--list">
                  <ul>
                    <li>
                    {loggedIn || token ? (
                        <div className="login_user_section d-flex align-items-center d-lg-flex">
                          <div className="profile_wrapper">
                            <img
                              src={
                                userImage
                                  ? `http://167.71.238.150/keyzi-admin/public${userImage}`
                                  : defaultUserPic
                              }
                              alt="login_user_pic"
                              className="login_user_profile"
                            />
                          </div>
                          <span className="d-none">{update}</span>
                          <Dropdown
                            align="end"
                            show={showDropdown}
                            onToggle={toggleDropdown}
                          >
                            <Dropdown.Toggle
                              className={`login_dropdown ${
                                !loggedIn && "home_color_text"
                              }`}
                              variant="success"
                              id="dropdown-basic"
                              onClick={toggleDropdown}
                            >
                              {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="drop_popup"
                                to={Routers.Dashboard}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                Dashboard
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                className="drop_popup"
                                to={Routers.Profile}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                My Profile
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                onClick={logoutHandler}
                                to={Routers.Login}
                                className="drop_popup"
                              >
                                Log Out
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <Button
                          className="loginheader_btn d-lg-block br-10"
                          onClick={() => {
                            navigate(Routers.Login);
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              ) : (
                <div
                  className={`keyzi_navLink--list ${
                    hamShow ? "keyzi_navLink--list-show" : ""
                  }`}
                >
                  <ul>
                    <li>
                      <Link to={Routers.Home}>Home</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Instructors</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Lessons</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Reffer A Friend</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Packages</Link>
                    </li>
                    <li>
                      <Link to={Routers.Home}>Testimonials</Link>
                    </li>
                    <li>
                      {loggedIn || token ? (
                        <div className="login_user_section d-flex align-items-center d-lg-flex">
                          <div className="profile_wrapper">
                            <img
                              src={
                                userImage
                                  ? `http://167.71.238.150/keyzi-admin/public${userImage}`
                                  : defaultUserPic
                              }
                              alt="login_user_pic"
                              className="login_user_profile"
                            />
                          </div>
                          <span className="d-none">{update}</span>
                          <Dropdown
                            align="end"
                            show={showDropdown}
                            onToggle={toggleDropdown}
                          >
                            <Dropdown.Toggle
                              className={`login_dropdown ${
                                !loggedIn && "home_color_text"
                              }`}
                              variant="success"
                              id="dropdown-basic"
                              onClick={toggleDropdown}
                            >
                              {userName}
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              <Link
                                className="drop_popup"
                                to={Routers.Dashboard}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                Dashboard
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                className="drop_popup"
                                to={Routers.Profile}
                                onClick={toggleDropdown} // Close the dropdown when clicking on "My Account"
                              >
                                My Profile
                              </Link>
                              <Dropdown.Divider />
                              <Link
                                onClick={logoutHandler}
                                to={Routers.Login}
                                className="drop_popup"
                              >
                                Log Out
                              </Link>
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      ) : (
                        <Button
                          className="loginheader_btn d-lg-block br-10"
                          onClick={() => {
                            navigate(Routers.Login);
                          }}
                        >
                          Login
                        </Button>
                      )}
                    </li>
                  </ul>
                </div>
              )}
              <div
                className="hamburger"
                onClick={() => {
                  setHamShow(!hamShow);
                }}
              >
                <div className="hb-line"></div>
                <div className="hb-line"></div>
                <div className="hb-line"></div>
              </div>
            </div>
          </Container>
        </nav>
      )}
    </header>
  );
};
