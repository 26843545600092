import React from "react";
import mind from "../../../assets/images/newpages/mind.png"
import road from "../../../assets/images/newpages/road.png"

const Mind = () => {
  return (
    <section className="keyzi_mind--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner">
          <h2>Introducing Our Tailored Driving Test Packages</h2>
          <h5>Designed with YOU in Mind</h5>
          <p>
            Embarking on the journey to earn your driver’s licence is filled
            with excitement and understandably, a bit of anxiety. It’s a
            significant milestone, symbolising freedom, independence, and a
            brand-new chapter in life. But for many, the thought of sitting the
            driving test intertwines dreams with nerves.{" "}
          </p>
          <p>
            What if you could face your driving test wrapped in confidence,
            fully prepared, and supported every step of the way?
          </p>
          <p>For just $225, we offer more than just a driving test package.</p>

          <p>
            We provide peace of mind, preparation, and the personal touch needed
            to turn your driving aspirations into reality.{" "}
          </p>

          <p>
            Our package is meticulously crafted to address every learner’s fears
            and ensure you walk into your driving test feeling confident,
            prepared, and calm.
          </p>
          <img src={road} className="mind-road" alt="Car" />
          <img src={mind} className="mind-car" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default Mind;
