export const Wallet=()=>{
return(
  <>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.375 15V45C9.375 45.9946 9.77009 46.9484 10.4733 47.6516C11.1766 48.3549 12.1304 48.75 13.125 48.75H50.625C51.1223 48.75 51.5992 48.5525 51.9508 48.2008C52.3025 47.8492 52.5 47.3723 52.5 46.875V20.625C52.5 20.1277 52.3025 19.6508 51.9508 19.2992C51.5992 18.9475 51.1223 18.75 50.625 18.75H13.125C12.1304 18.75 11.1766 18.3549 10.4733 17.6516C9.77009 16.9484 9.375 15.9946 9.375 15ZM9.375 15C9.375 14.0054 9.77009 13.0516 10.4733 12.3483C11.1766 11.6451 12.1304 11.25 13.125 11.25H45" stroke="white" strokeWidth="3.75" strokeLinecap="round" strokeLinejoin="round"/>
<path d="M42.1875 36.5625C43.7408 36.5625 45 35.3033 45 33.75C45 32.1967 43.7408 30.9375 42.1875 30.9375C40.6342 30.9375 39.375 32.1967 39.375 33.75C39.375 35.3033 40.6342 36.5625 42.1875 36.5625Z" fill="white"/>
</svg>

  </>
)
}