import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { LandingPageReferalId } from "../redux/slice/authSlice";

export const LandingPage = () => {
  const dispatch = useDispatch();
  const { referralId } = useParams();
  const { landingPage, error } = useSelector((state) => state.auth);
  console.log(landingPage);

  useEffect(() => {
    dispatch(LandingPageReferalId(referralId));
  }, [dispatch, referralId]);

  if (landingPage) {
    return <div>Affiliate Home Page</div>;
  } else if (error) {
    return <div>Error: {error}</div>;
  } else {
    return <div>URL not found</div>;
  }
};
