

import { call, put, takeLatest } from 'redux-saga/effects';
import {  fetchInstructorsPostalCode,
  fetchInstructorsPostalCodeSuccess,
  fetchInstructorsPostalCodeFailure } from '../slice/postalSlice';
import { postalCodeAPI } from '../../services/instructorsAPI';

function* fetchInstructorsPostalCodeSaga() {
  try {
    const response = yield call(postalCodeAPI);
    yield put(fetchInstructorsPostalCodeSuccess(response.data));
  } catch (error) {
    yield put(fetchInstructorsPostalCodeFailure(error.message));
  }
}

export default function* instructorsPostalCodeSaga() {
  yield takeLatest(fetchInstructorsPostalCode.type, fetchInstructorsPostalCodeSaga);
}