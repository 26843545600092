import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import rediscover from "../../../assets/images/newpages/rediscover-path.jpg";

const RediscoverPath = () => {
  return (
    <div className="keyzi_rediscoverPath--wrapper">
      <Container>
        <div className="keyzi_rediscoverPath--inner">
          <Row>
            <Col lg={6}>
              <div className="rediscover--leftSide">
                <h4>Rekindling Your Drive: The Path to Regaining Confidence</h4>
                <p>
                  <span>Embracing the Need for a Refresher: </span> It's a truth
                  universally acknowledged that any skill not regularly
                  practised can fade—including driving. But here's the good
                  news: it's a skill that can be quickly reawakened with the
                  right guidance. Refresher driving lessons are your bridge back
                  to the road, tailored to meet you exactly where you are.
                </p>
                <img src={rediscover} className="img-fluid" alt="" />
              </div>
            </Col>
            <Col lg={6}>
              <div className="rediscover--rightSide">
                <ul>
                  <li>
                    <p>
                      <span>Time And Stress Saved: </span>Balancing work, life,
                      and teaching your teen to drive can be overwhelming. Let’s
                      take the instruction wheel. You’ll save time and avoid the
                      potential stress and strain on your relationship that
                      teaching driving can sometimes cause.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Safety Comes First: </span>Our dual control systems
                      allow our instructors to take control in difficult
                      situations, ensuring the safety of your teen at every
                      turn. This feature addresses the paramount concern of
                      every parent - keeping their child safe.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Updated and comprehensive Curriculum: </span>Our
                      lessons are always current with the latest road rules and
                      test requirements. We provide a structured learning
                      experience that covers everything from the basics to
                      advanced driving techniques, preparing your teen for not
                      just the test, but for a lifetime of safe driving.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Cost-Effective Learning Paths: </span>Investing in
                      professional driving lessons might seem like an added
                      expense, but it’s a cost-effective choice in the long run.
                      Our bulk lesson packages offer savings, ensuring your teen
                      receives comprehensive instruction without cutting any
                      corners. Plus, our trained professionals can significantly
                      reduce the wear and tear on your family car - and
                      potentially your nerves!
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Support Every Step Of The Way: </span>We understand
                      the rollercoaster of emotions that comes with this
                      journey. Our team is here to support not just your teen
                      but you as well. From the first lesson to the day they
                      pass their test, and beyond, we're with you every mile of
                      the journey.
                    </p>
                  </li>
                  <li>
                    <p>
                      <span>Support Every Step Of The Way: </span>We understand
                      the rollercoaster of emotions that comes with this
                      journey. Our team is here to support not just your teen
                      but you as well. From the first lesson to the day they
                      pass their test, and beyond, we're with you every mile of
                      the journey.
                    </p>
                  </li>
                </ul>
              </div>
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default RediscoverPath;
