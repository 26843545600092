import React from 'react'
import { Container } from 'react-bootstrap'
import InputSelector from "../inputselector/InputSelector"
import "./BannerCsOne.css"
export const BannerCsOne = () => {
  const currentUrl = window.location.href;
  const containsCorporateSiteone = currentUrl.includes("corporatesiteone");
  const containsCorporateSitetwo = currentUrl.includes("corporatesitetwo");
  return (
    <section className={containsCorporateSitetwo ? "bannercstwo--wrapper" : "bannercsone--wrapper" }>
      <Container>
        <div className="bannercsone--inner">
          <div className={containsCorporateSitetwo ? "cstwo--inputSelector" : "csone--inputSelector" }>
            <h1>Where do you need driving lessons?</h1>
              <InputSelector/>
          </div>
        </div>
      </Container>
    </section>
  )
}
