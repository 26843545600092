import React from 'react'
import banner from "../../../assets/images/newpages/unlock-banner.png"
const Banner = () => {
  return (
    <section className="keyzi_conquer--wrapper keyzi_unlock--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
              <h3>Unlock The Road To Success</h3>
              <p>A Parent’s Guide To Empowering Your Teen Driver</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side">
                <img src={banner} alt="Car" className='img-fluid'/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default Banner