import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import banner from "../../../assets/images/newpages/away-banner.jpg"
import visa from "../../../assets/images/visa.png"
import mastercard from "../../../assets/images/master-card.png"
import eftops from "../../../assets/images/eftops.png"
import InputSelector from "../../inputselector/InputSelector"
const Quite = () => {
  return (
    <>
    <section className="keyzi_quite--wrapper">
    <Container>
    <div className='keyzi_quite--inner'>
      <h2>Not Quite There Yet?</h2>
      <p>If the thought of sitting the driving test still sends a wave of nerves your way, we've got you covered. Explore our standard driving school packages, designed to gently guide you from learner to confident driver. With our expert instruction, you'll gain the skills and assurance to not just pass your test, but to thrive on the roads.</p>
    </div>
    </Container>
  </section>
   <section className="keyzi_clickAway--wrapper">
    <div className='click_away--banner'></div>
   <Container>
   <div className='keyzi_clickAway--inner'>
     <Row>
      <Col lg={6}><p>Embrace the journey to your driving test with a partner who understands, supports, and believes in you. Our driving test packages are more than a service; they're a stepping stone to your independence. Book now and transform anxiety into achievement.</p>
     <p>Let's Drive Towards Your Success Together - because we believe in turning your driving dreams into reality with every mile.</p></Col>
      <Col lg={6}>
          <div className="newPage--InputSelector">
          <h3>Your Confidence Is Just A Click Away</h3>
        <InputSelector label="Instructor Search"/>
        <div className="fp_weaccept">
                <h4>We Accept</h4>
                <img src={visa} className="img-fluid " alt="Visa" />
                <img src={mastercard} className="img-fluid master_card" alt="Master Card" />
                <img src={eftops} className="img-fluid " alt="Eftops" />
            </div>
          </div>
      </Col>
     </Row>
   </div>
   </Container>
 </section>
    </>
  )
}

export default Quite