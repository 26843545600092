import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import oneStep from "../../../assets/images/newpages/oneStep.jpg";
import twoStep from "../../../assets/images/newpages/twoStep.jpg";
import threeStep from "../../../assets/images/newpages/threeStep.jpg";
import fourStep from "../../../assets/images/newpages/fourStep.jpg";
import fiveStep from "../../../assets/images/newpages/fiveStep.jpg";
import sixStep from "../../../assets/images/newpages/sixStep.jpg";

const EmbarkSteps = () => {
  return (
    <>
      <section className="keyzi_embarkStep--wrapper">
        <Container>
          <div className="keyzi_embarkStep--inner">
            <div className="embarkStep_cards--wrapper">
              <Row>
                <div class="road">
                  <div class="lane"></div>
                </div>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={oneStep} className="img-fluid" alt="icon" />
                      <h5>
                        Kickstart Your Driving Adventure - Get Your Learner’s
                        Permit
                      </h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>Hit the Books:</span> Dive into the road rules
                        handbook. It's your first step to understanding the
                        language of the roads.
                      </p>
                      <p>
                        <span>Ace Your Tests:</span> Show off what you've
                        learned in a theory test, make sure your eyes are up to
                        the task with an eyesight test, and share any medical
                        conditions that could affect your driving.
                      </p>
                      <p>
                        <span>Grab Your Permit and Logbook:</span> Passing your
                        tests means you get your learner's permit. You'll also
                        receive a logbook to start tracking your driving
                        journey.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={twoStep} className="img-fluid" alt="icon" />
                      <h5>Take The Wheel - Learn To Drive</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>Practice Makes Perfect:</span> Whether it's with a
                        seasoned driving instructor or a trusted adult, start
                        clocking up those driving hours. Remember, every minute
                        behind the wheel is a step closer to your goal.
                      </p>
                      <p>
                        <span>Log Your Adventures:</span> Keep a detailed record
                        of your driving experiences, especially those
                        challenging drives at night or in the rain. You need at
                        least 50 hours, including 5 hours under the stars.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={threeStep} className="img-fluid" alt="icon" />
                      <h5>
                        Sharpen Your Road Sense - The Hazard Perception Test
                        (HPT)
                      </h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>Ready for a Challenge:</span> After at least 6
                        months with your Ls, it's time to test your hazard
                        perception skills. This computer-based test is like a
                        virtual drive where you'll need to spot and respond to
                        hazards. roads.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
            <div className="embarkStep_cards--wrapper mb-0">
              <Row>
              <div class="road">
                  <div class="lane"></div>
                </div>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={fourStep} className="img-fluid" alt="icon" />
                      <h5>Refine Your Skills - Keep Practising</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>Beyond the Basics:</span>Use this time to hone
                        your driving skills further. Each drive is an
                        opportunity to become a more confident and competent
                        driver, ready for the final test.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={fiveStep} className="img-fluid" alt="icon" />
                      <h5>Show What You’ve Got - Pass Your Driving Test</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>Book Your Moment:</span>Schedule your practical
                        driving assessment. It's the big day where you'll show
                        you can safely and confidently handle the car on your
                        own.
                      </p>
                      <p>
                        <span>Test Time:</span> Arrive with time to spare,
                        logbook in hand, ready to demonstrate your driving
                        knowledge and skills. This is your moment to shine and
                        prove you're ready for the road.
                      </p>
                    </div>
                  </div>
                </Col>
                <Col lg={4}>
                  <div className="embarkStep--card">
                    <div className="card-head">
                      <img src={sixStep} className="img-fluid" alt="icon" />
                      <h5>Celebrate Your Freedom - Get Your P Plates</h5>
                    </div>
                    <div className="card-body">
                      <p>
                        <span>You Did It!</span>
                      </p>
                      <p>
                        Passing your test is a huge achievement. Now, you'll get
                        your photo taken and receive your provisional licence,
                        complete with P plates.
                      </p>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Container>
      </section>
    </>
  );
};

export default EmbarkSteps;
