import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { FormLabel } from 'react-bootstrap'
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import 'bootstrap-icons/font/bootstrap-icons.css';
// import { useState } from 'react';
import bootstrap5Plugin from '@fullcalendar/bootstrap5';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import '../Progress/checkavailability/CheckAvailability.css';
import '../../components/dashboard/dashboardmain/Calender.css';
import { CalenderAvailabilityData} from '../../redux/slice/CalenderSlice';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect,useRef } from 'react';

export const CalendarModal= ({showCalendar,id,setShowCalendar}) => {
  const calendarRef = useRef();
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(CalenderAvailabilityData(id))
  },[dispatch]);
  const Availability=useSelector(state=>state.calender.calender);
  const Events= Availability.calendar_data;
  function mobileCheck() {
    if (window.innerWidth < 568) {
      return true;
    } else {
      return false;
    }
  }

  const handleDate = (info) => {
    const setCalenderTime = new Date(info.date.toString());
    const newtime = setCalenderTime
      .toLocaleString('en-US', {
        hour: 'numeric',
        minute: 'numeric',
        hour12: true,
      })
      .toLocaleLowerCase();

    const newday = setCalenderTime.toLocaleDateString('locale', {
      weekday: 'long',
    });
    const currDate = setCalenderTime.toLocaleDateString('en-US', {
      month: 'long',
      day: '2-digit',
    });

  };

  // const now = new Date(); // Current date and time
  // const upcomingEvents = Events.filter((event) => {
  //   const eventStart = new Date(event.start); // Assuming the event has a 'start' property with the date and time
  //   return eventStart >= now; // Only include events that start in the future or at the current time
  // });

  const handleTodayButtonClick = () => {
    if (calendarRef.current) {
      const currentDate = new Date();
      calendarRef.current.getApi().gotoDate(currentDate);
    }
  };

  return (
    <>
     <Modal show={showCalendar}  className='check_availability_Sec'>
     <Modal.Body>
     <section className=" calender_sec m-bot">
    <Container>
          <Row>
            <Col>
              <div className=" check_availability">
                <div className="d-flex justify-content-between p-4 calender_head ">
                  <div className="book_calender bg-white">
                    <p className="mb-0">Booking Calendar</p>
                  </div>
                  <div className="view_btn_sec">
                    <Button
                      variant="primary"
                      className="view_btn view_booking_btn bg-indigo  "
                      onClick={()=>setShowCalendar(false)}
                    >
                      Close
                    </Button>
                  </div>
                </div>
                <div className="p-4">
                  <FullCalendar
                  ref={calendarRef}
                    id="calender"
                    initialView={mobileCheck() ? 'timeGridDay' : 'timeGridWeek'}
                    plugins={[
                      dayGridPlugin,
                      bootstrap5Plugin,
                      timeGridPlugin,
                      listPlugin,
                      interactionPlugin,
                    ]}
                    customButtons={{
                      myCustomButton: {
                        text: 'Today',
                        click: handleTodayButtonClick,
                      },
                    }}
                    editable={false}
                    events={Events}
                    slotMinTime="09:00"
                    dateClick={handleDate}
                    selectable={true}
                    allDaySlot={false}
                    expandRows={true}
                    eventBackgroundColor="green"
                    eventColor="green"
                    eventTextColor="white"
                    contentHeight={'500px'}
                    // this this the column header section
                    dayHeaderContent={({ date }) => {
                      const dayName = date.toLocaleDateString('locale', {
                        weekday: 'short',
                      });
                      const newdate = date.getDate();
                      const currentDate = new Date().getDate();

                      return (
                        <>
                          <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                          >
                            <div
                              style={{
                                display: 'flex',
                                flexDirection: 'column',
                              }}
                              className={`pt-2 ${
                                currentDate === newdate ? '#3599E7' : 'black'
                              }`}
                            >
                              {dayName}
                            </div>
                            <span
                              className={`pt-1 ${
                                currentDate === newdate ? 'current_Date' : ''
                              }`}
                              style={{
                                width: '25px',
                                height: '25px',
                                borderRadius: '50%',
                              }}
                            >
                              {newdate}
                            </span>
                          </div>
                        </>
                      );
                    }}
                    headerToolbar={{
                      left: 'myCustomButton prev next',
                      center: 'title',
                      right: "timeGridWeek",
                    }}
                    themeSystem="bootstrap5"
                  />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
        </Modal.Body> 
    
      </Modal>
    </>
  );
};
