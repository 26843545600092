import React from "react";
import road from "../../../assets/images/newpages/road.png";

const NavigatingContent = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner text-center">
          <p>
          Australia's vast landscapes and diverse cities offer unique driving experiences that are reflected in the varied licensing processes across its states and territories. Whether you're starting your driving journey, converting an international licence, or refreshing your skills, understanding the specific requirements of your region is key. Here's a guide that highlights what makes each area unique and where you can find more information to navigate your path to driving independence.
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default NavigatingContent;
