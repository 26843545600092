import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/esm/Button';
import "../login/Login.css"
import { RightArrow } from '../../assets/icons/RightArrow';
import { Bannermain } from '../bannermain/Bannermain';
import { Routers } from '../../routes/Constant';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

export const ResetPassword= () => {
  const navigate = useNavigate();
  const [resetvalue,setResetValue]=useState({
    newPassword:"",
    confirmPassword:""
  })

  const handleReset=(e)=>{
  const{name,value}=e.target;
  setResetValue((pre)=>{
    return {...pre,[name]:value}
      })
      console.log(resetvalue);
  }
  const handleResetSubmit=(e)=>{
   e.preventDefault();
   navigate(Routers.Login)
    console.log(resetvalue)
  }
  
  
    return (
      <>
        <Bannermain bgconfirm="container-o-bg-clr">
          <section className="login_card forgot_section">
            <h1 className="main_head text-indigo text-center">
              WELCOME
            </h1>
            <Container className="login_container mt-5">
              <Row className="login_sec br-10">
                <Col lg={6} md={12} className="left_login_Col">
                  <div className="left_login_sec pt-0 pt-lg-2">
                    <h2 className=" sub_light_head  text-light-black left_login_head mb-0">
                      Reset Your 
                      <span className="main_head text-indigo"> Password ?</span>
                    </h2>
                    <p className="sec_text_subhead mb-0 pt-lg-4 pt-0">
                      Join Keyzi today and get started with quick and easy lesson
                      bookings.
                    </p>
                  </div>
                </Col>
                <Col lg={6} md={12}>
                  <div className="login_right pt-3">
                    <h4 className="  text-light-grey login_right_head">Reset Password</h4>
                    <form action="">
                      <Row className="pt-3">
                        <Col>
                          <div className="">
                            <input
                              type="password"
                              placeholder="NewPassword*"
                              className="input_tag br-10 p-4"
                              name="newPassword"
                              value={resetvalue.password}
                              onChange={handleReset}
                            />
                          </div>
                          <div className="pt-4">
                            <input
                              type="password"
                              placeholder="ConfirmPassword*"
                              className="input_tag br-10 p-4"
                              name="confirmPassword"
                              value={resetvalue.password}
                              onChange={handleReset}
                            />
                          </div>
                          <div className="d-flex  flex-sm-row  flex-column  justify-content-between inst_learn_btn pt-4 pt-sm-5">
                            <div>
                              <Button
                                variant="primary"
                                className="orange_btn text_manual bg-orange login_btn br-10 "
                                onClick={handleResetSubmit}
                              >
                               submit
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </form>
                  </div>
                </Col>
              </Row>
            </Container>
          </section>
        </Bannermain>
      </>
    );
  };