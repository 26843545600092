import { call, put, takeLatest } from "redux-saga/effects";
import {
  LandingPageReferalId,
  LandingPageReferalIdSuccess,
  LandingPageReferalIdFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  logoutLearner,
  logoutLearnerSuccess,
  logoutLearnerFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  SignUpLearnerData,
  SignUpLearnerDataFailure,
  SignUpInstructorDataSuccess,
  SignUpInstructorData,
  SignUpInstructorDataFailure,
  SignUpLearnerDataSuccess,
  InstructorPackagesByIdAData,
  InstructorPackagesByIdADataSuccess,
  InstructorPackagesByIdADataFailure,
  learnerDashboardInfoById,
  learnerDashboardInfoByIdSuccess,
  learnerDashboardInfoByIdFailure,
  learnerDashboardProfileInfoById,
  learnerDashboardProfileInfoByIdSuccess,
  learnerDashboardProfileInfoByIdFailure,
  learnerDashboardProfileUpdateById,
  learnerDashboardProfileUpdateByIdSuccess,
  learnerDashboardProfileUpdateByIdFailure,
  learnerDashboardPwdChangeById,
  learnerDashboardPwdChangeByIdSuccess,
  learnerDashboardPwdChangeByIdFailure,
  learnerDashboardUpcomingBookingById,
  learnerDashboardUpcomingBookingByIdSuccess,
  learnerDashboardUpcomingBookingByIdFailure,
  InstructorBookingById,
  InstructorBookingByIdSuccess,
  InstructorBookingByIdFailure,
  LearnerWalletDataById,
  LearnerWalletDataByIdSuccess,
  LearnerWalletDataByIdFailure,
  LearnerBookingDetailById,
  LearnerBookingDetailByIdSuccess,
  LearnerBookingDetailByIdFailure,
  LearnerTransactionDataById,
  LearnerTransactionDataByIdSuccess,
  LearnerTransactionDataByIdFailure,
  BookingDescriptionById,
  BookingDescriptionByIdSuccess,
  BookingDescriptionByIdFailure,
  feedbacklearnersend,
  feedbacklearnersendSuccess,
  feedbacklearnersendFailure,
  cancelBooking,
  cancelBookingSuccess,
  cancelBookingFailure,
  contactusUser,
  contactusUserSuccess,
  contactusUserFailure,
  PackageSubSerivceList,
  PackageSubSerivceListSuccess,
  PackageSubSerivceListFailure,
  updateBookingDateLearner,
  updateBookingDateLearnerSuccess,
  updateBookingDateLearnerFailure
} from "../slice/authSlice";
import {
  LandingPageReferalIdAPI,
  loginauthentication,
  logoutLearnerAPI,
  forgotPasswordAPI,
  SignUpAuthentication,
  SignUpInstructorAuthentication,
  InstructorPackagesByIdAPI,
  learnerDashboardInfoByIdAPI,
  learnerDashboardProfileInfoByIdAPI,
  learnerDashboardProfileUpdateByIdAPI,
  learnerDashboardPwdChangeByIdAPI,
  InstructorBookingByIdAPI,
  BookingDescriptionByIdAPI,
  LearnerWalletDataByIdAPI,
  LearnerBookingDetailByIdAPI,
  LearnerTransactionDataByIdAPI,
  learnerDashboardUpcomingBookingByIdAPI,
  feedbacklearnerAPI,
  cancelBookingAPI,
  contactusAPI,
  PackageSubSerivceListAPI,
  bookingDateUpdateAPI
} from "../../services/instructorsAPI";
import { message } from "antd";
import "../../pages/SignUpLearner.css";

function* loginSaga(action) {
  try {
    const response = yield call(loginauthentication, action.payload);
    yield put(loginSuccess(response));
  } catch (error) {
    yield put(loginFailure(error.message));
    console.log(error);
  }
}

function* logoutLearnerSaga(action) {
  try {
    const response = yield call(logoutLearnerAPI, action.payload);
    console.log(response?.data?.success);
    yield put(logoutLearnerSuccess(response));
  } catch (error) {
    console.log(error)
    yield put(logoutLearnerFailure(error.message));
  }
}



function* forgotPasswordSaga(action) {
  try {
    const response = yield call(forgotPasswordAPI, action.payload);
    const data = response.data;
    yield put(forgotPasswordSuccess(data));
  } catch (error) {
    yield put(forgotPasswordFailure("An error occurred during forgot Password."));
  }
}

function* SignUpLearnerSaga(action) {
  try {
    const response = yield call(SignUpAuthentication, action.payload);
    yield put(SignUpLearnerDataSuccess(response.data));

    message.success({
      content: "Sign up Successfully Please Login",
      duration: 3,
    });
  } catch (error) {
    message.error({
      content: "this email is already exist",
      duration: 3,
    });
    yield put(SignUpLearnerDataFailure("An error occurred during signup learner."));
  }
}

function* SignUpInstructorSaga(action) {
  try {
    const response = yield call(SignUpInstructorAuthentication, action.payload);
    yield put(SignUpInstructorDataSuccess(response.data));

    message.success({
      content: "Sign up Successfully",
      duration: 3,
    });
  } catch (error) {
    message.error({
      content: "this email is already exist",
      duration: 3,
    });
    yield put(SignUpInstructorDataFailure("An error occurred during signup instructor."));
  }
}

function* InstructorPackageByIdSaga(action) {
  try {
    const response = yield call(InstructorPackagesByIdAPI, action.payload);
    yield put(InstructorPackagesByIdADataSuccess(response.data));
  } catch (error) {
    yield put(
      InstructorPackagesByIdADataFailure("An error occurred during package get.")
    );
  }
}

function* learnerDashboardInfoByIdSaga(action) {
  try {
    const response = yield call(learnerDashboardInfoByIdAPI, action.payload);
    yield put(learnerDashboardInfoByIdSuccess(response.data));
  } catch (error) {
    yield put(
      learnerDashboardInfoByIdFailure("An error occurred during get dashboard Info.")
    );
  }
}

function* learnerDashboardProfileInfoByIdSaga(action) {
  try {
    const response = yield call(
      learnerDashboardProfileInfoByIdAPI,
      action.payload
    );
    console.log(response);
    yield put(learnerDashboardProfileInfoByIdSuccess(response.data));
   
  } catch (error) {
    yield put(
      learnerDashboardProfileInfoByIdFailure("An error occurred while fetching the profile information.")
    );
  }
}


function* learnerDashboardProfileUpdateByIdSaga(action) {
  try {
    const response = yield call(
      learnerDashboardProfileUpdateByIdAPI,
      action.payload
    );
    console.log(response);
    yield put(learnerDashboardProfileUpdateByIdSuccess(response.data));
  } catch (error) {
    yield put(
      learnerDashboardProfileUpdateByIdFailure("An error occurred while updating the profile.")
    );
    console.log(error);
  }
}

function* learnerDashboardUpcomingBookingByIdSaga(action) {
  try {
    const response = yield call(
      learnerDashboardUpcomingBookingByIdAPI,
      action.payload
    );
    yield put(learnerDashboardUpcomingBookingByIdSuccess(response.data));
  } catch (error) {
    yield put(
      learnerDashboardUpcomingBookingByIdFailure("An error occurred while for getting upcoming bookding data.")
    );
  }
}

function* learnerDashboardPwdChangeByIdSaga(action) {
  try {
    const response = yield call(
      learnerDashboardPwdChangeByIdAPI,
      action.payload
    );
    console.log(response);


    yield put(learnerDashboardPwdChangeByIdSuccess(response.data));
  } catch (error) {
    yield put(
      learnerDashboardPwdChangeByIdFailure(error?.response?.data?.error)
    );
    console.log(error);
  }
}

function* InstructorBookingByIdSaga(action) {
  try {
    const response = yield call(
      InstructorBookingByIdAPI,
      action.payload
    );
    yield put(InstructorBookingByIdSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(
      InstructorBookingByIdFailure("An error occurred during booking instructor.")
    );
    console.log(error);
  }
}

function* BookingDescriptionByIdSaga(action) {
  try {
    const response = yield call(
      BookingDescriptionByIdAPI,
      action.payload
    );
    yield put(BookingDescriptionByIdSuccess(response.data));
  } catch (error) {
    yield put(
      BookingDescriptionByIdFailure("An error occurred during get booking description data.")
    );
  }
}

function* LearnerWalletDataByIdSaga(action) {
  try {
    const response = yield call(
      LearnerWalletDataByIdAPI,
      action.payload
    );
    yield put(LearnerWalletDataByIdSuccess(response.data));
  } catch (error) {
    yield put(
      LearnerWalletDataByIdFailure("An error occurred during get learner wallet data.")
    );
  }
}

function* LearnerTransactionDataByIdSaga(action) {
  try {
    const response = yield call(
      LearnerTransactionDataByIdAPI,
      action.payload
    );
    yield put(LearnerTransactionDataByIdSuccess(response.data));
  } catch (error) {
    yield put(
      LearnerTransactionDataByIdFailure("An error occurred during get learner card transection data.")
    );
  }
}

function* LearnerBookingDetailByIdSaga(action) {
  try {
    const response = yield call(
      LearnerBookingDetailByIdAPI,
      action.payload
    );
    yield put(LearnerBookingDetailByIdSuccess(response.data));
  } catch (error) {
    yield put(
      LearnerBookingDetailByIdFailure("An error occurred during get learner booking detail.")
    );
  }
}

function* LandingPageReferalIdSaga(action) {
  try {
    const response = yield call(
      LandingPageReferalIdAPI,
      action.payload
    );
    yield put(LandingPageReferalIdSuccess(response.data));
  } catch (error) {
    yield put(
      LandingPageReferalIdFailure("Url Not Found")
    );
  }
}

function* feedbacklearnerSaga(action) {
  try {
    const response = yield call(
      feedbacklearnerAPI,
      action.payload
    );
    yield put(feedbacklearnersendSuccess(response.data));
  } catch (error) {
    yield put(
      feedbacklearnersendFailure("Url Not Found")
    );
  }
}

function* cancelBookingSaga(action) {
  try {
    const response = yield call(
      cancelBookingAPI,
      action.payload
    );
    yield put(cancelBookingSuccess(response.data));
  } catch (error) {
    yield put(
      cancelBookingFailure("Url Not Found")
    );
  }
}

function* contactusUserSaga(action) {
  try {
    const response = yield call(
      contactusAPI,
      action.payload
    );
    yield put(contactusUserSuccess(response.data));
  } catch (error) {
    yield put(
      contactusUserFailure("Url Not Found")
    );
  }
}

function* PackageSubSerivceListSaga(action) {
  try {
    const response = yield call(PackageSubSerivceListAPI, action.payload);
    yield put(PackageSubSerivceListSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(PackageSubSerivceListFailure(error.message));
  }
}

function* updateBookingDateLearnerSaga(action) {
  try {
    const response = yield call(bookingDateUpdateAPI, action.payload);
    yield put(updateBookingDateLearnerSuccess(response.data));
    console.log(response);
  } catch (error) {
    yield put(updateBookingDateLearnerFailure(error.message));
  }
}





function* authSaga() {
  yield takeLatest(loginStart.type, loginSaga);
  yield takeLatest(logoutLearner.type, logoutLearnerSaga);
  yield takeLatest(forgotPasswordStart.type, forgotPasswordSaga);
  yield takeLatest(SignUpLearnerData.type, SignUpLearnerSaga);
  yield takeLatest(SignUpInstructorData.type, SignUpInstructorSaga);
  yield takeLatest(InstructorPackagesByIdAData.type, InstructorPackageByIdSaga);
  yield takeLatest(learnerDashboardInfoById.type, learnerDashboardInfoByIdSaga);
  yield takeLatest(InstructorBookingById.type, InstructorBookingByIdSaga);
  yield takeLatest(BookingDescriptionById.type, BookingDescriptionByIdSaga);
  yield takeLatest(LearnerWalletDataById.type, LearnerWalletDataByIdSaga);
  yield takeLatest(LearnerBookingDetailById.type, LearnerBookingDetailByIdSaga);
  yield takeLatest(learnerDashboardUpcomingBookingById.type, learnerDashboardUpcomingBookingByIdSaga);
  yield takeLatest(LandingPageReferalId.type, LandingPageReferalIdSaga);
  yield takeLatest(LearnerTransactionDataById.type, LearnerTransactionDataByIdSaga);
  yield takeLatest(learnerDashboardPwdChangeById.type, learnerDashboardPwdChangeByIdSaga);
  yield takeLatest(
    learnerDashboardProfileInfoById.type,
    learnerDashboardProfileInfoByIdSaga
  );
  yield takeLatest(
    learnerDashboardProfileUpdateById.type,
    learnerDashboardProfileUpdateByIdSaga
  );

  yield takeLatest(
    feedbacklearnersend.type,
    feedbacklearnerSaga
  );
  yield takeLatest(
    cancelBooking.type,
    cancelBookingSaga
  );
  yield takeLatest(
    contactusUser.type,
    contactusUserSaga
  );
  yield takeLatest(
    PackageSubSerivceList.type,
    PackageSubSerivceListSaga
  );
  yield takeLatest(
    updateBookingDateLearner.type,
    updateBookingDateLearnerSaga
  );
}

export default authSaga;
