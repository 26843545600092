import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import learnerone from "../../assets/images/learnercsone-one.jpg";
import learnertwo from "../../assets/images/learnercsone-two.jpg";
import learnerthree from "../../assets/images/learnercsone-three.jpg";
import "../workcstwo/WorkCsTwo.css"
import "./LearnerSayCsTwo.css"
import { RatingStart } from '../../assets/icons/RatingStart';
export const LearnerSayCsTwo = () => {
  return (
    <section className="workcstwo--wrapper learnersaycstwo--wrapper">
      <Container>
          <div className='workcstwo--inner'>
              <h2 className='sub_head'>What our learners say</h2>
              <p className='para_text'>Choose a driving instructor you can trust</p>
              <Row>
                <Col lg={4} sm={6}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={learnerone} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h6>Name</h6>
                        <RatingStart dynamicColor="#FFCE00"/>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt voluptate id impedit enim fugit aut eum sapiente incidunt, veritatis cumque.</p>
                      </div>
                  </div>
                </Col>
                <Col lg={4} sm={6}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={learnertwo} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h6>Name</h6>
                        <RatingStart dynamicColor="#FFCE00"/>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt voluptate id impedit enim fugit aut eum sapiente incidunt, veritatis cumque.</p>
                      </div>
                  </div>
                </Col>
                <Col lg={4} sm={6}>
                  <div className="workcstwo--card">
                      <div className="card-img">
                        <img src={learnerthree} className='img-fluid' alt="" />
                      </div>
                      <div className="card-content">
                        <h6>Name</h6>
                        <RatingStart dynamicColor="#FFCE00"/>
                        <p>Lorem ipsum dolor, sit amet consectetur adipisicing elit. Nesciunt voluptate id impedit enim fugit aut eum sapiente incidunt, veritatis cumque.</p>
                      </div>
                  </div>
                </Col>
              </Row>
          </div>
      </Container>
    </section>
  )
}
