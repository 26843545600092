import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import banner from "../../../assets/images/newpages/away-banner.jpg";
import visa from "../../../assets/images/visa.png";
import mastercard from "../../../assets/images/master-card.png";
import eftops from "../../../assets/images/eftops.png";
import InputSelector from "../../inputselector/InputSelector";
const Embark = () => {
  return (
    <>
      <section className="keyzi_clickAway--wrapper ">
        <div className="click_away--banner keyzi_Embark--banner"></div>
        <Container>
          <div className="keyzi_clickAway--inner">
            <Row>
              <Col lg={6}>
                <p>
                  Let’s embark on this journey together, steering your teen
                  towards independence with care, expertise, and the shared joy
                  of seeing them succeed.
                </p>
                <p>
                  Book a lesson or explore our package deals today, and take the
                  first step towards empowering your teen with the gift of safe
                  driving
                </p>
              </Col>
              <Col lg={6}>
                <div className="newPage--InputSelector">
                  <h3>
                    KEYZI - WHERE EVERY LESSON IS A MILESTONE TOWARDS SUCCESS
                  </h3>
                  <InputSelector label="Instructor Search" />
                  <div className="fp_weaccept">
                    <h4>We Accept</h4>
                    <img src={visa} className="img-fluid " alt="Visa" />
                    <img
                      src={mastercard}
                      className="img-fluid master_card"
                      alt="Master Card"
                    />
                    <img src={eftops} className="img-fluid " alt="Eftops" />
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </section>
    </>
  );
};

export default Embark;
