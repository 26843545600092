import React from "react";
import mind from "../../../assets/images/newpages/mind.png";
import road from "../../../assets/images/newpages/road.png";

const Content = () => {
  return (
    <section className="keyzi_mind--wrapper keyzi_unlockContent--wrapper">
      <div className="container">
        <div className="keyzi_mind--inner">
          <p>
            As a parent, watching your child take the wheel for the first time
            is a moment filled with mixed emotions. Pride in their growing
            independence, tinged with concern for their safety and well-being.
            It’s a pivotal step into adulthood that every teen looks forward to
            and every parent cautiously anticipates.{" "}
          </p>
          <p>
            At Keyzi, we understand the weight of this moment, not just for your
            teen but for you as well. That’s why we’ve tailored our driving
            lessons and packages to ensure a seamless, safe, and supportive
            journey to driving independence.
          </p>
          <img src={road} className="mind-road" alt="Car" />
        </div>
      </div>
    </section>
  );
};

export default Content;
