import React from 'react'
import LicenseBanner from '../components/newpages/license/LicenseBanner'
import LicenseContent from '../components/newpages/license/LicenseContent'
import LicenseBegine from '../components/newpages/license/LicenseBegine'
import LicenseWhy from '../components/newpages/license/LicenseWhy'
import LicenseJourney from '../components/newpages/license/LicenseJourney'

const License = () => {
  return (
    <>
      <LicenseBanner/>
      <LicenseContent/>
      <LicenseBegine/>
      <LicenseWhy/>
      <LicenseJourney/>
    </>
  )
}

export default License