import React, { useEffect } from "react";
import DataTable from "react-data-table-component";
import { TransectionData } from "./TransectionData";
import { Row } from "react-bootstrap";
import "./TransectionHistory.css";
import { useDispatch, useSelector } from "react-redux";
import { LearnerTransactionDataById } from "../../../redux/slice/authSlice";

export const TransectionHistory = () => {
  const dispatch = useDispatch();
  const { leanerTransactionData } = useSelector((state) => state?.auth);
  console.log(leanerTransactionData);
  const { user, error } = useSelector((state) => state?.auth);

  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(LearnerTransactionDataById(user?.data?.data.id));
      } catch (error) {
        console.error(
          "An error occurred while fetching dashboard info:",
          error
        );
      }
    };

    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);

  const columns = [
    {
      name: "Transaction ID",
      selector: (row) => row.transaction_id,
      sortable: true,
    },
    {
      name: "Amount",
      selector: (row) => row.amount,
      sortable: true,
      format: (row) => `$${row.amount}`,
    },
    {
      name: "Type",
      selector: (row) => row.type,
      sortable: true,
    },
    {
      name: "Description",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Booking ID",
      selector: (row) => row.bookingid,
      sortable: true,
      format: (row) => `${row.booking_id}`,
    },
  ];

  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };
  return (
    <section className="transectionHistory_section das_comp_shdo br-10">
      <Row className="bg-white br-10 p-1 p-lg-5 tabel_body">
        {leanerTransactionData?.data ? (
          <DataTable
            className="table-transection"
            columns={columns}
            data={leanerTransactionData?.data} // Use the correct prop for data
            pagination
            paginationPerPage={10} // Number of rows per page
            paginationRowsPerPageOptions={[5, 10, 20]} // Rows per page options
            paginationComponentOptions={paginationOptions}
            noDataComponent="NO RECORD FOUND"
          />
        ) : (
          <h6 className="text-center">Loading...</h6>
        )}
      </Row>
    </section>
  );
};
