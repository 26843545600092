import axios from 'axios';

const API_URL = 'http://167.71.238.150/keyzi-admin/api/';

export const fetchInstructorsAPI = () => {
  return axios.get(`${API_URL}instructor-list`);
};

export const fetchInstructorByIdAPI = (instructorId) => {
  return axios.get(`${API_URL}instructor-details?instructor_id=${instructorId}`);
};

export const loginauthentication = (formdata) => {
  return axios.post(`${API_URL}login-api`, formdata)
}

export const logoutLearnerAPI = (token) => {
  return axios.get(`${API_URL}logout`,{
    headers: {
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    }
  })
}

export const postalCodeAPI = () => {
  return axios.get(`${API_URL}pincode-list`);
}

export const instructorFilterAPI = (filterData) => {
  console.log(filterData);
  return axios.get(`${API_URL}instructor-list`, {
    params: {
      city_id: filterData.city_id,
      rating: filterData.rating,
      experience: filterData.experience,
      transmission_type: filterData.transmission_type,
      is_login:filterData.is_login,
      learner_id:filterData.learner_id,
      state:filterData.state,
    },
  });
};

export const FeedBackAPI=()=>{
  return axios.get(`${API_URL}feedback-list`);
}

export const CalenderAvailabilityAPI=(action)=>{
  console.log(action.payload);
  return axios.get(`${API_URL}already-booked-list?instructor_id=${action.payload}`);
}

export const forgotPasswordAPI=(emailverified)=>{
  return axios.post(`${API_URL}reset-password`,emailverified);
}

export const SignUpAuthentication=(signValues)=>{
  return axios.post(`${API_URL}learner-signup`,signValues);
}

export const SignUpInstructorAuthentication=(signInstructorValues)=>{
  return axios.post(`${API_URL}instructor-signup`,signInstructorValues);
}


export const InstructorPackagesByIdAPI=(id)=>{
  return axios.get(`${API_URL}instructor-package?instructor_id=${id}`);
}

export const learnerDashboardInfoByIdAPI=(id)=>{
  return axios.get(`${API_URL}learner-dashboard?learner_id=${id}`);
}

export const learnerDashboardProfileInfoByIdAPI=(id)=>{
  return axios.get(`${API_URL}learner-profile?learner_id=${id}`);
}

export const learnerDashboardProfileUpdateByIdAPI=(formdata)=>{
  return axios.post(`${API_URL}update-learner-profile`,formdata);
}


export const learnerDashboardPwdChangeByIdAPI = (newPwd) => {
  return axios.post(`${API_URL}change-password`, newPwd, {
    headers: {
      Authorization: `Bearer ${newPwd.token}`,
      'Access-Control-Allow-Origin': '*',
    }
  });
};

export const learnerDashboardUpcomingBookingByIdAPI = (id) => {
  return axios.get(`${API_URL}upcomming-booking-detail?learner_id=${id}`);
};

export const InstructorBookingByIdAPI=(data)=>{
  console.log(data);  
  return axios.post(`${API_URL}booking`,data);        
}

export const LearnerWalletDataByIdAPI=(id)=>{
  return axios.get(`${API_URL}wallet-history?user_id=${id}`);
}

export const LearnerBookingDetailByIdAPI=(id)=>{
  return axios.get(`${API_URL}learner-booked-list?learner_id=${id}`);
}

export const LandingPageReferalIdAPI=(id)=>{
  return axios.get(`${API_URL}landing-page?referal_code=${id}`);
}

export const LearnerTransactionDataByIdAPI=(id)=>{
  return axios.get(`${API_URL}transaction-history?user_id=${id}`);
}

export const BookingDescriptionByIdAPI=(id)=>{
  return axios.get(`${API_URL}booking-detail?booking_id=${id}`);
}


export const InstructorDayTimeFilterAPI = (tabData) => {
  const params = {
    timeFilter: tabData.timeTab,
    dayFilter: tabData.dayTab ? tabData.dayTab : `custom&date=${tabData.selectedDate}`
  };
  return axios.get(`${API_URL}instructor-list?time_filter=${params.timeFilter}&day_filter=${params.dayFilter}`);
};

export const feedbacklearnerAPI = (feedback_learner) => {
  console.log(feedback_learner);
  return axios.post(`${API_URL}learner-feedback`,feedback_learner);
};

export const cancelBookingAPI = (id) => {
  return axios.post(`${API_URL}booking-cancel`,id);
};

export const contactusAPI = (userInfo) => {
  return axios.post(`${API_URL}store-enquiry`,userInfo);
};

export const PackageSubSerivceListAPI = (data) => {
  return axios.get(`${API_URL}get-lesson?service_id=${data.service_id}&instructor_id=${data.instructor_id}`);
};

export const bookingDateUpdateAPI = (data) => {
  return axios.post(`${API_URL}update-booking-date`,data);
};

export const InstructorStateListAPI = (data) => {
  return axios.get(`${API_URL}state-list`,data);
};

export const citylistByStateAPI = (state) => {
  return axios.get(`${API_URL}city-list?state=${state}`,{
   
  });
};

// export const bookingDateUpdateAPI = (data) => {
//   return axios.post(`${API_URL}update-booking-date`,data);
// };











