import {createSlice} from "@reduxjs/toolkit";
import { resetAllState } from './rootSlice';

const initialState = {
  name: {},
  calender: [],
  error: null,
  loading: false,
}

const CalenderSlice = createSlice({
  initialState,
  [resetAllState]: () => initialState,
  name: "calender",
  initialState,
  reducers: {
    getData: (state, action) => {
      state.name = action.payload;
    },
    CalenderAvailabilityData: (state) => {
      state.error = null;
      state.loading = true;
    },
    CalenderAvailabilityDataSuccess: (state, action) => {
      console.log(action.payload);
      state.loading = false;
      state.calender = action.payload;
    },
    CalenderAvailabilityDataFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});




  export const {getData,CalenderAvailabilityData,CalenderAvailabilityDataSuccess,CalenderAvailabilityDataFailure}=CalenderSlice.actions;
export default CalenderSlice.reducer;

