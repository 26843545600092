import { createSlice } from '@reduxjs/toolkit';
import { resetAllState } from './rootSlice';

const initialState = {
  instructorsPostalCode: [],
  loading: false,
  error: null
}

const instructorPostalCodeSlice = createSlice({
  name: 'instructorsPostalCode',
  initialState,
  [resetAllState]: () => initialState,
  reducers: {
    fetchInstructorsPostalCode: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchInstructorsPostalCodeSuccess: (state, action) => {
      state.loading = false;
      state.instructors = action.payload;
    },
    fetchInstructorsPostalCodeFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  fetchInstructorsPostalCode,
  fetchInstructorsPostalCodeSuccess,
  fetchInstructorsPostalCodeFailure,
} = instructorPostalCodeSlice.actions;

export default instructorPostalCodeSlice.reducer;
