import React from 'react'
import Banner from '../components/newpages/unlock/Banner'
import  Content  from '../components/newpages/unlock/Content'
import WhyChoose from '../components/newpages/unlock/WhyChoose'
import Embark from '../components/newpages/unlock/Embark'

const Unlock = () => {
  return (
    <>
    <Banner/>
    <Content/>
    <WhyChoose/>
    <Embark/>
    </>
  )
}

export default Unlock