import "./GoogleMap.css";
import React from "react";
import GoogleMapReact from 'google-map-react';
import { Mark } from '../../../assets/icons/Mark'
import  Marker  from "google-map-react";

export const GoogleMap = () => {
  const defaultProps = {
    center: {
      lat: 22.758940,
      lng: 75.891420
    },
    zoom: 18
  };

  const markerPositions = [
    { lat: 22.758940, lng: 75.891420 },
    { lat: 22.758500, lng: 75.890100 },
    { lat: 22.759200, lng: 75.892700 },
  ];

  return (
    <section className="mt-3 mb-4">
      <div style={{ height: '300px', width: '100%' }}>
        <GoogleMapReact
          defaultCenter={defaultProps.center}
          defaultZoom={defaultProps.zoom}
        >
          {/* Render each marker */}
          {markerPositions.map((marker, index) => (
            <Marker
              key={index}
              lat={marker.lat}
              lng={marker.lng}
            >
              <Mark />
            </Marker>
          ))}
        </GoogleMapReact>
      </div>
    </section>
  );
}
