import React from 'react'
import { Container,Row,Col } from 'react-bootstrap'
import visa from "../../../assets/images/visa.png"
import mastercard from "../../../assets/images/master-card.png"
import eftops from "../../../assets/images/eftops.png"
import InputSelector from "../../inputselector/InputSelector"
const EmbarkAhead = () => {
  return (
    <>
    <section className="keyzi_quite--wrapper ">
    <Container>
    <div className='keyzi_quite--inner '>
      <h2>Embrace The Journey Ahead</h2>
      <p>Starting with red P plates, you’ll navigate the roads with newfound freedom, all while displaying your commitment to safe driving</p>
    </div>
    </Container>
  </section>
   <section className="keyzi_clickAway--wrapper keyzi_ahead--wrapper">
    <div className='click_away--banner '></div>
   <Container>
   <div className='keyzi_clickAway--inner'>
     <Row>
      <Col lg={6}><p>Ready to start your journey to independence and adventure on the roads? Remember, while this path may seem daunting, each step you take brings you closer to that feeling of freedom that comes with driving. And for the parents reading along, rest assured, this journey emphasises safety, responsibility, and the development of skilled, confident drivers.</p>
     <p>Imagine the places you’ll go, the music you’ll hear, and the memories you’ll make, all from the driver’s seat.</p></Col>
      <Col lg={6}>
          <div className="newPage--InputSelector newPage_ahead-inputSelector">
          <h3>Your road to independence starts here. 
Are you ready to take the first step?</h3>
        <InputSelector label="Instructor Search"/>
        <div className="fp_weaccept">
                <h4>We Accept</h4>
                <img src={visa} className="img-fluid " alt="Visa" />
                <img src={mastercard} className="img-fluid master_card" alt="Master Card" />
                <img src={eftops} className="img-fluid " alt="Eftops" />
            </div>
          </div>
      </Col>
     </Row>
   </div>
   </Container>
 </section>
    </>
  )
}

export default EmbarkAhead