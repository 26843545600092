import instructor from "../../assets/images/instructor.png"
import learner from "../../assets/images/learner.png"
export const CardContent=[
  {
id:1,
title:"LEARNER",
textDetail:"Sign up to start taking driving lessons",
URL:learner
},
{
  id:2,
  title:"INSTRUCTOR",
  textDetail:"Sign up to start growing your business today",
  URL:instructor
  }
]