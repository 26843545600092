import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import star from "../../assets/images/Instructor Rating.svg";
import arrow from "../../assets/images/Accredited.svg";
import message from "../../assets/images/Vehicle Safety.svg";
import car from "../../assets/images/Always your choice.svg";
import "./DrivingLessonsCsTwo.css";
import visa from "../../assets/images/visa.png";
import mastercard from "../../assets/images/master-card.png";
import eftops from "../../assets/images/eftops.png";
export const DrivingLessonsCsTwo = () => {
  return (
    <section className="drivinglessonscstwo--wrapper">
      <Container>
        <div className="drivinglessonscstwo--inner">
          <div className="drivinglessonscstwo--whiteWrapper">
            <h2 className="sub_head">Book driving lessons with confidence</h2>
            <p className="para_text">
              Choose a driving instructor you can trust
            </p>
            <Row>
              <Col md={6} lg={3} >
                <div className="drivinglessonscstwo--card">
                  <div className="card-img">
                    <img src={star} className="img-fluid" alt="" />
                  </div>
                  <div className="card-content">
                    <h5>Instructor Ratings</h5>
                    <p>
                      Access peer reviews & find an instructor who has
                      consistently provided a great learning experience.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="drivinglessonscstwo--card">
                  <div className="card-img">
                    <img src={arrow} className="img-fluid" alt="" />
                  </div>
                  <div className="card-content">
                    <h5>Accredited</h5>
                    <p>
                      We obtain up to date copies of relevant instructor
                      accreditations & verify their working with children
                      credentials.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="drivinglessonscstwo--card">
                  <div className="card-img">
                    <img src={message} className="img-fluid" alt="" />
                  </div>
                  <div className="card-content">
                    <h5>Vehicle Safety</h5>
                    <p>
                      Gain access to instructor vehicle make, model, year &
                      safety rating.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={6} lg={3}>
                <div className="drivinglessonscstwo--card">
                  <div className="card-img">
                    <img src={car} className="img-fluid" alt="" />
                  </div>
                  <div className="card-content">
                    <h5>Always your choice</h5>
                    <p>
                      Don't like your current instructor? Select a new
                      instructor via our online portal, no questions asked.
                    </p>
                  </div>
                </div>
              </Col>
              <Col md={12}>
                <Link to="/" className="btn">
                  Book driving lessons now
                </Link>
              </Col>
              <Col md={12}>
                <h2 className="sub_head">We Accept</h2>
                <div className="payment_list">
                  <img
                    src={visa}
                    className="img-fluid "
                    alt="Visa"
                  />
                  <img
                    src={mastercard}
                    className="img-fluid master-card"
                    alt="Master Card"
                  />
                  <img
                    src={eftops}
                    className="img-fluid "
                    alt="Eftops"
                  />
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </Container>
    </section>
  );
};
