import React, { useEffect } from "react";
import { Container, Row,Button } from "react-bootstrap";
import { fetchInstructorsSingle } from "../../../redux/slice/InstructorSlice";
import "../transactionhistory/TransectionHistory.css";
import { useDispatch, useSelector } from "react-redux";

import { Link, useNavigate } from "react-router-dom";
import "./BookingDetails.css";
import {
  learnerDashboardInfoById,
  learnerDashboardProfileInfoById,
  LearnerWalletDataById,
  LearnerBookingDetailById,
  LearnerTransactionDataById,
} from "../../../redux/slice/authSlice";
import { Routers } from "../../../routes/Constant";
import DataTable from "react-data-table-component";
import { Edit } from "../../../assets/icons/Edit";

export const Booking = () => {
  const { bookingDetail } = useSelector((state) => state?.auth);
  console.log(bookingDetail);
  const { user, error } = useSelector((state) => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const reversedCalendarData = bookingDetail?.calendar_data?.slice().reverse();
  useEffect(() => {
    const fetchDashboardInfo = async () => {
      try {
        await dispatch(LearnerBookingDetailById(user?.data?.data.id));
      } catch (error) {
        console.error(
          "An error occurred while fetching dashboard info:",
          error
        );
      }
    };

    if (user?.data?.data) {
      fetchDashboardInfo();
    }
  }, [user]);

  function convertDateFormat(inputDateStr) {
    if(inputDateStr === null){
      return null;
    }
    const originalDate = new Date(inputDateStr);

    const day = originalDate.toLocaleString("en-us", { weekday: "long" });
    const time = originalDate.toLocaleString("en-us", {
      hour: "2-digit",
      minute: "2-digit",
    });
    const month = originalDate.toLocaleString("en-us", { month: "long" });
    const date = originalDate.getDate();

    return `${time} ${day}, ${month} ${date}`;
  }

  const handleBookNow = (packageDataById) => {

    localStorage.setItem("booking", "pending");
    dispatch(fetchInstructorsSingle(packageDataById));
    navigate(Routers.Login);
  };

  const columns = [
    {
      name: "Instructor Name",
      selector: (row) => row.instructor?.name,
      cell: (row) => (
        <Link
          to={`/auth/dashboard/booking/bookingDetails/${row.id}`}
          className="text-decoration-none"
        >
          {row.instructor?.name}
        </Link>
      ),
    },
    {
      name: "Booking Start Date",
      selector: (row) => `${convertDateFormat(row.start)}`,
    },
    {
      name: "Booking End Date",
      selector: (row) => `${convertDateFormat(row.end)}`,

    },
    {
      name: "Package Name",
      selector: (row) => `${row?.package?.sub_service?.service?.name}`,
    },
    {
      name: "Location",
      selector: (row) =>
        `${row.pickup_location}, ${row.city} ${row.postal_code}`,
    },
    {
      name: "Actions",
      selector: (row) =>
        row.start ? <Button onClick={()=>{handleBookNow(row?.instructor?.id)}}>Book</Button> : (<div>
          
        <Button onClick={()=>{handleBookNow(row?.instructor?.id)}}>Book</Button> <Button className="Edit_BookingDate" onClick={() => {
          navigate(`/auth/dashboard/booking/bookingDetails/updateBooking/${row.id}`)
        }}>Update</Button>
        </div>),
    },
  ];




  const paginationOptions = {
    rowsPerPageText: "Rows per page:",
    rangeSeparatorText: "of",
    selectAllRowsItem: true,
    selectAllRowsItemText: "All",
  };

  return (
    <>
      <section className="booking_section das_comp_shdo">
        <Container>
          <Row className="bg-white br-10 p-2 p-lg-5  tabel_body">
            {reversedCalendarData ? (<DataTable
              title="Booking Details"
              columns={columns}
              data={reversedCalendarData}
              className="Booking_list_table"
              pagination
              paginationPerPage={10} // Number of rows per page
              paginationRowsPerPageOptions={[5, 10, 20]} // Rows per page options
              paginationComponentOptions={paginationOptions}
              noDataComponent="NO RECORD FOUND"
            />) : (<h6 className="text-center">Loading...</h6>)}
          </Row>
        </Container>
      </section>
    </>
  );
};
