import {createSlice} from "@reduxjs/toolkit";
import { resetAllState } from './rootSlice';


const initialState = {
  FeedBack:[],
  error:null,
  loading:false,
}
const LearnerSlice=createSlice(
  {
    name:"learner",
    initialState,
  [resetAllState]: () => initialState,
    reducers:{
    FeedBackData:(state)=>{
        state.error=null;
        state.loading=true;
      },
    FeedBackSuccess:(state,action)=>{
        state.loading=false;
        state.feedBack=action.payload;
        },
      FeedBackFailure:(state, action)=>{
          state.loading =false;
          state.error = action.payload;
        },
    }
  }
  )
  export const {FeedBackData,FeedBackSuccess,FeedBackFailure}=LearnerSlice.actions;
export default LearnerSlice.reducer;
