// ConfirmDetails.js
import React, { useState } from "react";
import { Button, Container, Row, Col } from "react-bootstrap";
import "./ConfirmDetails.css";
import { Calender } from "../../../assets/icons/Calender";
import "bootstrap-icons/font/bootstrap-icons.css";
import { useDispatch, useSelector } from "react-redux";
import { objectFiledDataStart } from "../../../redux/slice/objectSlice";
export const ConfirmDetails = ({
  SelectPacknext,
  updateFieldData,
  bookingData,
}) => {
  const dispatch = useDispatch();
  const cal = useSelector((state) => state.calender.name);
  const [pickupLocation, setPickupLocation] = useState(
    bookingData?.pickup_location || ""
  );
  const [city, setCity] = useState(bookingData?.city || "");
  const [postalCode, setPostalCode] = useState(bookingData?.postal_code || "");
  const [error, setError] = useState(false);

  const handleConfirm = () => {
    if (pickupLocation && city && postalCode) {
      updateFieldData("pickup_location", pickupLocation);
      updateFieldData("city", city);
      updateFieldData("postal_code", postalCode);
      SelectPacknext();
      setError(false);
    } else {
      setError(true);
    }
  };


  function formatDate(inputDate) {
    const options = {
      weekday: "long",
      year: "numeric",
      month: "long",
      day: "2-digit",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
  
    const formattedDate = new Date(inputDate).toLocaleDateString("en-US", options);
  
    return formattedDate;
  }

  return (
    <section className="confirmDetail_section m-bot">
      <Container>
        <Row>
          <Col className="mt-5">
            <form action="">
              <div className="d-flex justify-content-center flex-column form-cd br-10 pb-3 m-auto">
                <div className="cd_head_bg p-3">
                  <h5 className="text-center progress_head pt-2">
                    CONFIRM DETAILS
                  </h5>
                </div>
                <div className="p-3 pb-1">
                  <h6 className="text-indigo progress_sub_head">
                    Pickup Location
                  </h6>
                  <div className="confirm_details_select_style_pickuplocation">
                    {/* <select
                      name=""
                      value={pickupLocation}
                      onChange={(e) => setPickupLocation(e.target.value)}
                      className="form-control dropdown-select border-0 p-1 p-md-3 confirm_select"
                      aria-label="Default select example"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Palasia">Palasia</option>
                      <option value="LIG">LIG</option>
                      <option value="Vijay Nagar">Vijay Nagar</option>
                      <option value="Rajwada">Rajwada</option>
                    </select> */}
                    <input
                      value={pickupLocation}
                      onChange={(e) => setPickupLocation(e.target.value)}
                      placeholder="Address"
                      className="form-control dropdown-select border-0 p-3 confirm_select bg-grey"
                    />
                  </div>
                  <div className="d-flex mt-3 confirm_details_select_style">
                    {/* <select
                      name=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      className="form-control p-1 p-md-3 me-1 border-0 confirm_select"
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Indore">Indore</option>
                      <option value="Ujjain">Ujjain</option>
                      <option value="Bhopal">Bhopal</option>
                      <option value="Dewas">Dewas</option>
                    </select> */}
                    <input
                      name=""
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                      placeholder="City"
                      className="form-control p-3  border-0 confirm_select bg-grey"
                    />
                    <input
                      name=""
                      value={postalCode}
                      onChange={(e) => setPostalCode(e.target.value)}
                      placeholder="Postal Code"
                      className="form-control p-3  border-0 confirm_select bg-grey"
                    />
                  </div>
                  <div className="bg-grey rounded-3 mt-3 p-3 ">
                    <h6 className="progress_sub_head">
                      Booking with {bookingData.name_instructor}
                    </h6>
                    <ul className="list-unstyled">
                      {/* <li className="calender_time_sec">
                        {cal.map((i, index) => {
                          return (
                            <span key={index}>
                              <Calender />
                              <span key={index}>
                                {i.newtime} {i.newday}, {i.currDate}
                              </span>
                            </span>
                          );
                        })}
                          
                      </li> */}
                      {bookingData.start_date?.map((i, index) => {
                        return (
                          <li className="calender_time_sec">
                            <span key={index}>
                              <Calender />
                              <span key={index}>
                                {formatDate(i)}
                              </span>
                            </span>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="alert_wrap">
                  {error && (
                    <span className="text-danger d-block mt-1 ms-1">
                      Please fill pickup location details
                    </span>
                  )}
                </div>
                <div className="text-center confirm_btn_wrapper">
                  <Button
                    className="orange_btn border-0 br-10 confirm_btn mt-2 mt-4"
                    onClick={handleConfirm}
                  >
                    Confirm
                  </Button>
                </div>
              </div>
            </form>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
