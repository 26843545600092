import React from 'react'
import RediscoverBanner from '../components/newpages/rediscover/RediscoverBanner'
import RediscoverContent from '../components/newpages/rediscover/RediscoverContent'
import RediscoverPath from '../components/newpages/rediscover/RediscoverPath'
import RediscoverChooseUs from '../components/newpages/rediscover/RediscoverChooseUs'
import RediscoverJourney from '../components/newpages/rediscover/RediscoverJourney'

const Rediscover = () => {
  return (
    <>
      <RediscoverBanner/>
      <RediscoverContent/>
      <RediscoverPath/>
      <RediscoverChooseUs/>
      <RediscoverJourney/>
    </>
  )
}

export default Rediscover