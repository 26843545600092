import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { Facebook } from "../../assets/icons/Facebook";
import { Twitter } from "../../assets/icons/Twitter";
import { LinkedIn } from "../../assets/icons/LinkedIn";
import { Phone } from "../../assets/icons/Phone";
import { Location } from "../../assets/icons/Location";
import { Message } from "../../assets/icons/Message";
import Logo from "../../assets/images/image_12.png";
import "./Footer.css";
import facebookcsone from "../../assets/icons/ri_facebook-fill.svg";
import instagramcsone from "../../assets/icons/mdi_instagram.svg";
import linkdincsone from "../../assets/icons/mdi_linkedin.svg";
import youtubecsone from "../../assets/icons/mdi_youtube.svg";
import homecsone from "../../assets/icons/mdi_Home.svg";
import twittercsone from "../../assets/icons/devicon_twitter.svg";
import Button from "react-bootstrap/Button";
import { Link, useNavigate } from "react-router-dom";
import { Routers } from "../../routes/Constant";
import facebookcstwo from "../../assets/images/ri_facebook-fill - Black.svg";
import instagramcstwo from "../../assets/images/mdi_instagram - Black.svg";
import linkdincstwo from "../../assets/images/mdi_linkedin - Black.svg";
import youtubecstwo from "../../assets/images/mdi_youtube - Black.svg";
import twittercstwo from "../../assets/images/devicon_twitter - Black.svg";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { InstructorStateList } from "../../redux/slice/InstructorSlice";
// !FOOTER SECTION! this is a footer component have personal detail & contact detail of current wesite and also perform some routing on some component

export const Footer = () => {
  const d = new Date();
  const copyrightSymbol = "\u00A9";
  let year = d.getFullYear();
  const currentUrl = window.location.href;
  const containsSite2 = currentUrl.includes("site2");
  const navigate = useNavigate();
  const containsCorporateSiteone = currentUrl.includes("corporatesiteone");
  const containsCorporateSitetwo = currentUrl.includes("corporatesitetwo");
  const dispatch = useDispatch();
  useEffect(()=>{
    dispatch(InstructorStateList())
  },[dispatch])
  const { instructorslistState } = useSelector(
    (state) => state.instructors || { instructorslistState: [] }
  );

  console.log(instructorslistState);

  return (
    <>
      {containsCorporateSiteone  && <section className="footercsone--wrapper">
        <Container>
          <div className="footercsone--inner">
            <Row>
              <Col md={3} sm={12}>
                <ul className="leftfooter-csone">
                  <li>
                    <Link>
                      <span>
                        <img src={homecsone} alt="" />
                      </span>
                      Home
                    </Link>
                  </li>
                  <li>
                    <h2>
                      <Link>keyzi</Link>
                    </h2>
                  </li>
                  <li>
                    <p>
                      Keyzi takes the hassle out of choosing a driving school by
                      helping learner drivers find, compare and book verified
                      driving instructors online.
                    </p>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={12}>
                <h5>Driving Instructors by City</h5>
                <ul className="firstfooter-csone">
                  <li>
                    <Link>Sydney</Link>
                  </li>
                  <li>
                    <Link>Melbourne</Link>
                  </li>
                  <li>
                    <Link>Brisbane</Link>
                  </li>
                  <li>
                    <Link>Perth</Link>
                  </li>
                  <li>
                    <Link>Adelaide</Link>
                  </li>
                  <li>
                    <Link>Hobart</Link>
                  </li>
                  <li>
                    <Link>Canberra</Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={12}>
                <h5>Resources</h5>
                <ul className="secondfooter-csone">
                  <li>
                    <Link>Support</Link>
                  </li>
                  <li>
                    <Link>Blog</Link>
                  </li>
                  <li>
                    <Link>Contact</Link>
                  </li>
                  <li>
                    <Link>About</Link>
                  </li>
                  <li>
                    <Link>Instruct with Keyzi</Link>
                  </li>
                  <li>
                    <Link>Driving Instructor User Policies</Link>
                  </li>
                  <li>
                    <Link>Learner User Policies</Link>
                  </li>
                </ul>
              </Col>
              <Col md={3} sm={12}>
                <h5 className="m-0">Learn To Drive</h5>
                <Button>Book Online</Button>
                <ul className="rightfooter-csone">
                  <li>
                    <Link>Driving Lessons</Link>
                  </li>
                  <li>
                    <Link>Test Packages</Link>
                  </li>
                  <li>
                    <Link>Gift Vouchers</Link>
                  </li>
                  <li>
                    <Link>Refresher Driving Lesson</Link>
                  </li>
                </ul>
              </Col>
            </Row>
          </div>
        </Container>
        <div className="copywrite_section--wrapper">
          <Container>
            <div className="copywrite_section--inner">
              <div className="csone--socialMediaList">
                <ul>
                  <li>
                    <Link>
                      <img src={facebookcsone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img src={instagramcsone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img src={twittercsone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img src={youtubecsone} alt="" />
                    </Link>
                  </li>
                  <li>
                    <Link>
                      <img src={linkdincsone} alt="" />
                    </Link>
                  </li>
                </ul>
              </div>
              <div className="csone--rightSideList">
                <ul>
                  <li>
                    <Link>Keyzi © 2023</Link>
                  </li>
                  <li>
                    <Link>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link>Terms and Conditions</Link>
                  </li>
                </ul>
              </div>
            </div>
          </Container>
        </div>
      </section>}
      {(!containsCorporateSiteone && !containsCorporateSitetwo) &&
      (
        <>
        <footer className="keyzi_footer--wrapper">
        <Container>
          <div className="keyzi_footer--inner">
            <Row>
              <Col md={6} xl={3} >
                <div className="footer--leftSide">
                  <h1>
                    <Link to={Routers.Home}>keyzi</Link>
                  </h1>
                  <Button>Find My Instructor</Button>
                  <Button>Book My First Lesson</Button>
                </div>
              </Col>
              
              <Col md={6} xl={3}>
                <div className="footer--rightSide">
                  <h4>Driving Instructors By State</h4>
                  {/* <ul>
                    <li>
                    <Link to={Routers.Manual}>NSW Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>VIC Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>QLD Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>WA Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>SA Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>TAS Driving instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>ACT Driving instructors</Link>
                    </li>
                  </ul> */}
                  <ul>
                    {instructorslistState?.[0]?.map((e)=>{
                      return(<li><Link to={`/driving_instructors/${e?.state_code}`}>{e?.state_code} Driving Instructors</Link></li>)
                    })}
                  </ul>
                </div>
              </Col>
              <Col md={6} xl={3}>
                <div className="footer--rightSide">
                  <h4>Driving Instructors By City</h4>
                  <ul>
                    <li>
                    <Link to={Routers.Manual}>Sydney Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Melbourne Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Brisbane Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Perth Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Adelaide Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Hobart Driving Instructors</Link>
                    </li>
                    <li>
                    <Link to={Routers.Manual}>Canberra Driving Instructors</Link>
                    </li>
                  </ul>
                </div>
              </Col>
              <Col md={6} xl={3}>
                <div className="footer--rightSide">
                  <h4>Learn to Drive</h4>
                  <ul>
                    <li>
                    <Link to={Routers.conquer}>Test Packages</Link>
                    </li>
                    <li>
                    <Link to={Routers.unlock}>Parents Manual</Link>
                    </li>
                    <li>
                    <Link to={Routers.embark}>Driving Lessons</Link>
                    </li>
                    <li>
                    <Link to={Routers.guide}>DIY vs Driving Instructor</Link>
                    </li>
                    <li>
                    <Link to={Routers.rediscover}>Refresher Driving Lesson</Link>
                    </li>
                    <li>
                    <Link to={Routers.license}>International Driver's Licence Conversions</Link>
                    </li>
                    <li>
                    <Link to={Routers.navigating}>Licensing Requirements in Australia</Link>
                    </li>
                  </ul>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </footer>
      <div className="getOnRoad--section">
        <Container>
          <div className="getOnRoad--inner">
            <h3>
              {containsSite2
                ? "Your Time To Rise and Shine"
                : "Get on the Road to Freedom!"}
            </h3>
            <Button
              onClick={() => {
                navigate(Routers.Login);
              }}
            >
              Get Started
            </Button>
          </div>
        </Container>
      </div>
        </>
      )}
      {containsCorporateSitetwo &&  (
        <>
        <footer className="keyzi_footercstwo--wrapper">
        <Container>
          <div className="keyzi_footercstwo--inner">
            <Row>
                <Col md={6} lg={5}>
                  <ul className="social_media">
                    <li>
                      <img src={facebookcstwo} alt="" />
                    </li>
                    <li>
                    <img src={instagramcstwo} alt="" />
                    </li>
                    <li>
                    <img src={twittercstwo} alt="" />
                    </li>
                    <li>
                    <img src={youtubecstwo} alt="" />
                    </li>
                    <li>
                    <img src={linkdincstwo} alt="" />
                    </li>
                  </ul>
                  <ul className="privacy_list">
                    <li className="logo_link"><Link>keyzi</Link></li>
                    <li>Privacy Policy</li>
                    <li>Terms and Conditions</li>
                  </ul>
                  <h6>Keyzi © 2023</h6>
                </Col>
                <Col md={6} lg={2}>
                  <h5>Quick Links</h5>
                  <ul className="heading_list">
                    <li>Support</li>
                    <li>Blog</li>
                    <li>Contact</li>
                  </ul>
                </Col>
                <Col md={6} lg={2}>
                  <ul className="common_list">
                    <li>About</li>
                    <li>Driving Lessons</li>
                    <li>Gift Vouchers</li>
                  </ul>
                </Col>
                <Col md={6} lg={3}>
                  <ul className="common_list">
                    <li>Instruct with Keyzi</li>
                    <li>Driving Instructor User Policies</li>
                    <li>Learner User Policies</li>
                  </ul>
                </Col>
            </Row>
          </div>
        </Container>
      </footer>
        </>
      )}
    </>
  );
};
