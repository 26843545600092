

import React from "react";
import banner from "../../../assets/images/newpages/rediscover-banner.png";
const RediscoverBanner = () => {
  return (
    <section className="keyzi_conquer--wrapper keyzi_rediscover--wrapper">
      <div className="container">
        <div className="keyzi_conquer--inner">
          <div className="row">
            <div className="col-md-6">
              <div className="left-side">
                <h3>Rediscover The Joy and Confidence of Driving</h3>
                <p>Why Refresher Courses Are Your Roadmap Back</p>
              </div>
            </div>
            <div className="col-md-6">
              <div className="right-side">
                <img src={banner} alt="Car" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default RediscoverBanner;
