import React, { useState, useEffect,useRef  } from "react";
import { Rate } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { BookingDescriptionById, feedbacklearnersend } from "../../../redux/slice/authSlice";
import { Bannermain } from "../../bannermain/Bannermain";
import './BookingDetails.css';
import { message } from "antd";
import { useNavigate } from "react-router-dom";

export const FeedBackPage = () => {
  const { id } = useParams();
  const [rating, setRating] = useState(0);
  const [reviews, setReviews] = useState("");
  const dispatch = useDispatch();
  const isFirstRender = useRef(true);
  const [feedbackLearner, setFeedbackLearner] = useState({
    user_id: "",
    learner_id: "",
    description: "",
    rating: "",
    booking_id: "",
  });
  const navigate = useNavigate();
  const [feedErrormessage,SetfeedErrormessage] = useState(false);

  const { bookingDescription, feedbackdata } = useSelector((state) => state?.auth);

  useEffect(() => {
    dispatch(BookingDescriptionById(id));
  }, [dispatch, id]);

  useEffect(() => {
    setFeedbackLearner({
      user_id: bookingDescription?.data?.user_id,
      learner_id: bookingDescription?.data?.learner?.id,
      description: reviews,
      rating: rating,
      booking_id: bookingDescription?.data?.id,
    });
  }, [rating, reviews, bookingDescription]);

  const handleRating = (value) => {
    setRating(value);
  };

  const handleReviews = (e) => {
    setReviews(e.target.value);
  };

  const handleFeedBackSubmit = (e) => {
    e.preventDefault(); 
    if(rating === 0|| reviews === "")
    {
      SetfeedErrormessage(true);
      return false;
    }
    if (feedbackLearner) {
      dispatch(feedbacklearnersend(feedbackLearner));
      SetfeedErrormessage(false);
      setRating(0);
      setReviews("");
      navigate(-1)
    }  
  };

  useEffect(() => {
    if (!isFirstRender.current && feedbackdata?.message) {
      message.success({
        content: feedbackdata.message,
        duration: 3,
      });
    } else {
      isFirstRender.current = false;
    }
  }, [feedbackdata]);

  return (
    <section className="feedback_Section">
      <Container>
        <Row className="br-10">
          <Col lg={6} md={12} className="feedback_col_one">
            <h2 className="main_head text-indigo text-capitalize">
              Share your Instructor Feedback
            </h2>
          </Col>
          <Col lg={6} md={12} className="feedback_col_two">
            <div className="mb-3">
              <h4 className="text-indigo mb-1 ">Rating</h4>
              <div>
                <span>
                  <Rate onChange={handleRating} value={rating} />
                  {/* {rating ? (
                    <span className="ant-rate-text">{rating}</span>
                  ) : (
                    ""
                  )} */}
                </span>
              </div>
            </div>
            <div>
              <h4 className="text-indigo">Reviews</h4>
              <div>
                <Form.Control
                  as="textarea"
                  placeholder="Comment here"
                  style={{ height: "100px" }}
                  onChange={handleReviews}
                  value={reviews}
                />
              </div>
              {feedErrormessage ? (<span className="text-danger">Please give rating and review</span>):""}
              <div>
                <Button
                  variant="primary"
                  className="btn_sumbit_feedback"
                  type="submit"
                  onClick={handleFeedBackSubmit}
                >
                  Submit
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
