import React from 'react'
import journeyOne from "../../../assets/images/newpages/journey_one.jpg"
import journeyTwo from "../../../assets/images/newpages/journey_two.jpg"
import journeyThree from "../../../assets/images/newpages/journey_three.jpg"
import journeyFour from "../../../assets/images/newpages/journey_four.jpg"
import { Container } from 'react-bootstrap'

const Journey = () => {
  return (
    <>
    <section className="keyzi_journey--wrapper">
      <Container>
      <div className="keyzi_journey--inner">
        <h2>Your Journey to Success Includes:</h2>
            <div className="journey_card_wrapper">
            <div className="journey--card">
                  <img src={journeyOne} className='img-fluid' alt="icon" />
                  <h5>Personalized Pick-Up</h5>
                  <p>We start your big day with a comforting touch, picking you up 1 hour before your test. This is your moment to breathe, relax, and focus.</p>
            </div>
            <div className="journey--card">
                  <img src={journeyTwo} className='img-fluid' alt="icon" />
                  <h5>Pre-Test Warm-Up</h5>
                  <p>Engage in a 45-minute warm-up lesson to settle any last-minute jitters. It's a chance to familiarise yourself once more with the vehicle and polish those driving skills, ensuring you're test-ready.</p>
            </div>
            <div className="journey--card">
                  <img src={journeyThree} className='img-fluid' alt="icon" />
                  <h5>The Test Drive</h5>
                  <p>Navigate your driving test with the familiarity and comfort of your instructor's vehicle. It's not just any car; it's your ally on the road to passing.</p>
            </div>
            <div className="journey--card">
                  <img src={journeyFour} className='img-fluid' alt="icon" />
                  <h5>Post-Test Support</h5>
                  <p>Regardless of the outcome, we'll be there to celebrate your efforts or offer comforting support. We'll provide a thoughtful drop-off after your result, allowing you time to reflect and plan your next steps.</p>
            </div>
            </div>
      </div>
      </Container>
  </section>
  <section className="keyzi_empowerment--wrapper">
    <Container>
    <div className='keyzi_empowerment--inner'>
      <h5>Empowerment is Key</h5>
      <p>Remember, our packages are designed to empower you. While booking your driving test with your local roads authority is a step you'll take, we're here to guide you through the process, ensuring clarity and confidence.</p>
    </div>
    </Container>
  </section>
    </>
  )
}

export default Journey