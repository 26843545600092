import { createSlice } from '@reduxjs/toolkit';
import { resetAllState } from './rootSlice';

const initialState = {
  instructors: [],
  loading: false,
  error: null
}

const instructorSlice = createSlice({
  name: 'instructors',
  initialState,
  [resetAllState]: () => initialState,
  reducers: {
    fetchInstructors: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchInstructorsSuccess: (state, action) => {
      state.loading = false;
      state.instructors = action.payload;
    },
    fetchInstructorsFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchInstructorsSingle: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    fetchInstructorsSingleSuccess: (state, action) => {
      state.loading = false;
      state.instructorsdetails = [action.payload];
    },
    fetchInstructorsSingleFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchInstructorsFilter: (state, action) => {
      state.loading = true;
      state.error = null;
    },
    fetchInstructorsFilterSuccess: (state, action) => {
      state.loading = false;
      state.instructorsFilter = [action.payload];
    },
    fetchInstructorsFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    InstructorDayTimeFilter: (state, action) => {
      state.loading = true;
      state.instructorsdaytimeFilter = null;
      state.error = null;
    },
    InstructorDayTimeFilterSuccess: (state, action) => {
      state.loading = false;
      state.instructorsdaytimeFilter = [action.payload];
      state.error = null;

    },
    InstructorDayTimeFilterFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
    ,
    InstructorStateList: (state, action) => {
      state.loading = true;
      state.instructorslistState = null;
      state.error = null;
    },
    InstructorStateListSuccess: (state, action) => {
      state.loading = false;
      state.instructorslistState = [action.payload];
      state.error = null;

    },
    InstructorStateListFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
    ,
    citylistByState: (state, action) => {
      state.loading = true;
      state.citylist = null;
      state.error = null;
    },
    citylistByStateSuccess: (state, action) => {
      state.loading = false;
      state.citylist = [action.payload];
      state.error = null;

    },
    citylistByStateFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    }
  }
});

export const {
  fetchInstructors,
  fetchInstructorsSuccess,
  fetchInstructorsFailure,
  fetchInstructorsSingle,
  fetchInstructorsSingleSuccess,
  fetchInstructorsSingleFailure,
  fetchInstructorsFilter,
  fetchInstructorsFilterSuccess,
  fetchInstructorsFilterFailure,
  InstructorDayTimeFilter,
  InstructorDayTimeFilterSuccess,
  InstructorDayTimeFilterFailure,
  InstructorStateList,
  InstructorStateListSuccess,
  InstructorStateListFailure,
  citylistByState,
  citylistByStateSuccess,
  citylistByStateFailure,
} = instructorSlice.actions;

export default instructorSlice.reducer;
